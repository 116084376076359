@use 'sass:math';

@import 'typography';
@import 'strip__table';

.strip {
    $this: &;
    background: var(--bgStrip, transparent);
    background-image: var(--bgImage, none);
    color: var(--strip-text-color, inherit);

    :where(&) & {
        --bgStrip: unset;
        --column-bg-left: initial;
        --column-bg-right: initial;
        --strip-text-color: initial;
        --text-v-align: unset;
        text-align: initial;
    }

    &--draft {
        .page__content > & {
            display: none !important;
        }
    }
    &__data {
        flex: 1 1 auto;
        color: var(--strip-text-color, inherit);
        .builder & {
            min-height: $lineHeight * $unit;
        }
    }
    &--wide {
        --container-maxWidth: 100%;
        --column-bg-left: 0;
        --column-bg-right: 0;
        .container {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &--xnarrow,
    &--narrow,
    &--large,
    &--normal {
        .container {
            padding-left: var(--strip-expand-paddingLeft, var(--container-pad, var(--vpad)));
            padding-right: var(--strip-expand-paddingRight, var(--container-pad, var(--vpad)));
        }
    }
    &--large {
        --container-maxWidth: 100%;
        --strip-expand-maxWidth: 100%;
    }
    &--normal {
        --container-maxWidth: #{$contWidth};
        --strip-expand-maxWidth: #{$contWidth};
    }
    &--narrow {
        --container-maxWidth: #{$contWidthNarrow};
        --strip-expand-maxWidth: #{$contWidthNarrow};
    }
    &--xnarrow {
        --container-maxWidth: #{math.div($contWidthNarrow, 1.5)};
        --strip-expand-maxWidth: #{math.div($contWidthNarrow, 1.5)};
    }
    @media screen and (min-width: #{$screenMedium}) {
        &--expand {
            &-sx {
                --container-maxWidth: calc(
                    (100% + var(--strip-expand-maxWidth)) / 2 - var(--container-pad, var(--vpad))
                );
                .container {
                    margin-left: 0;
                }
                &#{$this}--image {
                    --strip-expand-paddingLeft: 0;
                }
            }
            &-dx {
                --container-maxWidth: calc(
                    (100% + var(--strip-expand-maxWidth)) / 2 - var(--container-pad, var(--vpad))
                );
                .container {
                    margin-right: 0;
                }
                &#{$this}--image {
                    --strip-expand-paddingRight: 0;
                }
            }
        }
    }

    &--above {
        position: relative;
        z-index: 1;
    }

    &--hidden {
        display: none;
    }

    &--full-height {
        min-height: calc(100vh - var(--header-height, 0) * 1px);
        display: flex;
        #{$this} {
            &__text {
                height: auto;
            }
        }
    }

    .container {
        background: var(--bgCont, transparent);
    }

    &.bg-narrow {
        --bgCont: var(--bgStrip);
        --container-width-adjust: var(--container-pad, var(--vpad, 0px)) * -2;
        background: none;
        padding-left: var(--container-pad, var(--vpad));
        padding-right: var(--container-pad, var(--vpad));
    }

    @include titles($this: &);

    &__text {
        position: relative;
        width: 100%;
        align-self: var(--text-v-align, unset);
        .js-scroll-anime & {
            z-index: 0;
        }
        table {
            @extend %table;
        }
        // :where(*:not(picture)) > img {
        //     max-width: 100%;
        //     height: auto;
        //     display: block;
        // }

        .v-center & {
            --text-v-align: center;
        }
        .v-bottom & {
            --text-v-align: flex-end;
        }
        .v-center-bottom > & {
            display: flex;
            flex-direction: column;
            &::before {
                content: '';
                display: block;
                margin-bottom: auto;
            }
        }
        .v-center-top > & {
            display: flex;
            flex-direction: column;
            &::after {
                content: '';
                display: block;
                margin-top: auto;
            }
        }

        [style*='font-']:not([class*='js-']) {
            font-family: inherit !important;
            font-size: inherit !important;
            //text-align: inherit !important;
            color: inherit !important;
        }

        [href^='http'] {
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }
    }

    &__image {
        position: relative;

        display: grid;
        grid-template-areas: 'main';
        margin-left: auto;
        margin-right: auto;

        .js-scroll-anime & {
            z-index: -1;
        }
        .js-image-anime & {
            overflow: hidden;
        }
        &--before {
            margin-bottom: calc(var(--vpad) / 2);
            &:last-child {
                margin-bottom: 0;
            }
        }
        &--after {
            margin-top: calc(var(--vpad) / 2);
            &:first-child {
                margin-top: 0;
            }
        }
        &--before,
        &--after {
            img {
                // display: block;
                // max-width: 100%;
                // height: auto;
                width: auto;
                margin-left: auto;
                margin-right: auto;

                @media screen and (min-width: $screenMedium) {
                    margin: 0 var(--image-marginRight, auto) 0 var(--image-marginLeft, 0);
                }
            }
        }

        picture,
        .image__credits {
            grid-area: main;
        }
        .image__credits {
            padding: 0.2em 0.4em;
            justify-self: flex-end;
            align-self: flex-end;

            color: $colBianco;
            text-shadow: 0 0 3px $colNero;
        }

        figcaption {
            display: block;
            position: relative;
            z-index: 1;
            padding: 0.3em;
            text-align: center;
            font-size: 0.85em;
            &::before {
                content: '';
                @include abs-cover(-1);
                background: var(--colNero);
                opacity: 0.03;
            }
        }
    }

    // ! Strip column
    &--columns {
        --span: off;
        @media screen and (max-width: #{$screenMedium - .1}) {
            --strip-textNarrow-paddingLeft: var(--container-pad);
            --strip-textNarrow-paddingRight: var(--container-pad);
        }
        @media screen and (min-width: $screenMedium) {
            --span: 12;
            --strip-col-bgPad: -2;
        }
        .container {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            row-gap: var(--hpad, var(--vpad));

            @media screen and (min-width: $screenMedium) {
                --strip-column-gap: var(--hpad, var(--vpad));
                display: grid;
                grid-template-columns: repeat(24, minmax(1em, 1fr));
            }
        }
    }
    &--3-cols {
        --span: off;
        --strip-column-fraction: 3 * 2;
        @media screen and (max-width: #{$screenLarge - .1}) {
            --strip-textNarrow-paddingLeft: var(--container-pad);
            --strip-textNarrow-paddingRight: var(--container-pad);
        }
        @media screen and (min-width: $screenMedium) {
            --span: off;
            --strip-col-bgPad: unset;
        }
        @media screen and (min-width: $screenLarge) {
            --span: 8;
            --strip-col-bgPad: -2;
        }
        .container {
            @media screen and (min-width: $screenMedium) {
                --strip-column-gap: 0;
                grid-template-columns: none;
            }
            @media screen and (min-width: $screenLarge) {
                --strip-column-gap: var(--hpad, var(--vpad));
                grid-template-columns: repeat(24, minmax(1em, 1fr));
            }
        }
    }
    &__column {
        $col: &;

        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 100%;

        margin-right: calc(var(--strip-column-gap, 0) / var(--strip-column-fraction, 2));
        margin-left: calc(var(--strip-column-gap, 0) / var(--strip-column-fraction, 2));
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
            // margin-left: 0;
        }

        &.waiting {
            #{$this}__image {
                transform: none;
            }
        }

        &[class*='bg-'] {
            position: relative;
            z-index: 1;
            &::before {
                content: '';
                position: absolute;
            }
            .m-filters-open & {
                z-index: unset;
                @media screen and (min-width: $screenMedium) {
                    z-index: 1;
                }
            }
        }
        &.full-height {
            min-height: calc(100vh - var(--header-height, 0) * 1px);
            #{$this} {
                &__text {
                    height: auto;
                }
            }
        }

        &::before {
            z-index: -1;
            background: var(--bgStrip, transparent);
            background-image: var(--bgImage, none);
            top: 0;
            bottom: 0;
            left: calc(var(--hpad, var(--vpad)) / var(--strip-col-bgPad, -1));
            right: calc(var(--hpad, var(--vpad)) / var(--strip-col-bgPad, -1));
            pointer-events: none;
        }

        &.mobile-first {
            order: -1;
            @media screen and (min-width: $screenMedium) {
                order: unset;
            }
        }

        &__content {
            // --container-pad: calc(var(--vpad) / 4);
            width: 100%;
            height: 100%;
            display: flex;
            //z-index: 1;

            .text-narrow & {
                padding-left: var(--strip-textNarrow-paddingLeft, 0);
                padding-right: var(--strip-textNarrow-paddingRight, 0);

                @media screen and (min-width: $screenMedium) {
                    max-width: calc(
                        var(--strip-textNarrow-maxWidth, #{math.div($contWidth, 2)}) + var(--vpad)
                    );
                    margin-left: var(--strip-textNarrow-marginLeft, 0);
                    margin-right: var(--strip-textNarrow-marginRight, 0);
                }
            }
        }
        // ! Colonna Sovrapposta ----
        &--overlap {
            @media screen and (min-width: $screenMedium) {
                &-up {
                    #{$this}__text {
                        transform: translateY(calc(var(--vpad) * -1));
                    }
                }
                &-down {
                    #{$this}__text {
                        transform: translateY(var(--vpad));
                    }
                }
                &-up-down {
                    #{$this}__text {
                        transform: translateY(var(--vpad));
                        margin-top: calc(var(--vpad) * -2);
                    }
                }
            }
        }

        &--image {
            #{$this} {
                &__text {
                    height: 100%;
                    position: relative;
                    z-index: 1;
                }
                &__image {
                    height: 100%;
                    img {
                        max-height: 40vh;
                        @media screen and (min-width: $screenMedium) {
                            max-height: none;
                            width: calc(100% + var(--hpad, var(--vpad)) / 2);
                        }
                    }
                }
            }
            img {
                max-width: none;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &.col-sticky {
            #{$col} {
                &__content {
                    align-items: flex-start;
                }
            }
        }

        &--1 {
            --strip-textNarrow-marginLeft: auto;
            grid-column: span calc(var(--span, 24) + var(--w1, 0) - var(--w0, 0));
            #{$this}--cross-cols & {
                position: relative;
                z-index: 2;
            }
            #{$this}--wide & {
                --strip-textNarrow-paddingLeft: var(--container-pad);
            }
            &:not(.bg-narrow)::before {
                left: var(--column-bg-left, calc(100% - 50vw + var(--hpad, var(--vpad)) / 2));
                transform: rotate(180deg);
            }

            .bg-narrow &#{$col}--image {
                #{$this}__image {
                    width: calc(100% + (var(--hpad, var(--vpad)) * 2));
                    transform: translateX(calc(var(--hpad, var(--vpad)) * -1));
                    @media screen and (min-width: $screenMedium) {
                        width: calc(100% + var(--hpad, var(--vpad)));
                    }
                }
            }
        }
        &--2 {
            --strip-textNarrow-marginRight: auto;
            grid-column: span calc(var(--span, 24) + var(--w2, 0) - var(--w1, 0));
            #{$this}--wide & {
                --strip-textNarrow-paddingRight: var(--container-pad);
            }
            #{$this}--3-cols & {
                --strip-column-fraction: 3;
            }
            &:last-child {
                &:not(.bg-narrow)::before {
                    right: var(--column-bg-right, calc(100% - 50vw + var(--hpad, var(--vpad)) / 2));
                }
            }

            .bg-narrow &#{$col}--image:last-child {
                #{$this}__image {
                    width: calc(100% + (var(--hpad, var(--vpad)) * 2));
                    transform: translateX(calc(var(--hpad, var(--vpad)) * -1));

                    @media screen and (min-width: $screenMedium) {
                        width: calc(100% + var(--hpad, var(--vpad)));
                        transform: none;
                    }
                }
            }
        }
        &--3 {
            --strip-textNarrow-marginRight: auto;
            grid-column: span calc(var(--span, 24) + var(--w3, 0) - var(--w2, 0));
            &:last-child {
                &:not(.bg-narrow)::before {
                    right: calc(100% - 50vw + var(--hpad, var(--vpad)) / 2);
                }
            }
            #{$this}--wide & {
                --strip-textNarrow-paddingRight: var(--container-pad);
            }
            .bg-narrow &#{$col}--image:last-child {
                #{$this}__image {
                    width: calc(100% + (var(--hpad, var(--vpad)) * 2));
                    transform: translateX(calc(var(--hpad, var(--vpad)) * -1));

                    @media screen and (min-width: $screenMedium) {
                        width: calc(100% + var(--hpad, var(--vpad)));
                        transform: none;
                    }
                }
            }
        }
    }

    // ! Riga immagine --image
    &--image {
        --image-text-padding: var(--vpad) var(--container-pad);
        .strip {
            --image-text-padding: ;
        }
        #{$this}__data {
            display: grid;
            grid-template-columns: minmax(1em, 1fr);
            grid-template-rows: 1fr;
            grid-template-areas: 'main';
            justify-items: center;
            align-items: stretch;
            position: relative;
            html:not(.np_a11y) &.text-opaque,
            html:not(.np_a11y) &.text-inbox {
                @media screen and (min-width: $screenMedium) {
                    #{$this} {
                        &__image {
                            margin-bottom: 0;
                            min-height: 100%;
                        }
                        &__text {
                            grid-area: main;
                            position: relative;
                            z-index: 5;
                            max-width: var(--text-max-width, none);
                            padding: var(--image-text-padding);
                            margin-left: var(--image-marginLeft, 0);
                            margin-right: var(--image-marginRight, auto);
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
        }
        #{$this} {
            &__image {
                grid-area: main;
                position: relative;
                z-index: 0;
                overflow: hidden;
                margin-bottom: calc(var(--vpad) / 2);
                max-height: var(--strip-image-maxHeight, none);
                &:last-child {
                    margin-bottom: 0;
                }
                &--small {
                    --strip-image-maxHeight: 50vh;
                }
                &--big {
                    --strip-image-maxHeight: 80vh;
                }
                &--dark {
                    #{$this} {
                        html:not(.np_a11y) &__image {
                            video,
                            img {
                                filter: brightness(0.5) contrast(1.25);
                            }
                        }
                        html:not(.np_a11y) &__text {
                            @media screen and (min-width: $screenMedium) {
                                --colTitle: var(--colBianco);
                                --strip-text-color: var(--colBianco);
                                color: var(--colBianco);
                            }
                        }
                    }
                }
                &--text-narrow {
                    --text-max-width: #{$contWidthNarrow};
                }
                &--text-normal {
                    --text-max-width: #{$contWidth};
                }
                img,
                video {
                    // display: block;
                    // height: auto;
                    width: 100%;
                    margin: 0 auto;
                    object-fit: cover;
                }
                &--real-size {
                    img {
                        // max-width: 100%;
                        width: auto;
                        min-height: 0;
                    }
                }
            }
            &__video__container {
                grid-area: main;
                + #{$this}__text {
                    pointer-events: none;
                }
            }
        }

        &#{$this}--wide {
            .parallax {
                #{$this} {
                    &__image {
                        height: var(--strip-image-maxHeight, 65vh);
                    }
                }
            }
            #{$this} {
                &__image {
                    display: block;
                    width: 100%;
                    img {
                        height: 100%;
                    }
                    .image__credits {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
            #{$this} {
                &__text {
                    max-width: $contWidthNarrow;
                    padding-left: var(--container-pad);
                    padding-right: var(--container-pad);
                    margin-left: auto;
                    margin-right: auto;
                    .container {
                        padding-left: 0;
                        padding-right: 0;
                    }
                    #{$this} {
                        &__text {
                            max-width: none;
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        .parallax {
            #{$this} {
                &__image {
                    overflow: hidden;
                    img {
                        height: 150%;
                    }
                }
            }
        }
        &.js-scroll-title {
            #{$this} {
                &__image {
                    margin-bottom: 0;
                }
                &__text {
                    color: #fff;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    grid-area: main;
                    width: 100%;
                    max-width: none;
                    position: relative;
                    z-index: 5;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                }
            }
        }
        &.js-scroll-reveal {
            #{$this} {
                &__image {
                    clip-path: inset(20% 40%);
                }
            }
        }
    }

    // ! Slider ----
    &__slider {
        $slider: &;
        display: block;

        &__item {
            display: grid;
            gap: calc(var(--vpad) / 2);
            position: relative;
            // padding: 0 calc(var(--vpad) / 4);
            margin-right: calc(var(--vpad) / 2);
            grid-template-areas:
                'figure'
                'text';
            align-items: start;
            #{$slider}--reverse & {
                grid-template-areas:
                    'text'
                    'figure';
            }
            &--cols {
                @media screen and (min-width: $screenMedium) {
                    grid-template-areas: 'figure text';
                    grid-template-columns: minmax(1em, 1fr) minmax(1em, 1fr);
                    #{$slider}--reverse & {
                        grid-template-areas: 'text figure';
                    }
                }
            }
            // .carousel & {
            //     padding-right: calc(var(--vpad) / 2);
            //     padding-bottom: calc(var(--vpad) / 2);
            // }
        }
        &__figure {
            @extend %animate2;
            grid-area: figure;
            figure {
                display: grid;
                grid-template-areas: 'main';
            }
            picture,
            .image__credits {
                grid-area: main;
            }
            .image__credits {
                padding: 0.2em 0.4em;
                justify-self: flex-end;
                align-self: flex-end;

                color: $colBianco;
                text-shadow: 0 0 3px $colNero;
            }
        }
        &__text {
            grid-area: text;
        }
        &__paging {
            list-style: none;
            margin: var(--vpad) 0;
            padding: 0;

            display: flex;
            justify-content: center;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__link {
            color: inherit;
        }
        &__arrows {
            margin-top: calc(var(--vpad) / 2);
            display: flex;
            a {
                font-size: 1.5em;
                margin-right: 1em;
                &.off {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }

    // ! Video (con thumb)
    &__video {
        display: block;
        width: 100%;
        height: 100%;
        visibility: hidden;
        object-fit: cover;
        &--inline {
            &:focus-within {
                overflow: visible;
            }
        }
        &--inline & {
            visibility: visible;
            object-fit: contain;
            background: var(--bgStrip, var(--colBg));
        }
        .active & {
            visibility: visible;
        }
        &__container {
            @extend %actFigure;
            position: relative;
            display: block;
            &:hover {
                @extend %actFigureHover;
            }
            &::before {
                @extend %fontIcons;
                @extend %icon-play;
                position: absolute;
                z-index: 2;
                left: 50%;
                top: 50%;
                margin: 0;
                color: $colBianco;
                font-size: 2em;
                transform: translate(-50%, -50%);
            }
            &.active {
                &::after,
                &::before {
                    display: none;
                }
            }
        }
        &__thumb {
            @include abs-cover(1);
            width: 100%;
            height: 100%;
            object-fit: cover;
            .active & {
                display: none;
            }
        }
    }

    // ! Gallery
    &__gallery {
        $gal: &;
        --col-width: 300px;
        display: grid;
        gap: calc(var(--vpad) / 3);
        grid-template-columns: repeat(auto-fill, minmax(var(--col-width), 1fr));
        text-align: center;
        position: relative;
        #{$gal}--slider &,
        &.splide,
        &.slickslider {
            :where(html:not(.np_a11y)) & {
                display: block;
            }
            .builder & {
                display: grid;
            }
        }
        &__link {
            display: flex;
        }
        picture {
            overflow: hidden;
            display: grid;
            grid-template-areas: 'main';
            width: 100%;
            source {
                display: none;
            }
            .image__credits {
                grid-area: main;
            }
        }
        .image__credits {
            padding: 0.2em 0.4em;
            justify-self: flex-end;
            align-self: flex-end;

            color: $colBianco;
            text-shadow: 0 0 3px $colNero;
        }
        img {
            // display: block;
            grid-area: main;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &--video {
            display: block;
            position: relative;
            color: var(--colBianco);
            &:hover {
                color: var(--colBianco);
            }
            &::after {
                @extend %fontIcons;
                @extend %icon-play-circled;
                margin: 0;
                position: absolute;
                font-size: 2em;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }
        .animate & {
            > * {
                @extend %animate2;
                @for $i from 1 to 12 {
                    &:nth-child(#{$i}n) {
                        transition-delay: ($i * 0.08s);
                    }
                }
            }
        }
        .waiting & {
            > * {
                opacity: 0;
                transform: translate3d(0, 50%, 0);
            }
        }
    }

    // ! Blurb & Counter
    &__blurb,
    &__counter {
        $item: &;
        // --strip-blurb-margin: calc(var(--hpad, var(--vpad)) / 2);
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        // margin-left: calc(var(--strip-blurb-margin) * -1);
        // margin-right: calc(var(--strip-blurb-margin) * -1);

        gap: var(--strip-blurb-margin, calc(var(--hpad, var(--vpad)) / 2));

        &__item {
            color: inherit;
            flex: 1 1 auto;
            // margin: 0 var(--strip-blurb-margin) calc(var(--strip-blurb-margin) * 2);
            max-width: clamp(
                (
                        99.9% - var(--strip-blurb-margin, var(--hpad, var(--vpad)) / 2) *
                            (var(--strip-blurb-number, 4) - 1)
                    ) / var(--strip-blurb-number, 4),
                (36em - 100vw) * 1000,
                100%
            );
            min-width: min(
                calc(var(--container-maxWidth) / (var(--strip-blurb-number, 4) + 2)),
                100%
            );
            width: 100%;

            display: flex;
            flex-direction: var(--strip-blurb-direction, column);

            #{$this}__column & {
                margin-bottom: 0;
            }
        }
        &__item {
            counter-increment: blurbNumber;
            &[class*='bg-custom'],
            &[class*='bg-np'] {
                flex: 0 0 auto;
                padding: 1em;
                align-items: stretch;
                background: var(--bgStrip, transparent);
                color: var(--strip-text-color, inherit);
                #{$item} {
                    &__text {
                        position: relative;
                    }
                }
            }
        }
        &__number {
            $nw: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.3em;
            width: $nw;
            height: $nw;
            margin: 0 auto 0.5em;
            position: relative;
        }
        &__text {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            width: 100%;
            flex: 1 1 auto;
            &::before {
                $nw: 2em;
                content: counter(blurbNumber);
                display: var(--strip-blurb-number-display, none);
                justify-content: center;
                align-items: center;
                font-size: 2em;
                padding: 0.3em;
                width: $nw;
                height: $nw;
                margin: 0 auto 0.5em;
                position: relative;
            }
        }
        &__title {
            @extend %fontTitoli;
            margin-bottom: calc(var(--vpad) / 4);
        }
    }

    // ! Blurb
    &__blurb {
        $blurb: &;
        &--n1 {
            --strip-blurb-number: 1;
        }
        &--n2 {
            --strip-blurb-number: 2;
        }
        &--n3 {
            --strip-blurb-number: 3;
        }
        &--n4 {
            --strip-blurb-number: 4;
        }
        &--n5 {
            --strip-blurb-number: 5;
        }
        &--n6 {
            --strip-blurb-number: 6;
        }
        &--n7 {
            --strip-blurb-number: 7;
        }
        &--n8 {
            --strip-blurb-number: 8;
        }

        &--imgSmall {
            --strip-blurb-image-width: 10em;
            --strip-blurb-horizontal-image-width: 15%;
        }
        &--imgWide {
            --strip-blurb-image-width: 100%;
            --strip-blurb-horizontal-image-width: 50%;
        }
        &--imgHeightSmall {
            --strip-blurb-image-height: 5em;
        }
        &--imgHeightTall {
            --strip-blurb-image-height: 10em;
        }

        &--horizontal {
            --strip-blurb-direction: row;
        }

        &--numbered {
            --strip-blurb-number-display: flex;
            counter-reset: blurbNumber;
        }

        &__item {
            #{$blurb}--text-over & {
                display: grid;
                grid-template-areas: 'main';
                align-items: stretch;
                > * {
                    grid-area: main;
                }
            }
        }
        &__link {
            @extend #{$blurb}__item;

            #{$blurb} {
                &__item {
                    width: 100%;
                    height: 100%;
                    min-width: 0;
                    max-width: none;
                    margin: 0;
                    &[class*='bg-custom'],
                    &[class*='bg-np'] {
                        position: relative;
                        overflow: hidden;
                        &::before {
                            @extend %animate2;
                            content: '';
                            @include abs-cover(0);
                            background: var(--colNero);
                            transform: translate3d(0, 50%, 0);
                            opacity: 0;
                        }
                    }
                }
                &__image {
                    overflow: hidden;
                    img {
                        @extend %animate2;
                    }
                }
            }
            &:hover {
                color: inherit;
                #{$blurb}__item[class*='bg-custom'],
                #{$blurb}__item[class*='bg-np'],
                #{$blurb}--text-over & {
                    color: var(--colBianco);
                }
                #{$blurb} {
                    &__image {
                        img {
                            transform: scale3d(1.1, 1.1, 1);
                            #{$blurb}--text-over & {
                                opacity: 0.3;
                            }
                        }
                    }
                    &__item {
                        &[class*='bg-custom'],
                        &[class*='bg-np'] {
                            &::before {
                                transform: none;
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }
        &__image {
            display: grid;
            grid-template-areas: 'main';
            margin: 0 auto var(--hpad, calc(var(--vpad) / 2));
            width: var(--strip-blurb-image-width, calc(40em / var(--strip-blurb-number, 4)));
            max-width: 100%;
            #{$blurb}--horizontal & {
                margin: 0 var(--hpad, calc(var(--vpad) / 2)) 0 0;
                flex: 0 0 var(--strip-blurb-horizontal-image-width, 25%);
                min-width: calc(#{$contWidth} / (var(--strip-blurb-number, 4) + 1) * 0.2);
                aspect-ratio: 1/1;
            }
            #{$blurb}--text-over & {
                margin: 0;
                width: 100%;
                background: var(--colNero);
            }
            picture,
            .image__credits {
                grid-area: main;
            }
            .image__credits {
                padding: 0.2em 0.4em;
                justify-self: flex-end;
                align-self: flex-end;

                color: $colBianco;
                text-shadow: 0 0 3px $colNero;
            }
            img {
                // display: block;
                margin: 0 auto;
                width: 100%;
                height: var(--strip-blurb-image-height, auto);
                object-fit: contain;
                #{$blurb}--horizontal & {
                    height: 100%;
                    object-fit: contain;
                    object-position: 50% 0;
                }
                #{$blurb}--text-over & {
                    height: 100%;
                    object-fit: cover;
                    opacity: 0.5;
                }
            }
        }
        &__number {
            #{$blurb}__image + & {
                margin-top: calc(var(--vpad) * -1.5);
            }
        }
        &__text {
            #{$blurb}--text-over & {
                padding: 1em;
                color: var(--colBianco);
                position: relative;
            }
        }
        &__buttons {
            padding-top: 1em;
            margin-top: auto;
        }
    }

    // ! Counter
    &__counter {
        $count: &;

        &__values {
            @extend %fontTitoli;
            text-align: center;
            text-transform: uppercase;
            line-height: 1.1;
            margin-bottom: 1em;
            display: grid;
            grid-template-areas: 'main';
            align-items: center;
            &.pie-chart--empty {
                width: 100%;
                max-width: 9em;
                margin-left: auto;
                margin-right: auto;
                &::before {
                    content: '';
                    display: block;
                    grid-area: main;
                    width: 100%;
                    margin: auto;
                    padding-bottom: calc(100% - 6px);
                    border: 3px solid var(--colContrasto);
                    border-radius: 50%;
                }
            }
            &__text {
                grid-area: main;
                position: relative;
                z-index: 1;
                padding: 0 0.5em;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &__main {
                font-weight: inherit;
                font-size: 3.2em;
                margin: 0;
            }
            &__sub {
                margin: 0;
            }
            canvas {
                grid-area: main;
                width: 100% !important;
                height: auto !important;
                max-width: 9em;
                margin: auto;
            }
        }
        &__label {
            text-transform: uppercase;
            line-height: 1.2;
            font-size: 0.85em;
            font-weight: $fontStrong;
        }
    }

    // ! Mappa ----
    &__map {
        position: relative;
        z-index: 0;
        height: 40em;
        max-height: 60vh;
        min-height: 100%;
        &__infowin {
            padding: calc(var(--vpad) / 4);
        }
    }
}
