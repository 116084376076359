.side {
    &__container {
        @extend %animate2;
        display: block;
        background: var(--colBianco);
        padding: calc(var(--vpad) / 2);
        @media screen and (min-width: 50em) {
            padding: calc(var(--vpad) / 2) var(--vpad);
        }

        .side-open & {
            transform: none;
        }
    }

    &__content {
        transition: opacity 0.3s ease-in-out;
        display: flex;
        flex-flow: column nowrap;
        height: 100%;

        &.loading {
            opacity: 0;
        }
    }

    &__close {
        position: absolute;
        font-size: 0.75em;
        text-transform: uppercase;
        right: calc(var(--vpad) / 4);
        top: calc(var(--vpad) / 2);
        @media screen and (min-width: 50em) {
            right: calc(var(--vpad) / 2);
        }

        span {
            color: var(--colNero);
        }
    }

    &__footer {
        margin-top: auto;
    }

    &__screen {
        @extend %animate2;
        @include abs-cover(10);
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        opacity: 0;
        background: rgba(0, 0, 0, 0.6);

        .loading & {
            visibility: visible;
            opacity: 1;
        }

        .spinner {
            margin: 100px auto 0;
            width: 70px;
            text-align: center;

            > div {
                width: 18px;
                height: 18px;
                background-color: var(--colContrasto);

                border-radius: 100%;
                display: inline-block;
                animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            }

            .bounce1 {
                animation-delay: -0.32s;
            }

            .bounce2 {
                animation-delay: -0.16s;
            }
        }
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

.side-cart {
    &__items {
        overflow: auto;
        margin: calc(var(--vpad) / 2) 0;
        padding-right: 1em;
    }

    &__item {
        display: grid;
        margin-top: 1.5em;

        @media screen and (min-width: 50em) {
            grid-template-columns: max(8em, 25%) 1fr;
        }

        .cart__title + &,
        &:first-child {
            margin-top: 0;
        }

        &__image {
            @media screen and (min-width: 50em) {
                padding-right: 1em;
            }

            img {
                max-width: 100%;
                object-fit: contain;
                width: 12em;
                margin: 0 auto;
                @media screen and (min-width: 50em) {
                    width: auto;
                    margin: 0;
                }
            }
        }

        &__description {
            p {
                margin-bottom: 0;
            }
        }

        &__dett {
            font-size: 0.8em;
            margin-top: 0.75em;
            color: var(--colNero);

            span {
                color: var(--colPrimario);
                margin-right: 0.2em;
            }
        }

        &__name {
            @extend %fontTitoli;
            font-size: 1.6em;
            text-transform: uppercase;
            color: var(--colTitle);
            margin-bottom: 0.5em;
        }

        &__subname {
            @extend %fontTitoli;
            color: var(--colTitle, inherit);
            font-size: 1.2em;
        }

        &__qty {
            width: 33px;
            height: 33px;
            display: flex;
            align-items: center;
            border: 2px solid #0c0c0c;

            p {
                width: 100%;
                text-align: center;
                color: var(--colNero);
            }
        }

        &__price {
            margin-top: 0.25em;
            text-align: right;
            @media screen and (min-width: 50em) {
                text-align: unset;
            }
            .product__price {
                margin: 0;
                font-size: 1.5em;
            }
        }

        &__tools {
            --inputH: 2.2em;
            display: flex;
            flex-flow: column nowrap;
            padding-left: calc(var(--vpad) / 4);
            font-size: 0.9em;
            align-items: flex-end;
        }
    }

    &__buttons {
        margin-top: 0.5rem;

        [class*='icon-']::before {
            margin-right: 0;
            font-size: 1em;
            color: var(--colPrimario);
            vertical-align: middle;
        }

        span {
            text-transform: uppercase;
            font-size: 0.75em;
        }
    }

    &__promo,
    &__subtotal,
    &__total {
        p {
            display: flex;
            justify-content: space-between;
            line-height: 1.3;
            margin-bottom: 0.5rem;
        }

        p:nth-child(2) {
            margin-bottom: 0;
        }
    }

    &__promo {
        p {
            align-items: flex-end;
        }

        strong {
            font-size: 0.85em;
            text-transform: uppercase;
        }

        &__label {
            margin-right: auto;
        }

        &__del {
            align-self: flex-start;
            padding: 0.1em;
        }
    }

    &__total {
        text-transform: uppercase;
        //font-weight: $fontStrong;
        border-top: 2px solid var(--colNero);
        border-bottom: 2px solid var(--colNero);
        margin: 1rem 0;
        padding: 1rem 0;
        font-size: 0.85em;
        @media screen and (min-width: 50em) {
            font-size: 1em;
        }
    }

    &__value {
        min-width: 45%;
        margin-right: 0.5em;
        text-align: right;
    }

    &__promo-text {
        font-size: 0.85em;
        padding-top: 1rem;

        p {
            margin-bottom: 0.5em;
        }
    }
    &__recap {
        .buttons {
            display: grid;
            gap: 1rem;
            justify-content: center;
            a {
                font-size: 1em;
                &:not(.button) {
                    color: var(--colNero);
                }
            }
        }
    }
}
