// !Fonts --------------------

@if ($notA11y) {
    @include linkFont('NotoSans-Regular', 'Noto Sans');
    @include linkFont('NotoSans-Italic', 'Noto Sans', 400, italic);
    @include linkFont('NotoSans-Light', 'Noto Sans', 300);
    @include linkFont('NotoSans-LightItalic', 'Noto Sans', 300, italic);
    @include linkFont('NotoSans-SemiBold', 'Noto Sans');
    @include linkFont('NotoSans-SemiBoldItalic', 'Noto Sans', 600, italic);
} @else {
    @include linkFont('Atkinson-Hyperlegible-Regular', 'Atkinson', 400, $otf: true);
    @include linkFont('Atkinson-Hyperlegible-Italic', 'Atkinson', 400, italic, $otf: true);
    @include linkFont('Atkinson-Hyperlegible-Bold', 'Atkinson', 700, $otf: true);
    @include linkFont('Atkinson-Hyperlegible-BoldItalic', 'Atkinson', 700, italic, $otf: true);
}
@include linkFont('fontello', 'fontello');
