@use 'sass:math';
@import 'typography';
// Strip adjust -------------

.strip {
    $this: &;
    --hpad: var(--vpad);
    // overflow: hidden;
    .container {
        .slickslider & {
            max-width: none;
            padding-left: 0;
            padding-right: 0;
        }
    }
    &--full-height {
        min-height: 100vh;
        .container {
            align-self: center;
        }
        #{$this} {
            &__text {
                height: auto;
            }
        }
    }
    &--decoration {
        &-v {
            position: relative;
            z-index: 0;
            &::before {
                @extend %animate2;
                animation-delay: 0.4s;
                position: absolute;
                left: 50%;
                top: 0;
                bottom: 0;
                border-left: 2px solid var(--colDecoration, var(--colContrasto));
                transform-origin: 50% 0;
                @media screen and (min-width: $screenMedium) {
                    content: '';
                }
            }
            &.js-waiting-deco-top::before {
                transform: scale3d(1, 0, 1);
            }
        }
        &-h {
            position: relative;
            z-index: 0;
            &::after {
                @extend %animate2;
                animation-delay: 0.6s;
                content: '';
                position: absolute;
                // left: var(--container-pad, var(--vpad));
                // right: var(--container-pad, var(--vpad));
                left: 0;
                right: 0;
                top: 0;
                border-top: 2px solid var(--colDecoration, var(--colContrasto));
                transform-origin: 50% 50%;
            }
            &.js-waiting-deco-bottom::after {
                transform: scale3d(0, 1, 1);
            }
        }
    }

    &--stamp {
        position: relative;
        &::after {
            $w: 7em;
            @extend %animate2;
            transition-property: opacity;
            content: '';
            position: absolute;
            z-index: 10;
            top: math.div($w, -2);
            right: calc(var(--vpad) / 2);
            width: $w - 2;
            height: $w - 2;
            background: transparent url('/img/paszkowski-stamp.svg') no-repeat 50% 50%;
            background-size: contain;
            transform: translate3d(0, var(--stamp-y, 0), 0);
            font-size: 0.6em;
            @media screen and (min-width: $screenSmall) {
                right: var(--vpad);
                width: $w;
                height: $w;
                font-size: 1em;
            }
        }
        &.strip__column--1 {
            &::after {
                right: auto;
                left: var(--vpad);
            }
        }
        &.waiting {
            &::after {
                opacity: 0;
            }
        }
    }

    &__column {
        @media screen and (min-width: 43.76em) {
            ul,
            ol {
                --ulPad: 1.5em;
            }
            &.full-height {
                min-height: 100vh;
                #{$this} {
                    &__image {
                        &:only-child {
                            img {
                                height: 100vh;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }
        &--sticky {
            #{$this} {
                &__text {
                    align-self: flex-start;
                    position: sticky;
                    top: 30vh;
                }
            }
        }
    }

    &--image {
        &#{$this}--wide {
            #{$this} {
                &__text {
                    max-width: calc(
                        var(--container-maxWidth) + var(--container-pad, var(--vpad)) * 2 +
                            var(--container-width-adjust, 0px)
                    );
                }
            }
        }
    }
    &__image--overlap {
        #{$this} {
            &__image {
                width: calc(100% + var(--hpad) * 2);
            }
        }
        &#{$this}__column--2 {
            img {
                transform: translateX(calc(var(--hpad) * -2));
            }
        }
    }

    &__gallery {
        $gal: &;
        --col-width: 250px;
        .strip--wide & {
            &.splide {
                margin-left: auto;
                margin-right: 0;
                width: calc(100vw - var(--vpad));
                max-width: calc((100% + #{$contWidth}) / 2);
            }
            .splide {
                &__track {
                    padding-right: max(
                        50vw - #{math.div($contWidth, 2)},
                        var(--container-pad)
                    ) !important;
                }
            }
        }
        &__link {
            overflow: hidden;
            img {
                @extend %animate2;
            }
            &:hover {
                img {
                    transform: scale3d(1.02, 1.02, 1);
                }
            }
        }
        &--slider {
            #{$gal} {
                &__item {
                    margin-right: 1em;
                    .builder & {
                        margin-right: 0;
                    }
                }
            }
            img {
                max-height: 70vh;
                object-fit: cover;
                @media screen and (min-width: $screenMedium) {
                    max-width: 80vw;
                    max-height: 50vh;
                }
            }
        }
    }

    &__counter {
        &__values {
            text-align: inherit;
            canvas {
                display: none;
            }
            &__main {
                font-size: 3.8em;
                color: var(--strip-text-color, inherit);
                span {
                    color: var(--colContrasto);
                }
            }
        }
    }

    &__blurb {
        $blurb: &;
        &__title {
            font-size: clamp(var(--titleSize) / 3, 2.4vw, var(--titleSize) / 2.5);
        }
        &__item {
            border-radius: 0.4em;
        }
        &__link {
            #{$blurb} {
                &__item {
                    @extend %animate2;
                    transition-property: transform, box-shadow;
                    &[class*='bg-custom'],
                    &[class*='bg-np'] {
                        &::before {
                            transform: none;
                        }
                    }
                }
            }
            &:hover {
                #{$blurb} {
                    &__item[class*='bg-custom'],
                    &__item[class*='bg-np'] {
                        color: inherit;
                        transform: scale3d(1.05, 1.05, 1);
                        box-shadow: 0 2px 0.75em rgba(#000, 0.2);
                        &::before {
                            opacity: 0.02;
                        }
                    }
                }
            }
        }
        &__image {
            margin-bottom: calc(var(--vpad) / 2);
        }
        &__text {
            a {
                &[href^='mailto:'],
                &[href^='http'] {
                    display: inline-block;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    &__map {
        height: 60em;
    }

    // STILI ANIMAZIONI SCROLL -----------

    &.js-image-cover {
        transform: scaleZ(1);
        &.js-fixed {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            transform: scaleZ(1) !important;
        }
        #{$this} {
            &__image {
                height: 35em;
                max-height: 100vh;
                align-items: center;
                picture {
                    position: fixed;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    .builder & {
                        position: absolute;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    &.js-scroll-n-clip {
        height: 100vh;
        overflow: hidden;
        .builder & {
            height: auto;
        }
        #{$this} {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            transform: translate(100vw, 0);
            &:first-child {
                transform: none;
            }
            &__image {
                img {
                    height: 100vh;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &.js-image-slide {
        transform: scaleZ(1);
        overflow: hidden;
        #{$this} {
            &__image {
                picture {
                    position: fixed;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    .builder & {
                        position: static;
                        overflow: hidden;
                    }
                }
                img {
                    height: 100vh;
                    width: 100%;
                    object-fit: cover;
                    transform: scale3d(1.2, 1.2, 1);
                    .builder & {
                        height: 100%;
                    }
                }
            }
        }
    }

    &.js-zoom-splash {
        transform: scaleZ(1);
        .strip {
            &__text {
                &:has(.strip) {
                    display: flex;
                    overflow: hidden;
                    justify-content: center;
                    align-items: center;
                    gap: calc(var(--vpad) / 3);
                }
                .strip {
                    min-width: 30vw;
                    width: 100%;
                    &--columns {
                        display: none;
                        @media screen and (min-width: 36em) {
                            display: block;
                            min-width: 50vw;
                        }
                        .container {
                            --strip-column-gap: calc(var(--vpad) / 3);
                        }
                    }
                    &--image {
                        z-index: 1;
                        #{$this} {
                            &__image {
                                height: 35em;
                                max-height: 100vh;
                                align-items: center;
                                clip-path: inset(0px);
                                picture {
                                    position: fixed;
                                    left: 0;
                                    top: 0;
                                    width: 100%;
                                    height: 100%;
                                    z-index: 1;
                                    .builder & {
                                        position: absolute;
                                    }
                                    &::before {
                                        content: '';
                                        @include abs-cover(1);
                                        background: var(--colNero);
                                        opacity: var(--screenOpacity, 0);
                                    }
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        transform: scale3d(0.7, 0.7, 1);
                                    }
                                }
                            }
                            &__text {
                                position: absolute !important;
                                z-index: 2;
                                width: 100vw;
                                max-width: $contWidthNarrow;
                                transform: scale3d(1.2, 1.2, 1);
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
