// ! Carrello / Progetto -----
.cart {
    margin: calc(var(--vpad) / 2) 0;
    &:last-child {
        margin-bottom: 0;
    }
    &__title {
        @extend %fontTitoli;
        color: var(--colPrimario);
        margin-bottom: calc(var(--vpad) / 3);
        a {
            color: inherit;
            &:hover {
                color: var(--colContrasto);
            }
        }
    }
    &__section {
        margin-bottom: calc(var(--vpad) / 2);
        background: var(--colGrigioLeggero);
        border-radius: 0.4em;
        padding: calc(var(--vpad) / 3);
        &__footer {
            margin: calc(var(--vpad) / 3) calc(var(--vpad) / -3) calc(var(--vpad) / -3);
            padding: calc(var(--vpad) / 3);
            background: var(--colGrigioChiaro);
            border-radius: 0 0 0.4em 0.4em;
            @media screen and (max-width: 43.75em) {
                font-size: 0.85em;
            }
        }
    }
    &__item {
        $pad: 1em;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: stretch;

        border-top: 1px solid var(--colPrimarioChiaro);

        @media screen and (max-width: 65.5em) {
            flex-wrap: wrap;
        }

        @media screen and (max-width: 43.75em) {
            font-size: 0.85em;
        }
        .cart__title + &,
        &:first-child {
            border-top: none;
        }
        &__image {
            width: 12em;
            max-width: 33%;
            padding: $pad $pad $pad 0;
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
        &__description {
            flex: 1 1 auto;
            display: flex;
            flex-flow: column nowrap;
            padding: $pad 0;
            @media screen and (max-width: 65.5em) {
                width: 66%;
            }
        }
        &__name {
            @extend %fontTesto;
            font-size: 1em;
            font-weight: 600;
            text-transform: uppercase;
            a {
                color: var(--colText);
                &:hover {
                    color: var(--colContrasto);
                }
            }
        }
        &__price {
            margin-top: 1em;
            font-weight: 600;
            @media screen and (max-width: 65.5em) {
                margin-top: 0;
            }
        }
        &__qty {
            --inputH: 3em;
            flex: 0 0 auto;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-end;
            justify-content: space-between;
            text-align: right;
            padding: $pad 0 $pad $pad;

            @media screen and (max-width: 65.5em) {
                --inputH: 2em;
                width: 100%;
                flex-flow: row;
                padding: 0 0 $pad $pad;
            }

            .input--number {
                font-size: 0.8em;
            }
        }
        &__tools {
            margin-top: auto;
            padding-top: calc(var(--vpad) / 6);
            .button,
            button {
                font-size: 0.8em;
                margin-right: 1em;
                text-transform: uppercase;
                cursor: pointer;
                text-align: left;
            }
        }
        &__note {
            font-size: 0.8em;
            color: var(--colGrigioScuro);
        }
    }

    &__message {
        &__title {
            @extend %fontTitoli;
            font-size: 0.8em;
            margin-bottom: 1em;
        }
    }

    &__delivery {
        --inputH: 2.5em;
        //font-size: .85em;
        margin-top: 1em;
        padding-top: 1em;
        border-top: 1px dotted var(--colGrigio);

        display: flex;
        align-items: center;
        @media screen and (max-width: 65.5em) {
            flex-wrap: wrap;
        }

        @media screen and (max-width: 43.75em) {
            display: block;
        }

        label {
            margin-bottom: 0;
        }
        &__title {
            margin-right: 1em;
            @media screen and (max-width: 43.75em) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 0.5em;
            }
        }
        &__day {
            margin-right: 1em;
            display: flex;
            align-items: center;
            @media screen and (max-width: 65.5em) {
                margin-right: 0;
            }
            > * {
                margin-right: calc(var(--vpad) / 3);
                @media screen and (max-width: 43.75em) {
                    margin-right: 0;
                }
            }
        }
        &__time {
            @media screen and (max-width: 65.5em) {
                margin-top: 0.5em;
            }
            @media screen and (max-width: 43.75em) {
                margin-top: 1em;
            }
        }
    }

    &__column {
        font-size: 0.9em;
        @media screen and (max-width: 43.75em) {
            margin-top: var(--vpad);
        }
    }
    &__town {
        margin-top: 1em;
        font-size: 0.9em;
        p {
            padding: 0.5em;
            margin-bottom: 0 !important;
            background: var(--colGrigioLeggero);
            + p {
                padding-top: 0;
            }
            &:first-child {
                border-radius: 0.4em 0.4em 0 0;
            }
            &:last-child {
                border-radius: 0 0 0.4em 0.4em;
            }
        }
        strong {
            display: block;
        }
    }

    &__promo,
    &__subtotal,
    &__shipping,
    &__total {
        p {
            display: flex;
            justify-content: space-between;
            line-height: 1.3;
            margin-bottom: calc(var(--vpad) / 4);
        }
        margin-bottom: calc(var(--vpad) / 4);
    }
    &__shipping {
        margin-top: calc(var(--vpad) / 2);
        strong {
            text-transform: uppercase;
            font-size: 0.9em;
        }
    }
    &__promo {
        p {
            align-items: flex-end;
        }
        strong {
            font-size: 0.85em;
            text-transform: uppercase;
        }
        &__label {
            margin-right: auto;
        }
        &__del {
            align-self: flex-start;
            padding: 0.1em;
        }
    }
    &__total {
        font-size: 1.2em;
        font-weight: 700;
        border-top: 1px solid var(--colGrigio);
        margin-top: calc(var(--vpad) / 2);
        padding-top: 0.5em;
    }
    &__value {
        min-width: 45%;
        margin-right: 0.5em;
        text-align: right;
    }
    &__promo-text {
        font-size: 0.85em;
        padding-top: calc(var(--vpad) / 3);
        p {
            margin-bottom: 0.5em;
        }
    }
}

// ! Chechout ---
.checkout {
    &__item {
        margin-bottom: calc(var(--vpad) / 4);
        padding-bottom: calc(var(--vpad) / 4);
        border-bottom: 1px dotted var(--colGrigio);
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
        .strip__title3 {
            font-size: 0.85em;
        }
        .button {
            font-size: 0.7em;
        }
    }
    &__sblock {
        display: inline-block;
        margin-right: 0.5em;
    }
    &__alert {
        display: block;
        font-size: 0.9rem;
        color: var(--colText);
        background: var(--colContrastoChiaro);
        padding: 0.3em 0.7em;
        margin: calc(var(--vpad) / 2) 0;
        border-radius: 0.4em;
    }
}
