@mixin button() {
    $p: .75em;
    --p: #{$p};
    --ph: 0.75em;
    --pr: 0px;
    --aniX: var(--X, 0);
    --aniY: var(--Y, 1);
    --aniExclude: outline, outline-offset;
    @extend %animateBut;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    padding: var(--p) calc(var(--p) + var(--ph) + var(--pr)) calc(var(--p))
        calc(var(--p) + var(--ph));
    background: var(--colFondoBottoni, var(--colPrimario));
    color: var(--colTextBottoni, var(--colBianco));
    border: 1px solid var(--colBordoBottoni, transparent);
    font-weight: 500;
    font-size: .85em;
    text-transform: uppercase;
    line-height: 1.2;
    text-align: center;
    position: var(--buttonPosition, relative);
    z-index: var(--buttonZ, 1);
    overflow: hidden;
    width: 100%;
    border-radius: .5em;
    transform: var(--btTransform, scale3d(1, 1, 1));
    @media screen and (min-width: 36em) {
        width: auto;
    }
    :where(&) + & {
        margin-top: 1em;
        @media screen and (min-width: 36em) {
            &:not(.button--wide) {
                margin-top: 0;
                margin-left: 1em;
                .np_a11y & {
                    margin-left: 2.5em;
                }
            }
        }
    }
    &::after {
        @extend %animate2;
        // content: attr(data-hover);
        content: '';
        @include abs-cover(-1);
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--colBianco);
        // color: var(--colBianco);
        opacity: 0.4;
        transform: translate3d(calc(-100% * var(--aniX)), calc(-100% * var(--aniY)), 0);
    }
    strong {
        color: inherit;
    }

    a:hover &:not(.button--shy),
    &:hover {
        color: var(--colBianco);
        &::after {
            // opacity: 1;
            transform: none;
        }
    }

    @keyframes bt-anim {
        50% {
            // transform: translate3d(0, -100%, 0);
            transform: translate3d(
                calc(-100% * var(--aniX) * -1),
                calc(-100% * var(--aniY) * -1),
                0
            );
            opacity: 0;
        }
        50.001% {
            // transform: translate3d(0, 100%, 0);
            transform: translate3d(calc(-100% * var(--aniX)), calc(-100% * var(--aniY)), 0);
            opacity: 0;
        }
        100% {
            transform: translate3d(0, 0, 0);
        }
    }

    &--small {
        --p: #{$p - 0.5};
    }
    &--big {
        --p: #{$p + 0.5};
    }
    &--wide {
        display: flex;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    &--alternate {
        --colFondoBottoni: var(--colContrasto);
    }
    &--no-icon {
        --pr: 0em;
        font-family: $fontTesto;
        font-weight: $fontNormal;
        strong {
            font-weight: $fontNormal;
        }
        &::before {
            display: none;
        }
    }
    &--outlined {
        --colFondoBottoni: transparent;
        --colBordoBottoni: currentColor;
        --colTextBottoni: inherit;
    }
    &--white {
        --colTextBottoni: var(--colBianco);
    }
    &--no-button {
        border: none;
        color: var(--colTextBottoni, inherit);
        &::after {
            display: none;
        }
        &:hover {
            --colTextBottoni: unset;
            color: var(--colContrasto);
        }
    }
    &--icon {
        --aniTime: 0.3s;
        width: auto;
        padding: calc(var(--p) - 0.2em);
        &::before {
            margin: 0;
            // font-size: 1.2em;
        }
    }
    &--squared {
        border-radius: 0;
    }
    &--rounded {
        height: var(--buttonHeight, 4.5em);
        width: var(--buttonHeight, 4.5em);
        padding: var(--p);
        border-radius: 50%;
    }
    &--disabled {
        --colTextBottoni: var(--colGrigioScuro);
        --colFondoBottoni: var(--colGrigio);
        opacity: 0.5;
        cursor: auto;
        pointer-events: none;
    }
    &[href^='http']:not(.button--standard) {
        &::before {
            order: 2;
            margin-right: 0;
            margin-left: 0.5em;
        }
    }
}
