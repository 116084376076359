@use 'sass:math';

@import '../../../node_modules/@splidejs/splide/dist/css/splide-core.min';

@if ($notA11y) {
    .splide {
        $splide: &;
        &:hover {
            #{$splide} {
                &__arrow:not([disabled]) {
                    opacity: 1;
                }
            }
        }
        #{$splide}-arrows--top & {
            padding-top: 5rem;
        }

        &__track {
            .no-cursor & {
                pointer-events: none;
            }
        }

        &__pagination {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-top: calc(var(--vpad) / 2);
            li {
                margin: 0 0.25em;
                &:only-child {
                    display: none;
                }
            }
            button {
                position: relative;
                display: block;
                // overflow: hidden;
                text-indent: 99rem;
                background: none;
                color: transparent;
                padding: 0;
                cursor: pointer;
                width: 3em;
                @include animate();
                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 50%;
                    width: 100%;
                    background: var(--colDots, var(--colText));
                    height: 3px;
                    border-radius: 1px;
                }
                &::before {
                    opacity: 0.5;
                    @include animate();
                }
                &::after {
                    transform-origin: 0 50%;
                    transform: scale3d(0, 1, 1);
                }
                &.is-active {
                    &::after {
                        animation: activeDot var(--sliderTime, 0.3s) linear forwards;
                    }
                }
            }
            @keyframes activeDot {
                0% {
                    transform: scale3d(0, 1, 1);
                }
                100% {
                    transform: none;
                }
            }
        }

        &__arrows {
            position: absolute;
            z-index: 10;
            bottom: var(--vpad);
            right: calc(var(--vpad) / 2);
            display: grid;
            gap: 1rem;
            font-size: 0.85em;
            @media screen and (min-width: $screenSmall) {
                font-size: 1em;
            }
            @media screen and (min-width: $screenMedium) {
                font-size: 1.4em;
            }
            .splide-arrows--top & {
                position: static;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 1rem;
            }
        }

        $arrow_w: 8em;
        &__arrow {
            $w: $arrow_w;
            $f: 1;
            --colTextBottoni: var(--colBianco);
            --colFondoBottoni: var(--colPrimario);
            --colBordoBottoni: var(--colBianco);
            // --buttonPosition: absolute;
            // --buttonZ: 10;
            border-radius: 50% !important;
            & + & {
                margin: 0 !important;
            }
            &--prev {
                --X: -1;
                --Y: 0;
            }
            &--next {
                --X: 1;
                --Y: 0;
            }
            &::after {
                border-radius: 50%;
            }
            .is-mouse & {
                opacity: 0;
            }
            .splide-arrows--top & {
                opacity: 1;
            }

            svg {
                display: none;
            }
        }

        &__handle {
            $w: 4.5em;
            --aniProp: opacity;
            @extend %animate;
            position: absolute;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: $w;
            height: $w;
            color: var(--colBianco);
            background: var(--colPrimario);
            border-radius: 50%;
            transform: translate3d(-50%, -50%, 0);
            pointer-events: none;
            opacity: 0.8;
            left: calc(95% - #{math.div($w, 2)});
            top: 10%;
            font-size: 0.7em;
            @media screen and (min-width: 36em) {
                font-size: 1em;
            }
            &.visible {
                opacity: 1;
            }
            &::before {
                @extend %fontIcons;
                @extend %icon-drag;
                font-size: 2.6em;
                margin: 0 !important;
            }
        }
    }
}
