$colCustom1: var(--colPrimarioLeggero);
$colCustom2: var(--colContrastoLeggero);
$colCustom3: var(--colContrasto);
$colCustom4: var(--colPrimarioChiaro);
$colDark: var(--colPrimario);

.bg {
    &-half {
        @media screen and (min-width: $screenMedium) {
            &-custom-1 {
                --bgImage: linear-gradient(
                    #{$bgHalfRotate},
                    #{$colCustom1} #{$bgHalfSize},
                    transparent 0
                );
            }
            &-custom-2 {
                --bgImage: linear-gradient(
                    #{$bgHalfRotate},
                    #{$colCustom2} #{$bgHalfSize},
                    transparent 0
                );
            }
            &-custom-3 {
                --bgImage: linear-gradient(
                    #{$bgHalfRotate},
                    #{$colCustom3} #{$bgHalfSize},
                    transparent 0
                );
            }
            &-custom-4 {
                --bgImage: linear-gradient(
                    #{$bgHalfRotate},
                    #{$colCustom4} #{$bgHalfSize},
                    transparent 0
                );
            }
            &-dark {
                --bgImage: linear-gradient(
                    #{$bgHalfRotate},
                    #{$colDark} #{$bgHalfSize},
                    transparent 0
                );
            }
        }
    }
    &-custom-1 {
        --bgStrip: #{$colCustom1};
    }
    &-custom-2 {
        --bgStrip: #{$colCustom2};
    }
    &-custom-3 {
        --bgStrip: #{$colCustom3};
        // --colDots: var(--colBianco);
        // --colBordoBottoni: var(--colBianco);
        // --strip-text-color: var(--colBianco);
        // --colTitle: '';
        :where(a:not(.button):not(.read-more)) {
            color: inherit;
            &:hover {
                color: var(--colContrastoChiaro);
            }
        }
    }
    &-custom-4 {
        --bgStrip: #{$colCustom4};
        // --colDots: var(--colBianco);
        // --colBordoBottoni: var(--colBianco);
        // --strip-text-color: var(--colBianco);
        // --colTitle: '';
        // :where(a:not(.button):not(.read-more)) {
        //     color: inherit;
        //     &:hover {
        //         color: var(--colContrastoChiaro);
        //     }
        // }
    }
    &-np-dark {
        --bgStrip: #{$colDark};
        --colDots: var(--colBianco);
        --colBordoBottoni: var(--colBianco);
        --strip-text-color: var(--colBianco);
        --colTitle: '';
        :where(a:not(.button):not(.read-more)) {
            color: inherit;
            &:hover {
                color: var(--colContrastoChiaro);
            }
        }
    }
    &-grad {
        &-custom-1 {
            --bgImage: linear-gradient(#{$bgGradRotate}, #{$colCustom1} 0, transparent 100%);
        }
        &-custom-2 {
            --bgImage: linear-gradient(#{$bgGradRotate}, #{$colCustom2} 0, transparent 100%);
        }
        &-custom-3 {
            --bgImage: linear-gradient(#{$bgGradRotate}, #{$colCustom3} 0, transparent 100%);
            // --colDots: var(--colBianco);
            // --colBordoBottoni: var(--colBianco);
            // --strip-text-color: var(--colBianco);
            // --colTitle: '';
            :where(a:not(.button):not(.read-more)) {
                color: inherit;
                &:hover {
                    color: var(--colContrastoChiaro);
                }
            }
        }
        &-custom-4 {
            --bgImage: linear-gradient(#{$bgGradRotate}, #{$colCustom4} 0, transparent 100%);
            --colDots: var(--colBianco);
            --colBordoBottoni: var(--colBianco);
            --strip-text-color: var(--colBianco);
            // --colTitle: '';
            :where(a:not(.button):not(.read-more)) {
                color: inherit;
                &:hover {
                    color: var(--colContrastoChiaro);
                }
            }
        }
        &-dark {
            --bgImage: linear-gradient(#{$bgGradRotate}, #{$colDark} 0, transparent 100%);
            --colDots: var(--colBianco);
            --colBordoBottoni: var(--colBianco);
            --strip-text-color: var(--colBianco);
            --colTitle: '';
            :where(a:not(.button):not(.read-more)) {
                color: inherit;
                &:hover {
                    color: var(--colContrastoChiaro);
                }
            }
        }
    }
}
