@use 'sass:math';

// hack to import css ---
// @function max($numbers...) {
//     @return m#{a}x(#{$numbers});
// }

// @function min($numbers...) {
//     @return m#{i}n(#{$numbers});
// }
// -----------------------

$notA11y: true !default;

// Step responsive -----------
$screenLarge: 60em; // 960px
$screenMedium: 50em; // 800px
$screenSmall: 36em; // 576px
$screenXsmall: 430px;

// Dimensioni ----------------
$unit: 1rem !default;

$fontSize: 1;
$lineHeight: 1.5;
$contWidth: math.div(1200, 16) * $unit;
$contWidthNarrow: math.div(900, 16) * $unit;
$containerPad: var(--vpad);
$np_columns-gutter: var(--vpad);

// Caratteri ---------------
$fontTesto: 'Noto Sans', sans-serif;
$fontTitoli: "quiche-sans", sans-serif;
$fontNormal: 300;
$fontStrong: 400;
$titWheight: 500;
$titTransform: uppercase;
$titleSize: 3.75em;
// $titLetterSpacing: 0.02em;

// Colori ----------------
$colBg: #fcfcfc;
$colNero: #0c0c0c;
$colBianco: #fcfcfc;

// $colPrimario: #6D6E71;
$colPrimario: $colNero;
$colContrasto: #B27B00;
$colGrigio: #ccc;

$bgHalfSize: 25%; //calc(var(--vpad) * 3); // Dimensione mezzo sfondo
$bgHalfRotate: -90deg;
$bgGradRotate: 180deg;

// Immagini --------------
$logoCliente: '/img/caffe-paszkowski.svg';
$logoClienteWhite: '/img/caffe-paszkowski-white.svg';
