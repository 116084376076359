// ! Tabella (%table) ---
%table {
    max-width: 100%;
    width: 100%;
    border-top: 1px solid var(--colGrigio);

    :where(&) {
        tr:nth-child(2n) {
            background: var(--colGrigioLeggero);
        }
        th,
        td {
            @extend %fontTesto;
            font-size: 1em;
            padding: 0.5em 0.7em;
            vertical-align: middle;
            border-bottom: 1px solid var(--colGrigio);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &.table--data {
        border-top: none;
        tr:nth-child(2n) {
            background: none;
        }
        td,
        th {
            text-align: center;
            border-bottom: none;
        }
    }

    @media screen and (max-width: 43.74em) {
        // ! 700
        display: block;
        position: relative;
        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: 1em;
            background: linear-gradient(var(--dir, 90deg), rgba(0, 0, 0, 0.4) 0, transparent 100%);
        }
        &::before {
            left: 0;
        }
        &::after {
            --dir: -90deg;
            right: 0;
        }
        tbody {
            display: inline-block;
            vertical-align: top;
            max-width: 100%;

            overflow-x: auto;

            // optional - enable iOS momentum scrolling
            -webkit-overflow-scrolling: touch;
        }
        th,
        td {
            min-width: 10em;
        }
    }
}
