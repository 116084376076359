@import '../../../node_modules/@fancyapps/ui/dist/fancybox/fancybox';

.fancybox {
    &__container {
        --fancybox-opacity: 0.85;
        --fancybox-bg: var(--colNero);
    }
    &__nav {
        --f-button-width: auto;
    }
}

.fancy {
    &--md {
        width: $contWidth;
    }
    &--sm {
        width: $contWidthNarrow;
    }
    
    &__wrapper {
        display: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        max-height: 80vh;
        .buttons {
            display: flex;
            flex-wrap: wrap;
            gap: calc(var(--vpad) / 2);
            &.text-center {
                justify-content: center;
            }
            .button {
                @media screen and (max-width: 36em) {
                    width: 100%;
                    &.js-fancy-close {
                        order: 2;
                    }
                }
            }
        }
    }

    &__header {
        flex: 0 0 auto;
        margin-bottom: calc(var(--vpad) / 2);

        &__image {
            margin: calc(var(--vpad) / -2);
        }
    }

    &__scroll {
        flex: 1 1 auto;
        overflow: auto;
        padding: 1em;
    }
    
    &__footer {
        flex: 0 0 auto;
        margin-top: calc(var(--vpad) / 2);
        padding-top: calc(var(--vpad) / 2);
        border-top: 1px solid var(--colPrimarioScuro);
    }

    &--watermark {
        .fancybox__content {
            pointer-events: none;
            &::after {
                content: '';
                @include abs-cover(5);
                background: transparent url('/img/watermark.svg') no-repeat 50% 50%;
                background-size: 200px 30px;
                opacity: 0.3;
                pointer-events: none;
            }
        }
    }
}

.fancymsg {
    width: 38em;
    max-width: 100%;
    &__header {
        margin-bottom: calc(var(--vpad) / 3);
        text-align: center;
    }
    &__logo {
        display: inline-block;
        background: transparent url($logoCliente) no-repeat 50% 50%;
        background-size: contain;
        width: 100px;
        height: 60px;
    }
    .buttons {
        display: flex;
        flex-wrap: wrap;
        gap: calc(var(--vpad) / 2);
        .button {
            @media screen and (max-width: 36em) {
                width: 100%;
                &.js-fancy-close {
                    order: 2;
                }
            }
        }
    }
}
