%icon-fullscreen { content: '\e800'; } 
%icon-heart-full { content: '\e801'; } 
%icon-down-open { content: '\e802'; } 
%icon-mail { content: '\e803'; } 
%icon-left-open { content: '\e804'; } 
%icon-gallery { content: '\e805'; } 
%icon-right-open { content: '\e806'; } 
%icon-up-open { content: '\e807'; } 
%icon-clock { content: '\e808'; } 
%icon-play { content: '\e80a'; } 
%icon-pause { content: '\e80b'; } 
%icon-cancel { content: '\e810'; } 
%icon-plus { content: '\e811'; } 
%icon-trash { content: '\e813'; } 
%icon-check { content: '\e814'; } 
%icon-minus { content: '\e815'; } 
%icon-heart { content: '\e818'; } 
%icon-tripadvisor { content: '\e81c'; } 
%icon-slider-next { content: '\e892'; } 
%icon-slider-prev { content: '\e893'; } 
%icon-arrow-down { content: '\e8a1'; } 
%icon-arrow-left { content: '\e8a2'; } 
%icon-arrow-right { content: '\e8a3'; } 
%icon-arrow-up { content: '\e8a4'; } 
%icon-phone { content: '\e8b0'; } 
%icon-search { content: '\e8b1'; } 
%icon-user { content: '\e8c0'; } 
%icon-cart { content: '\e8c1'; } 
%icon-menu-close { content: '\e8f2'; } 
%icon-play-circled { content: '\e904'; } 
%icon-pdf { content: '\e92b'; } 
%icon-arrow-up-left { content: '\e93a'; } 
%icon-arrow-up-right { content: '\e93b'; } 
%icon-twitter { content: '\e945'; } 
%icon-cross { content: '\e953'; } 
%icon-drag { content: '\e964'; } 
%icon-location { content: '\e96b'; } 
%icon-share-point { content: '\e96e'; } 
%icon-sound-no { content: '\e96f'; } 
%icon-sound-off { content: '\e970'; } 
%icon-sound-on { content: '\e971'; } 
%icon-facebook { content: '\e980'; } 
%icon-linkedin { content: '\f0e1'; } 
%icon-sort-up { content: '\f160'; } 
%icon-sort-down { content: '\f161'; } 
%icon-youtube-play { content: '\f16a'; } 
%icon-instagram { content: '\f16d'; } 
%icon-file-pdf { content: '\f1c1'; } 
%icon-file-word { content: '\f1c2'; } 
%icon-file-excel { content: '\f1c3'; } 
%icon-file-powerpoint { content: '\f1c4'; } 
%icon-file-archive { content: '\f1c6'; } 
%icon-file-audio { content: '\f1c7'; } 
%icon-file-video { content: '\f1c8'; } 
%icon-sliders { content: '\f1de'; } 
%icon-pinterest { content: '\f231'; } 
%icon-whatsapp { content: '\f232'; } 
%icon-vimeo { content: '\f27d'; } 


.icon-fullscreen:before { @extend %icon-fullscreen; } 
.icon-heart-full:before { @extend %icon-heart-full; } 
.icon-down-open:before { @extend %icon-down-open; } 
.icon-mail:before { @extend %icon-mail; } 
.icon-left-open:before { @extend %icon-left-open; } 
.icon-gallery:before { @extend %icon-gallery; } 
.icon-right-open:before { @extend %icon-right-open; } 
.icon-up-open:before { @extend %icon-up-open; } 
.icon-clock:before { @extend %icon-clock; } 
.icon-play:before { @extend %icon-play; } 
.icon-pause:before { @extend %icon-pause; } 
.icon-cancel:before { @extend %icon-cancel; } 
.icon-plus:before { @extend %icon-plus; } 
.icon-trash:before { @extend %icon-trash; } 
.icon-check:before { @extend %icon-check; } 
.icon-minus:before { @extend %icon-minus; } 
.icon-heart:before { @extend %icon-heart; } 
.icon-tripadvisor:before { @extend %icon-tripadvisor; } 
.icon-slider-next:before { @extend %icon-slider-next; } 
.icon-slider-prev:before { @extend %icon-slider-prev; } 
.icon-arrow-down:before { @extend %icon-arrow-down; } 
.icon-arrow-left:before { @extend %icon-arrow-left; } 
.icon-arrow-right:before { @extend %icon-arrow-right; } 
.icon-arrow-up:before { @extend %icon-arrow-up; } 
.icon-phone:before { @extend %icon-phone; } 
.icon-search:before { @extend %icon-search; } 
.icon-user:before { @extend %icon-user; } 
.icon-cart:before { @extend %icon-cart; } 
.icon-menu-close:before { @extend %icon-menu-close; } 
.icon-play-circled:before { @extend %icon-play-circled; } 
.icon-pdf:before { @extend %icon-pdf; } 
.icon-arrow-up-left:before { @extend %icon-arrow-up-left; } 
.icon-arrow-up-right:before { @extend %icon-arrow-up-right; } 
.icon-twitter:before { @extend %icon-twitter; } 
.icon-cross:before { @extend %icon-cross; } 
.icon-drag:before { @extend %icon-drag; } 
.icon-location:before { @extend %icon-location; } 
.icon-share-point:before { @extend %icon-share-point; } 
.icon-sound-no:before { @extend %icon-sound-no; } 
.icon-sound-off:before { @extend %icon-sound-off; } 
.icon-sound-on:before { @extend %icon-sound-on; } 
.icon-facebook:before { @extend %icon-facebook; } 
.icon-linkedin:before { @extend %icon-linkedin; } 
.icon-sort-up:before { @extend %icon-sort-up; } 
.icon-sort-down:before { @extend %icon-sort-down; } 
.icon-youtube-play:before { @extend %icon-youtube-play; } 
.icon-instagram:before { @extend %icon-instagram; } 
.icon-file-pdf:before { @extend %icon-file-pdf; } 
.icon-file-word:before { @extend %icon-file-word; } 
.icon-file-excel:before { @extend %icon-file-excel; } 
.icon-file-powerpoint:before { @extend %icon-file-powerpoint; } 
.icon-file-archive:before { @extend %icon-file-archive; } 
.icon-file-audio:before { @extend %icon-file-audio; } 
.icon-file-video:before { @extend %icon-file-video; } 
.icon-sliders:before { @extend %icon-sliders; } 
.icon-pinterest:before { @extend %icon-pinterest; } 
.icon-whatsapp:before { @extend %icon-whatsapp; } 
.icon-vimeo:before { @extend %icon-vimeo; } 
