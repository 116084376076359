@use 'sass:math';

@mixin titles($this: '.strip') {
    &__title1,
    &__title2,
    &__title3,
    &__title4,
    &__title5 {
        @extend %fontTitoli;
        font-size: var(--fontSize, 1em);
        color: var(--colTitle, inherit);

        margin-top: calc(var(--vpad) / 2);
        margin-bottom: calc(var(--vpad) / 4);
        text-wrap-style: balance;
        
        #{$this}__pretitle + &,
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }

        .page__footer &,
        .banner & {
            word-wrap: unset;
            hyphens: none;
        }
    }
    &__title1,
    &__title2,
    &__title3 {
        @media screen and (max-width: 48em) {
            word-wrap: break-word;
            hyphens: auto;
        }
    }
    &__title1 {
        --fontSize: clamp(var(--titleSize) / 1.7, 6vw, var(--titleSize));
        i,
        em {
            font-style: inherit;
        }

        :is(.js-scroll-title, #f) & {
            position: relative;
            white-space: nowrap;
            min-width: 100%;
            font-weight: 500;
            .builder & {
                overflow: hidden;
            }
            i,
            em,
            strong {
                display: inline-block;
                // min-width: 100%;
                width: fit-content;
                font-style: inherit;
                font-weight: inherit;
            }
        }
    }
    &__title2 {
        --fontSize: clamp(var(--titleSize) / 1.6, 5.5vw, var(--titleSize) / 1.2);
    }
    &__title3 {
        --fontSize: clamp(var(--titleSize) / 2, 3.6vw, var(--titleSize) / 1.5);
    }
    &__title4 {
        --fontSize: clamp(var(--titleSize) / 2.2, 2.4vw, var(--titleSize) / 1.8);
    }
    &__title5 {
        --fontSize: clamp(var(--titleSize) / 2.5, 2vw, var(--titleSize) / 2);
    }
    &__pretitle {
        text-transform: uppercase;
        font-size: clamp(var(--titleSize) / 2.5, 2vw, var(--titleSize) / 2);
    }
    &__subtitle {
        font-size: clamp(var(--titleSize) / 2.5, 2vw, var(--titleSize) / 2);
        font-weight: $fontStrong;
        line-height: 1.2;
        // margin: calc(var(--vpad) / -4) 0 calc(var(--vpad) / 2);
        margin-bottom: 0.5em;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__title {
        &--splitted {
            line-height: 1rem;
        }
    }
}
