@use 'sass:math';

$fontScript: $fontTitoli !default;

// :focus-visible {
//     @extend %focus-visible;
// }
@if not($notA11y) {
    :focus-visible,
    :focus {
        @extend %focus-visible;
    }
}

[href$='.pdf' i]:not(.read-more):not(.strip__blurb__link) {
    @extend [class^='icon-'];
    @extend .icon-file-pdf;
}
[href$='.doc' i]:not(.read-more):not(.strip__blurb__link),
[href$='.docx' i]:not(.read-more):not(.strip__blurb__link) {
    @extend [class^='icon-'];
    @extend .icon-file-word;
}
[href$='.xls' i]:not(.read-more):not(.strip__blurb__link),
[href$='.xlsx' i]:not(.read-more):not(.strip__blurb__link) {
    @extend [class^='icon-'];
    @extend .icon-file-excel;
}
[href$='.ppt' i]:not(.read-more):not(.strip__blurb__link),
[href$='.pptx' i]:not(.read-more):not(.strip__blurb__link) {
    @extend [class^='icon-'];
    @extend .icon-file-powerpoint;
}
[href$='.zip' i]:not(.read-more):not(.strip__blurb__link),
[href$='.rar' i]:not(.read-more):not(.strip__blurb__link),
[href$='.gzip' i]:not(.read-more):not(.strip__blurb__link) {
    @extend [class^='icon-'];
    @extend .icon-file-archive;
}
[href$='.mp3' i]:not(.read-more):not(.strip__blurb__link),
[href$='.aiff' i]:not(.read-more):not(.strip__blurb__link),
[href$='.aac' i]:not(.read-more):not(.strip__blurb__link),
[href$='.flac' i]:not(.read-more):not(.strip__blurb__link),
[href$='.m4a' i]:not(.read-more):not(.strip__blurb__link),
[href$='.wav' i]:not(.read-more):not(.strip__blurb__link) {
    @extend [class^='icon-'];
    @extend .icon-file-audio;
}
[href$='.mp4' i]:not(.read-more):not(.strip__blurb__link),
[href$='.mpg' i]:not(.read-more):not(.strip__blurb__link),
[href$='.mov' i]:not(.read-more):not(.strip__blurb__link),
[href$='.mkv' i]:not(.read-more):not(.strip__blurb__link),
[href$='.flv' i]:not(.read-more):not(.strip__blurb__link),
[href$='.avi' i]:not(.read-more):not(.strip__blurb__link),
[href$='.wmv' i]:not(.read-more):not(.strip__blurb__link) {
    @extend [class^='icon-'];
    @extend .icon-file-video;
}

.border-link {
    @extend %borderLink;
}

.read-more {
    display: var(--display, inline-grid);
    align-items: center;
    justify-items: center;
    color: var(--colRead-more, var(--strip-text-color, var(--colNero)));
    text-transform: uppercase;
    font-weight: $fontStrong;
    font-size: 0.95em;

    cursor: pointer;

    .is-mouse & {
        grid-template-columns: 1fr auto;
        grid-template-areas: 'text icon';
        gap: 0.5em;
        &::before {
            $w: 2em;
            @extend %animate2;
            content: '';
            grid-area: icon;
            width: $w;
            height: $w;
            background: currentColor;
            opacity: 0;
            border-radius: 50%;
        }
        &::after {
            @extend %animate2;
            @extend %fontIcons;
            @extend %icon-arrow-right;
            color: var(--colBianco);
            grid-area: icon;
            display: flex;
            align-items: center;
            margin: 0;
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
            mix-blend-mode: difference;
        }
    }
    a:hover &,
    &:hover {
        color: var(--colRead-more, var(--strip-text-color, var(--colNero)));
        .is-mouse & {
            &::before {
                opacity: 1;
            }
            &::after {
                opacity: 1;
                transform: none;
            }
        }
    }
}

.panel-scroll {
    border: none;
    display: block;
    // overflow: hidden;
    tr,
    tr:nth-child(2n) {
        display: block;
        background: var(--bgStrip, var(--colBg));
        border-top: 1px solid;
        padding: var(--vpad) 0;
    }
    th,
    td {
        display: block;
        padding: 0;
        overflow: visible;
        border: none;
    }
}

.stroke {
    @extend %ir;
    display: inline-block;
    // margin: 0 0.4em 0 0.2em;
    vertical-align: middle;
    height: 1px;
    width: var(--strokeWidth, calc(var(--vpad) / 4));
    border-top: 1px solid;
}

.text {
    &-left {
        --image-marginLeft: 0;
        --strip-textNarrow-marginLeft: 0;
        --strip-textNarrow-marginRight: auto;
    }
    &-center {
        --image-marginLeft: auto;
        --strip-textNarrow-marginLeft: auto;
        --strip-textNarrow-marginRight: auto;
    }
    &-right {
        --image-marginLeft: auto;
        --image-marginRight: 0;
        --strip-textNarrow-marginLeft: auto;
        --strip-textNarrow-marginRight: 0;
    }
    &-justify {
        --image-marginLeft: 0;
    }
    &-small {
        font-size: 0.85em;
    }
    &-big {
        font-size: 1.2em;
    }
    &-cite {
        font-family: $fontTitoli;
        font-size: 1.4em;
        font-style: italic;
        color: var(--colTitle, var(--strip-text-color, var(--colPrimario)));
        margin-top: calc(var(--vpad) / 3);
        margin-bottom: calc(var(--vpad) / 3);

        @media screen and (min-width: 36em) {
            font-size: 2em;
        }

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &-decorated {
        @extend %fontTitoli;
        font-size: clamp(var(--titleSize) / 1.6, 5.5vw, var(--titleSize) / 1.2);
        line-height: 1.1;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        + [class*='strip__title'] {
            margin-top: 0;
        }
    }
}

@media screen and (min-width: $screenMedium) {
    .md\:text {
        &-left {
            --image-marginLeft: 0;
            --strip-textNarrow-marginLeft: 0;
            --strip-textNarrow-marginRight: auto;
        }
        &-center {
            --image-marginLeft: auto;
            --strip-textNarrow-marginLeft: auto;
            --strip-textNarrow-marginRight: auto;
        }
        &-right {
            --image-marginLeft: auto;
            --image-marginRight: 0;
            --strip-textNarrow-marginLeft: auto;
            --strip-textNarrow-marginRight: 0;
        }
        &-justify {
            --image-marginLeft: 0;
        }
    }
}
@media screen and (min-width: $screenLarge) {
    .lg\:text {
        &-left {
            --image-marginLeft: 0;
            --strip-textNarrow-marginLeft: 0;
            --strip-textNarrow-marginRight: auto;
        }
        &-center {
            --image-marginLeft: auto;
            --strip-textNarrow-marginLeft: auto;
            --strip-textNarrow-marginRight: auto;
        }
        &-right {
            --image-marginLeft: auto;
            --image-marginRight: 0;
            --strip-textNarrow-marginLeft: auto;
            --strip-textNarrow-marginRight: 0;
        }
        &-justify {
            --image-marginLeft: 0;
        }
    }
}

.two-columns {
    &:not(.strip__data),
    & .strip__text {
        columns: math.div($contWidthNarrow, 3) 2;
        column-gap: var(--hpad, var(--vpad));
        [class*='__title'] {
            column-span: all;
        }
        li {
            break-inside: avoid;
        }
    }
}
.three-columns {
    &:not(.strip__data),
    & .strip__text {
        columns: math.div($contWidthNarrow, 4) 3;
        column-gap: var(--hpad, var(--vpad));
        [class*='__title'] {
            column-span: all;
        }
        li {
            break-inside: avoid;
        }
    }
}

.image-grid {
    $ig: &;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: var(--vpad);
    grid-auto-flow: dense;

    &__item {
        display: block;
        position: relative;
        .is-mouse &:hover {
            #{$ig} {
                &__text {
                    opacity: 1;
                }
            }
        }
        &.wide {
            grid-column-end: span 2;
        }
        &.tall {
            grid-row-end: span 2;
        }
        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }
    }
    &__text {
        @extend %animate2;
        @extend %fontTitoli;
        @include abs-cover(1);
        color: var(--colTitle, inherit);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        font-size: max(calc(var(--titleSize) / 2), 2em);
        text-align: center;
        opacity: 0;
        pointer-events: none;
        &::before {
            content: '';
            @include abs-cover(-1);
            background: var(--igBg, #fff);
            opacity: 0.7;
        }
    }
}

.socials {
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        li {
            margin: 0.75em 0 0.75em 1.5em;
        }
        a {
            @extend %borderLink;
        }
    }
}

.link {
    &-list {
        line-height: initial;
        list-style: none;
        padding: 0;
        li {
            position: relative;
            padding-left: 1.5em;
            margin-bottom: calc(var(--vpad) / 2);
            &::before {
                @extend %fontIcons;
                @extend %icon-arrow-right;
                position: absolute;
                left: 0;
                top: 0.1em;
                color: var(--colContrasto);
            }
        }
        a {
            display: block;
            color: inherit;
            &:hover {
                color: var(--colContrasto);
            }
        }
    }
}

@import './modules/bgs';
@import './modules/bgs_site';
