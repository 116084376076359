@use 'sass:math';

@import 'modules/button';
@import './modules/strip_site';

::selection {
    background-color: var(--colPrimario);
    color: var(--colBianco);
}

.button {
    @include button();
}

.socials {
    list-style: none;
    margin: 0;
    padding: 0;
}

// ! PAGE-HEADER ===========================================================
.menu {
    &__screen {
        @extend %animate2;
        display: block;
        background: var(--colNero);
        opacity: 0.3;
        z-index: 4;
        .m-open & {
            visibility: visible;
            transform: none;
            pointer-events: all;
        }
    }
    &__container {
        @extend %animate2;
        display: flex;
        flex-direction: column;
        position: fixed;
        background: var(--colNero);
        padding-top: calc(var(--header-height) / 16 * 1em * var(--fontSize)) var(--vpad)
            calc(var(--vpad) / 2);
        z-index: 5;
        line-height: 1.2;
        color: var(--colBianco);

        .m-open & {
            visibility: visible;
            opacity: 1;
            transform: none;
            pointer-events: all;
        }
        a {
            color: inherit;
        }
        li {
            @extend %animate;
            @media screen and not (prefers-reduced-motion: reduce) {
                transform: translate3d(0, -100%, 0);
                opacity: 0;

                @for $i from 1 to 10 {
                    &:nth-child(#{$i}) {
                        transition-delay: ($i * 0.05s + 0.3s);
                    }
                }

                .m-open &,
                .no-js & {
                    transform: none;
                    opacity: 1;
                    &.off {
                        opacity: 0.5;
                    }
                }
            }
        }
    }
    &__row {
        max-width: $contWidth;
        margin-top: auto;
        display: grid;
        max-height: 100%;
        overflow: auto;
        width: 100%;
        gap: calc(var(--vpad) / 2) var(--vpad);
        padding-left: calc(var(--vpad) / 2);
        padding-right: calc(var(--vpad) / 2);
        @media screen and (min-width: $screenSmall) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__main {
        @extend %fontTitoli;
        width: 100%;
        padding: 1em var(--vpad);
        max-height: 100%;
        overflow: auto;
        ul {
            display: grid;
            gap: 1em;
        }
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            @media screen and (min-width: $screenMedium) {
                text-align: unset;
                justify-content: flex-start;
            }
        }
        a {
            font-size: clamp(var(--titleSize) / 1.7, 4vw, var(--titleSize));
        }
    }

    &__main,
    &__side {
        a {
            position: relative;
            display: inline-block;
            overflow: hidden;
            div {
                @extend %animate;
                display: block;
                + div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    opacity: 0;
                    transform: translate3d(0, -100%, 0);
                }
            }
            .is-mouse &:hover {
                div {
                    opacity: 0;
                    transform: translate3d(0, 100%, 0);
                    + div {
                        opacity: 1;
                        transform: none;
                    }
                }
            }
        }
    }

    &__mid {
        padding: calc(var(--vpad) / 2) var(--vpad) 0;
        margin-bottom: auto;
        display: grid;
        gap: 1em;
        @media screen and (min-width: $screenSmall) {
            display: flex;
            justify-content: space-between;
        }
    }
    &__side {
        text-transform: uppercase;
        font-size: 1.1em;
        text-align: center;
        @media screen and (min-width: $screenSmall) {
            text-align: unset;
        }
        ul {
            display: grid;
            gap: 0.5em;
        }
    }

    &__footer {
        padding: 1em var(--vpad);
        display: flex;
        gap: 1em;
        align-items: center;
        justify-content: space-between;
    }

    &__lang {
        ul {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            gap: 1em;
        }
        a {
            color: inherit;
            &.active {
                font-weight: $fontStrong;
            }
        }
    }

    &__socials {
        .socials {
            gap: 1em;
            display: flex;
        }
        a {
            &::before {
                margin: 0;
                font-size: 1.5em;
            }
            &:hover {
                color: inherit;
                opacity: 0.7;
            }
        }
    }
}

// ! PAGE-CONTENT ===========================================================
.page {
    &__content {
        overflow: hidden;
        .page--nobanner & {
            padding-top: calc(var(--header-height) / 16 * 1em / var(--fontSize));
        }
    }
}

.word-wrapper {
    display: inline-flex;
    overflow: hidden;
    margin-top: -0.15em;
    .word {
        padding-top: 0.15em;
    }
}

// ! Banner -------
.banner {
    background: var(--colNero);
    &__slider,
    &__slide {
        // height: 100vh;
        &,
        .home & {
            height: 100vh;
        }
    }
    &__slide {
        // &::after {
        //     content: '';
        //     @include abs-cover(1);
        //     background: linear-gradient(to bottom, var(--colNero) -10%, transparent 20%);
        // }
        .js-clip-n-cover & {
            picture {
                clip-path: inset(
                    calc(var(--header-height) * 1px + var(--vpad) * 2 + var(--titleSize) * 1.3) 0px
                        0px 0px
                );
            }
            img {
                transform: scale3d(1.2, 1.2, 1);
            }
        }
    }
    &__video {
        filter: Grayscale();
    }
    &__watermark {
        @include abs-cover(5);
        background: transparent url('/img/watermark.svg') no-repeat 50% 50%;
        background-size: 200px 30px;
        opacity: 0.3;
        pointer-events: none;
    }
    &__text {
        max-width: 100%;
        top: calc(var(--header-height) * 1px + var(--vpad));
        text-align: inherit;
        transform: none;
        .home & {
            --titleSize: 5em;
            top: auto;
            transform: none;
            text-shadow: 0.1em 0.1em 0px rgba(0, 0, 0, 1);
            bottom: calc(var(--vpad) * 1.5);
            @media screen and (min-width: $screenSmall) {
                bottom: calc(var(--vpad) * 2);
            }
            @media screen and (min-width: 50em) {
                bottom: 25%;
            }
        }
        // &__container {
        //     --aniProp: transform, opacity;
        //     > * {
        //         @extend %animate2;
        //         transform: translate3d(0, 70%, 0);
        //         .home & {
        //             opacity: 0;
        //             transform: translate3d(70%, 0, 0);
        //         }
        //         @for $i from 1 to 7 {
        //             &:nth-child(#{$i}) {
        //                 transition-delay: ($i * 0.05s);
        //             }
        //         }
        //         .no-js &,
        //         .slick-active & {
        //             opacity: 1;
        //             transform: none;
        //         }
        //     }
        // }
    }
    &__title {
        @extend %fontTitoli;
        font-size: clamp(var(--titleSize) / 1.7, 6vw, var(--titleSize));
    }
    &__credits {
        position: absolute;
        bottom: calc(var(--vpad) / 3);
        right: var(--vpad);
        color: var(--colBianco);
        z-index: 5;
        font-size: 0.7em;
    }

    &__form {
        display: block;
        position: absolute;
        bottom: 5em;
        left: 0;
        right: 0;
        z-index: 20;
    }
    &__slogan {
        @extend %fontTitoli;
        font-size: clamp(var(--titleSize) / 1.2, 3vw, var(--titleSize));
        color: var(--colBianco);
        margin-bottom: calc(var(--vpad) / 2);
        em {
            font-style: normal;
            display: block;
        }
    }
    &__search {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        background: var(--colBianco);
        padding: 0.5em;
        border-radius: 0.3em;
        gap: calc(var(--vpad) / 2);
        p {
            width: 25%;
            &:last-child {
                align-self: stretch;
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
                padding-right: 0.5em;
            }
        }
        label {
            font-size: 0.85em;
            text-align: center;
            margin-bottom: 0.2em;
        }
        select {
            border: none;
            text-align: center;
            font-weight: $fontStrong;
            height: auto;
        }
    }
    &__link {
        text-transform: uppercase;
        color: var(--colBianco);
        position: relative;
        z-index: 1;
        display: inline-block;
        padding: 0.5em;
        overflow: hidden;
        font-weight: $fontStrong;
        &::after {
            @extend %animate;
            content: '';
            @include abs-cover(-1);
            background: var(--colBianco);
            transform: translateY(calc(100% - 2px));
        }
        &:hover {
            color: var(--colText);
            &::after {
                transform: none;
            }
        }
    }
}

.breadcrumbs {
    padding: 0.3em 0;
    text-align: center;
    &.js-fixed {
        position: fixed;
        z-index: 89;
        left: 0;
        right: 0;
        top: calc(var(--header-height) * 1px + var(--menuPageHeight, 0px));
        background: var(--colBianco);
        margin-top: 0;
        transform: none;
    }
    &__container {
        .js-fixed & {
            @extend %container;
        }
    }
    a {
        color: inherit;
    }
    span {
        font-size: 0.85em;
        &:not(:first-child) {
            &::before {
                content: '/';
                font-weight: $fontNormal;
                display: inline-block;
                margin: 0 0.4em 0 0.2em;
                vertical-align: middle;
            }
        }
        &:last-child {
            font-weight: $fontStrong;
        }
    }
}

.accordion {
    &__switch {
        &::after {
            $w: 1.5em;
            @extend %animate;
            @extend %fontIcons;
            @extend %icon-arrow-down;
            flex: 0 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 calc(var(--vpad) / 4);
            font-size: 1rem;
            width: $w;
            height: $w;
            color: var(--colContrasto);
        }
        .no-js &,
        &.active {
            &::after {
                transform: rotate3d(0, 0, 1, 180deg);
            }
        }
    }
}

// .gm-style {
//     .gm-style-iw {
//         border-radius: 0;
//     }
// }

.map {
    &__content {
        @extend %fontTesto;
        font-size: 1rem;
        text-align: inherit;
        padding-top: 0.5em;
    }
    &__logo {
        height: 24px !important;
        width: auto !important;
        margin: 0 auto 1.5em !important;
    }
    &__image {
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto 0.7em;
    }
    &__title {
        @extend %fontTitoli;
        font-size: 1.4em;
        margin-bottom: 0.5em;
    }
    &__text {
        font-size: 0.95em;
    }
    &__marker {
        &__type {
            text-transform: uppercase;
            font-size: 0.75em;
            color: var(--colGrigioMedio);
        }
        &__title {
            text-transform: uppercase;
            font-weight: $fontStrong;
        }
    }
}

.slider {
    &__buttons {
        display: grid;
        grid-template-columns: repeat(2, minmax(1em, 1fr));
        gap: 1em;
        .button {
            margin: 0;
        }
    }
}

.scroll-title-fixed {
    position: fixed;
    z-index: 20;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.js-scroll-container {
    overflow: hidden;
    display: flex;
    > * {
        flex: 0 0 auto;
    }
    .strip__data,
    .strip__text {
        min-width: 100%;
        display: flex;
    }
    [class*='strip__title'] {
        white-space: nowrap;
        text-transform: uppercase;
        color: var(--colPrimarioLeggero);
        margin: 0;
        padding-left: 0.15em;
    }
    &.activated {
        .js-scroll-content,
        [class*='strip__title'] {
            animation: strip-marquee 10s linear infinite;
        }
    }
    @keyframes strip-marquee {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(-100%, 0, 0);
        }
    }
}

.awards {
    &-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        gap: calc(var(--vpad) / 2);
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        img {
            max-width: 10em;
            max-height: 4em;
            object-fit: contain;
        }
    }
}

.side-popup {
    $pop: &;
    @extend %animate2;
    position: fixed;
    z-index: 101;
    top: 0;
    right: 0;
    height: 100%;
    width: 70em;
    max-width: 95%;
    background: var(--colBg);
    transform: translate3d(100%, 0, 0);
    &-open {
        overflow: hidden;
        #{$pop} {
            transform: none;
            &__screen {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__screen {
        @extend %animate2;
        position: fixed;
        z-index: 99;
        @include abs-sides(0);
        background: transparent;
        backdrop-filter: blur(4px);
        opacity: 0;
        visibility: hidden;
        .side-open & {
            opacity: 1;
            visibility: visible;
        }
    }
    &__close {
        position: absolute;
        z-index: 10;
        top: calc(var(--vpad) / 2);
        right: var(--vpad);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3em;
        height: 3em;
        background: var(--colNero);
        color: var(--colBianco);
        border-radius: 50%;
        &::before {
            @extend %fontIcons;
            @extend %icon-menu-close;
            margin: 0;
        }
    }
    &__conts {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .ajax-page {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .strip:not(.strip--image) {
        display: flex;
        height: 100%;
        overflow: hidden;
        .container {
            display: flex;
        }
        .strip__text {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}

.card {
    &__banner {
        img {
            max-height: 30vh;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &__contents {
        padding: var(--vpad) 0;
        flex: 1 1 auto;
        height: 1em;
        display: flex;
    }
    &__scroll {
        height: 100%;
        overflow: auto;
        flex: 1 1 auto;
    }
    &__section {
        padding: 0 var(--vpad);
    }
    &__title {
        @extend %animate;
        @extend %fontTitoli;
        font-size: clamp(1.2em, 3vw, 2em);
        color: var(--colNero);
        position: sticky;
        top: 0;
        background: var(--bgStrip, var(--colBg));
        padding: 1rem 0;
        border-bottom: 1px solid;
        &.active {
            border-bottom-color: transparent;
        }
        &:hover {
            color: var(--colPrimario);
        }
        &::after {
            @extend %animate;
            color: inherit;
            font-size: 0.9em;
        }
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 1em 0 0;
        border-bottom: 2px solid var(--colNero);
    }
    &__item {
        display: grid;
        grid-template-columns: minmax(1em, 1fr) 6em;
        gap: 0 1em;
        margin-bottom: 1em;
    }
    &__plate {
        grid-column: span 2;
        font-weight: $fontStrong;
        text-transform: uppercase;
        font-size: 0.95em;
        line-height: 1.3;
        color: var(--colNero);
    }
    &__price {
        text-align: right;
    }
}

.scroll {
    &__container {
        overflow: hidden;
        position: relative;
    }
    &__item {
        overflow: auto;
        padding-bottom: 40px;
        margin-bottom: -30px;
        user-select: none;
        .is-touch & {
            scroll-snap-type: x mandatory;
        }
    }
    &__content {
        display: inline-flex;
        .strip {
            scroll-snap-align: start;
            width: 85vw;
            @media screen and (min-width: $screenSmall) {
                width: 90vw;
            }
            @media screen and (min-width: $screenMedium) {
                flex: 0 0 auto;
                height: 100vh;
                .container {
                    max-width: none;
                }
            }
            &--3-cols {
                @media screen and (min-width: $screenMedium) {
                    --span: 4;
                    --strip-col-bgPad: -2;
                    .container {
                        --strip-column-gap: var(--hpad, var(--vpad));
                        grid-template-columns: repeat(12, minmax(1em, 1fr));
                    }
                }
            }
            &__column {
                &.full-height {
                    min-height: 0;
                    @media screen and (min-width: $screenMedium) {
                        min-height: 100vh;
                    }
                }
            }
            &__column--image {
                .strip {
                    @media screen and (min-width: $screenMedium) {
                        &__image img {
                            width: calc(100% + var(--container-pad, var(--vpad)));
                        }
                    }
                }
                &.strip__column {
                    &--1 {
                        .strip {
                            @media screen and (min-width: $screenMedium) {
                                &__image img {
                                    transform: translateX(calc(var(--container-pad) * -1));
                                }
                            }
                        }
                    }
                }
            }
            &__image {
                img {
                    max-height: 40vh;
                    object-fit: contain;
                    @media screen and (min-width: $screenMedium) {
                        max-height: none;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    &__handle {
        $w: 4.5em;
        --aniProp: opacity;
        @extend %animate;
        position: absolute;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        width: $w;
        height: $w;
        color: var(--colPrimario);
        background: var(--colBianco);
        border-radius: 50%;
        transform: translate3d(-50%, -50%, 0);
        pointer-events: none;
        opacity: 0.8;
        &.visible {
            opacity: 1;
        }
        &::before {
            @extend %fontIcons;
            @extend %icon-drag;
            font-size: 2.6em;
            margin: 0 !important;
        }
    }
}

.scheduled {
    &__item {
        margin-right: calc(var(--vpad) * 1.5);
        display: flex;
        flex-direction: column;
    }
    &__name {
        font-weight: $fontStrong;
        color: var(--colNero);
    }
    &__category {
        @extend %fontTitoli;
        font-size: clamp(1.5em, 2vw, 2.1em);
        color: var(--colNero);
        margin-bottom: calc(var(--vpad) / 3);
    }
    &__time {
        display: flex;
        gap: 1em;
        justify-content: space-between;
        border-bottom: 1px solid var(--strip-text-color, var(--colNero));
        margin-bottom: 0.5em;
        margin-top: auto;
        padding-bottom: 0.5em;
    }
    &__hour {
        white-space: nowrap;
        &::before {
            @extend %fontIcons;
            @extend %icon-clock;
            margin: 0 0.4em 0;
            color: var(--strip-text-color, var(--colNero));
        }
    }
}

// SHOP -----------------
.box-grid {
    $grid: &;
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: var(--vpad) 1.5em;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 20em), 1fr));
    grid-auto-flow: dense;

    &__item {
        &--large {
            grid-column: span 2;
            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &__link {
        color: inherit;
        display: grid;
        gap: 1em;
        &:hover {
            color: inherit;
            #{$grid} {
                &__image {
                    img {
                        transform: scale3d(1.05, 1.05, 1);
                    }
                }
                &__title {
                    color: var(--colPrimarioChiaro);
                }
            }
        }
    }

    &__image {
        overflow: hidden;
        img {
            @extend %animate2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text {
        display: grid;
        gap: 0.5em;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 0.5em;
        font-weight: $fontStrong;
    }

    &__title {
        @extend %animate2;
    }

    &__buttons {
        margin-top: var(--vpad);
        text-align: center;
    }
}

// ! PAGE-FOOTER ===========================================================
.page__footer {
    overflow: hidden;
    a {
        color: inherit;
        display: inline-block;
        &:hover {
            color: inherit;
            opacity: 0.6;
        }
    }
}
.footer {
    &__top {
        padding: calc(var(--vpad) * 1.5) 0;
        display: grid;
        gap: calc(var(--vpad) / 2);
        text-align: center;
        @media screen and (min-width: $screenMedium) {
            text-align: unset;
            grid-template-columns: repeat(4, auto);
        }
    }
    &__logo {
        max-width: 5em;
        margin: 0 auto calc(var(--vpad) / 2);
        @media screen and (min-width: $screenMedium) {
            margin-left: 0;
            margin-right: 0;
        }
    }
    &__menu {
        ul {
            display: grid;
            gap: 0.25em;
        }
    }
    &__shortcuts {
        ul {
            display: grid;
            gap: 0.25em;
            grid-template-columns: repeat(2, minmax(1em, 1fr));
            @media screen and (min-width: $screenMedium) {
                grid-template-columns: none;
            }
            + ul {
                margin-top: 0.25em;
            }
        }
        a {
            div + div {
                display: none;
            }
        }
    }
    &__title {
        font-size: 0.9em;
        text-transform: uppercase;
        font-weight: $fontStrong;
        margin-bottom: 0.5em;
    }
    &__socials {
        .socials {
            gap: 0.5em;
            display: flex;
            justify-content: center;
            @media screen and (min-width: $screenSmall) {
                justify-content: flex-start;
            }
        }
        a::before {
            font-size: 1.5em;
            margin: 0;
        }
    }

    &__mid {
        padding-bottom: calc(var(--vpad) / 4);
        display: flex;
        gap: 1em;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.75em;
        flex-direction: column;
        @media screen and (min-width: $screenSmall) {
            flex-direction: row;
        }
    }
    &__bottom {
        background: var(--colNero);
        padding: calc(var(--vpad) / 2) 0;
        display: grid;
        gap: calc(var(--vpad) / 2);
        justify-content: center;
        position: relative;
        @media screen and (min-width: $screenSmall) {
            display: flex;
            align-items: center;
        }
    }
    &__credits {
        text-align: right;
        @media screen and (min-width: $screenSmall) {
            flex: 0 0 auto;
            text-align: unset;
        }
    }
}

.scroll-top {
    a {
        $w: 4.5em;
        width: $w;
        height: $w;
        color: var(--colBianco);
        background: var(--colNero);
        border: 1px solid;
        border-radius: 50%;
        opacity: 1;
        &:hover {
            opacity: 0.8;
            // background: var(--colContrasto);
        }
        &::before {
            font-size: 1em;
        }
    }
}
