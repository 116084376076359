@charset "UTF-8";
/* CSS Document */
.scheduled__hour::before, .scroll__handle::before, .side-popup__close::before, .accordion__switch::after, .gm-style .gm-style-iw button::before, .toggle__switch::after, .cursor-down .np-cursor__text::before, .cursor-up .np-cursor__text::before, .slick-cursor-right .np-cursor__text::before, .cursor-right .np-cursor__text::before, .slick-cursor-left .np-cursor__text::before, .cursor-left .np-cursor__text::before, .link-list li::before, .is-mouse .read-more::after, .video__thumb::before, .strip__gallery--video::after, .strip__video__container::before, .splide__handle::before, [class^=icon-]:before, .image__link:before, [href$=".mp4" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".mpg" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".mov" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".mkv" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".flv" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".avi" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".wmv" i]:not(.read-more):not(.strip__blurb__link):before, [href$=".mp3" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".aiff" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".aac" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".flac" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".m4a" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".wav" i]:not(.read-more):not(.strip__blurb__link):before, [href$=".zip" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".rar" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".gzip" i]:not(.read-more):not(.strip__blurb__link):before, [href$=".ppt" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".pptx" i]:not(.read-more):not(.strip__blurb__link):before, [href$=".xls" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".xlsx" i]:not(.read-more):not(.strip__blurb__link):before, [href$=".doc" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".docx" i]:not(.read-more):not(.strip__blurb__link):before, [href$=".pdf" i]:not(.read-more):not(.strip__blurb__link):before, .video__fullscreen:before, .video__sound:before, .video__play:before, .dropdown li.active:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.4em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.banner__text {
  color: var(--colBianco);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .scroll__handle, .card__title::after, .card__title, .accordion__switch::after, .banner__link::after, .menu__main a div, .menu__side a div, .menu__container li, .share-menu, .video__prog__ind, .video__play-cursor, input[type=checkbox] + label::before, input[type=checkbox] + label::after,
  input[type=radio] + label::before,
  input[type=radio] + label::after, .label,
  label, .splide__handle {
    transition: var(--aniTime, 0.3s) cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition-property: var(--aniProp, all);
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  .box-grid__title, .box-grid__image img, .side-popup__screen, .side-popup, .menu__container, .menu__screen, .button::after, .strip__blurb__link .strip__blurb__item, .strip__blurb__link .strip__blurb__link, .strip__gallery__link img, .strip--stamp::after, .strip--decoration-h::after, .strip--decoration-v::before, .scroll-top, .image-grid__text, .is-mouse .read-more::after, .is-mouse .read-more::before, .side__screen, .side__container, .strip__blurb__link .strip__blurb__image img, .strip__blurb__link .strip__blurb__item[class*=bg-custom]::before, .strip__blurb__link [class*=bg-custom].strip__blurb__link::before, .strip__blurb__link .strip__blurb__item[class*=bg-np]::before, .strip__blurb__link [class*=bg-np].strip__blurb__link::before, .animate .strip__gallery > *, .strip__slider__figure, .strip__video__container img, .strip__video__container::after, .socials__list a::after, .border-link::after {
    transition: var(--aniTime, 0.6s) cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-property: var(--aniProp, all);
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  .button {
    transition: all var(--aniTime, 0.6s) cubic-bezier(0.645, 0.045, 0.355, 1), var(--aniExclude, font-style) 1ms;
  }
}

.stroke {
  text-indent: 105%;
  white-space: nowrap;
  overflow: hidden;
  font-family: sans-serif !important;
}

.banner__slide {
  position: relative;
  overflow: hidden;
}
.banner__slide video,
.banner__slide picture,
.banner__slide img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: var(--pinX, 50%) var(--pinY, 50%);
}

.js-fixed .breadcrumbs__container, .banner__text {
  display: var(--container-display, block);
  width: 100%;
  max-width: calc(var(--container-maxWidth) + var(--container-pad) * 2 + var(--container-width-adjust, 0px));
  padding-left: var(--container-pad);
  padding-right: var(--container-pad);
  margin: 0 auto;
}

.map__content, .map-content, .cart__item__name, :where(.strip__text table) th,
:where(.strip__text table) td {
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
}

.scheduled__category, .card__title, .map__title, .banner__slogan, .banner__title, .menu__main, .image-grid__text, .text-decorated, .side-cart__item__subname, .side-cart__item__name, .cart__message__title, .cart__title, .strip__counter__values, .strip__blurb__title, .strip__counter__title, .strip__title1, .strip__title2, .strip__title3, .strip__title4, .strip__title5, legend {
  font-family: "quiche-sans", sans-serif;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: unset;
}

input[type=checkbox]:focus-visible + label:not(.mce-content-body),
input[type=radio]:focus-visible + label:not(.mce-content-body), body:not(.mce-content-body) input[type=checkbox]:focus-visible + label,
body:not(.mce-content-body) input[type=radio]:focus-visible + label {
  outline: 3px solid var(--colContrasto);
  box-shadow: 0 0 8px rgba(var(--colPrimario), 0.8);
  outline-offset: 2px;
}

.socials__list a, .border-link {
  --aniProp: transform;
  display: inline-flex;
  position: relative;
  color: inherit;
  font-weight: 400;
}
.socials__list a::after, .border-link::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  border-bottom: 2px solid var(--colContrastoScuro);
  transform: scale3d(0, 1, 1);
  transform-origin: 0 50%;
}
a:hover .socials__list a, .socials__list a:hover a, a:hover .border-link, .socials__list a:hover, .border-link:hover {
  color: inherit;
}
a:hover .socials__list a::after, .socials__list a:hover a::after, a:hover .border-link::after, .socials__list a:hover::after, .border-link:hover::after {
  transform: none;
}

.strip__video__container {
  overflow: hidden;
  position: relative;
}
.strip__video__container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #000;
  opacity: 0;
  transform: scale3d(50%, 50%, 1);
}
.is-mouse .strip__video__container:hover::after {
  opacity: 0.3;
  transform: none;
}
.is-mouse .strip__video__container:hover img {
  transform: scale3D(1.1, 1.1, 1);
}

@font-face {
  font-family: "Noto Sans";
  src: url("/inc/font/NotoSans-Regular.woff2") format("woff2"), url("/inc/font/NotoSans-Regular.woff") format("woff"), url("/inc/font/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  src: url("/inc/font/NotoSans-Italic.woff2") format("woff2"), url("/inc/font/NotoSans-Italic.woff") format("woff"), url("/inc/font/NotoSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  src: url("/inc/font/NotoSans-Light.woff2") format("woff2"), url("/inc/font/NotoSans-Light.woff") format("woff"), url("/inc/font/NotoSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  src: url("/inc/font/NotoSans-LightItalic.woff2") format("woff2"), url("/inc/font/NotoSans-LightItalic.woff") format("woff"), url("/inc/font/NotoSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  src: url("/inc/font/NotoSans-SemiBold.woff2") format("woff2"), url("/inc/font/NotoSans-SemiBold.woff") format("woff"), url("/inc/font/NotoSans-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  src: url("/inc/font/NotoSans-SemiBoldItalic.woff2") format("woff2"), url("/inc/font/NotoSans-SemiBoldItalic.woff") format("woff"), url("/inc/font/NotoSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "fontello";
  src: url("/inc/font/fontello.woff2") format("woff2"), url("/inc/font/fontello.woff") format("woff"), url("/inc/font/fontello.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.splide:hover .splide__arrow:not([disabled]) {
  opacity: 1;
}
.splide-arrows--top .splide {
  padding-top: 5rem;
}
.no-cursor .splide__track {
  pointer-events: none;
}
.splide__pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: calc(var(--vpad) / 2);
}
.splide__pagination li {
  margin: 0 0.25em;
}
.splide__pagination li:only-child {
  display: none;
}
.splide__pagination button {
  position: relative;
  display: block;
  text-indent: 99rem;
  background: none;
  color: transparent;
  padding: 0;
  cursor: pointer;
  width: 3em;
}
@media screen and (prefers-reduced-motion: no-preference) {
  .splide__pagination button {
    transition: var(--aniTime, 0.3s) cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition-property: var(--aniProp, all);
  }
}
.splide__pagination button::before, .splide__pagination button::after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
  width: 100%;
  background: var(--colDots, var(--colText));
  height: 3px;
  border-radius: 1px;
}
.splide__pagination button::before {
  opacity: 0.5;
}
@media screen and (prefers-reduced-motion: no-preference) {
  .splide__pagination button::before {
    transition: var(--aniTime, 0.3s) cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition-property: var(--aniProp, all);
  }
}
.splide__pagination button::after {
  transform-origin: 0 50%;
  transform: scale3d(0, 1, 1);
}
.splide__pagination button.is-active::after {
  animation: activeDot var(--sliderTime, 0.3s) linear forwards;
}
@keyframes activeDot {
  0% {
    transform: scale3d(0, 1, 1);
  }
  100% {
    transform: none;
  }
}
.splide__arrows {
  position: absolute;
  z-index: 10;
  bottom: var(--vpad);
  right: calc(var(--vpad) / 2);
  display: grid;
  gap: 1rem;
  font-size: 0.85em;
}
@media screen and (min-width: 36em) {
  .splide__arrows {
    font-size: 1em;
  }
}
@media screen and (min-width: 50em) {
  .splide__arrows {
    font-size: 1.4em;
  }
}
.splide-arrows--top .splide__arrows {
  position: static;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.splide__arrow {
  --colTextBottoni: var(--colBianco);
  --colFondoBottoni: var(--colPrimario);
  --colBordoBottoni: var(--colBianco);
  border-radius: 50% !important;
}
.splide__arrow + .splide__arrow {
  margin: 0 !important;
}
.splide__arrow--prev {
  --X: -1;
  --Y: 0;
}
.splide__arrow--next {
  --X: 1;
  --Y: 0;
}
.splide__arrow::after {
  border-radius: 50%;
}
.is-mouse .splide__arrow {
  opacity: 0;
}
.splide-arrows--top .splide__arrow {
  opacity: 1;
}
.splide__arrow svg {
  display: none;
}
.splide__handle {
  --aniProp: opacity;
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5em;
  height: 4.5em;
  color: var(--colBianco);
  background: var(--colPrimario);
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  pointer-events: none;
  opacity: 0.8;
  left: calc(95% - 2.25em);
  top: 10%;
  font-size: 0.7em;
}
@media screen and (min-width: 36em) {
  .splide__handle {
    font-size: 1em;
  }
}
.splide__handle.visible {
  opacity: 1;
}
.splide__handle::before {
  font-size: 2.6em;
  margin: 0 !important;
}

:root {
  --f-spinner-width: 36px;
  --f-spinner-height: 36px;
  --f-spinner-color-1: rgba(0, 0, 0, 0.1);
  --f-spinner-color-2: rgba(17, 24, 28, 0.8);
  --f-spinner-stroke: 2.75;
}

.f-spinner {
  margin: auto;
  padding: 0;
  width: var(--f-spinner-width);
  height: var(--f-spinner-height);
}

.f-spinner svg {
  width: 100%;
  height: 100%;
  vertical-align: top;
  animation: f-spinner-rotate 2s linear infinite;
}

.f-spinner svg * {
  stroke-width: var(--f-spinner-stroke);
  fill: none;
}

.f-spinner svg *:first-child {
  stroke: var(--f-spinner-color-1);
}

.f-spinner svg *:last-child {
  stroke: var(--f-spinner-color-2);
  animation: f-spinner-dash 2s ease-in-out infinite;
}

@keyframes f-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes f-spinner-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.f-throwOutUp {
  animation: var(--f-throw-out-duration, 0.175s) ease-out both f-throwOutUp;
}

.f-throwOutDown {
  animation: var(--f-throw-out-duration, 0.175s) ease-out both f-throwOutDown;
}

@keyframes f-throwOutUp {
  to {
    transform: translate3d(0, calc(var(--f-throw-out-distance, 150px) * -1), 0);
    opacity: 0;
  }
}
@keyframes f-throwOutDown {
  to {
    transform: translate3d(0, var(--f-throw-out-distance, 150px), 0);
    opacity: 0;
  }
}
.f-zoomInUp {
  animation: var(--f-transition-duration, 0.2s) ease 0.1s both f-zoomInUp;
}

.f-zoomOutDown {
  animation: var(--f-transition-duration, 0.2s) ease both f-zoomOutDown;
}

@keyframes f-zoomInUp {
  from {
    transform: scale(0.975) translate3d(0, 16px, 0);
    opacity: 0;
  }
  to {
    transform: scale(1) translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes f-zoomOutDown {
  to {
    transform: scale(0.975) translate3d(0, 16px, 0);
    opacity: 0;
  }
}
.f-fadeIn {
  animation: var(--f-transition-duration, 0.2s) var(--f-transition-easing, ease) var(--f-transition-delay, 0s) both f-fadeIn;
  z-index: 2;
}

.f-fadeOut {
  animation: var(--f-transition-duration, 0.2s) var(--f-transition-easing, ease) var(--f-transition-delay, 0s) both f-fadeOut;
  z-index: 1;
}

@keyframes f-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes f-fadeOut {
  100% {
    opacity: 0;
  }
}
.f-fadeFastIn {
  animation: var(--f-transition-duration, 0.2s) ease-out both f-fadeFastIn;
  z-index: 2;
}

.f-fadeFastOut {
  animation: var(--f-transition-duration, 0.1s) ease-out both f-fadeFastOut;
  z-index: 2;
}

@keyframes f-fadeFastIn {
  0% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
@keyframes f-fadeFastOut {
  100% {
    opacity: 0;
  }
}
.f-fadeSlowIn {
  animation: var(--f-transition-duration, 0.5s) ease both f-fadeSlowIn;
  z-index: 2;
}

.f-fadeSlowOut {
  animation: var(--f-transition-duration, 0.5s) ease both f-fadeSlowOut;
  z-index: 1;
}

@keyframes f-fadeSlowIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes f-fadeSlowOut {
  100% {
    opacity: 0;
  }
}
.f-crossfadeIn {
  animation: var(--f-transition-duration, 0.2s) ease-out both f-crossfadeIn;
  z-index: 2;
}

.f-crossfadeOut {
  animation: calc(var(--f-transition-duration, 0.2s) * 0.5) linear 0.1s both f-crossfadeOut;
  z-index: 1;
}

@keyframes f-crossfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes f-crossfadeOut {
  100% {
    opacity: 0;
  }
}
.f-slideIn.from-next {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-slideInNext;
}

.f-slideIn.from-prev {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-slideInPrev;
}

.f-slideOut.to-next {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-slideOutNext;
}

.f-slideOut.to-prev {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-slideOutPrev;
}

@keyframes f-slideInPrev {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes f-slideInNext {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes f-slideOutNext {
  100% {
    transform: translateX(-100%);
  }
}
@keyframes f-slideOutPrev {
  100% {
    transform: translateX(100%);
  }
}
.f-classicIn.from-next {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-classicInNext;
  z-index: 2;
}

.f-classicIn.from-prev {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-classicInPrev;
  z-index: 2;
}

.f-classicOut.to-next {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-classicOutNext;
  z-index: 1;
}

.f-classicOut.to-prev {
  animation: var(--f-transition-duration, 0.85s) cubic-bezier(0.16, 1, 0.3, 1) f-classicOutPrev;
  z-index: 1;
}

@keyframes f-classicInNext {
  0% {
    transform: translateX(-75px);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes f-classicInPrev {
  0% {
    transform: translateX(75px);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes f-classicOutNext {
  100% {
    transform: translateX(-75px);
    opacity: 0;
  }
}
@keyframes f-classicOutPrev {
  100% {
    transform: translateX(75px);
    opacity: 0;
  }
}
:root {
  --f-button-width: 40px;
  --f-button-height: 40px;
  --f-button-border: 0;
  --f-button-border-radius: 0;
  --f-button-color: #374151;
  --f-button-bg: #f8f8f8;
  --f-button-hover-bg: #e0e0e0;
  --f-button-active-bg: #d0d0d0;
  --f-button-shadow: none;
  --f-button-transition: all 0.15s ease;
  --f-button-transform: none;
  --f-button-svg-width: 20px;
  --f-button-svg-height: 20px;
  --f-button-svg-stroke-width: 1.5;
  --f-button-svg-fill: none;
  --f-button-svg-filter: none;
  --f-button-svg-disabled-opacity: 0.65;
}

.f-button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  position: relative;
  margin: 0;
  padding: 0;
  width: var(--f-button-width);
  height: var(--f-button-height);
  border: var(--f-button-border);
  border-radius: var(--f-button-border-radius);
  color: var(--f-button-color);
  background: var(--f-button-bg);
  box-shadow: var(--f-button-shadow);
  pointer-events: all;
  cursor: pointer;
  transition: var(--f-button-transition);
}

@media (hover: hover) {
  .f-button:hover:not([disabled]) {
    color: var(--f-button-hover-color);
    background-color: var(--f-button-hover-bg);
  }
}
.f-button:active:not([disabled]) {
  background-color: var(--f-button-active-bg);
}

.f-button:focus:not(:focus-visible) {
  outline: none;
}

.f-button:focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 var(--f-button-outline, 2px) var(--f-button-outline-color, var(--f-button-color));
}

.f-button svg {
  width: var(--f-button-svg-width);
  height: var(--f-button-svg-height);
  fill: var(--f-button-svg-fill);
  stroke: currentColor;
  stroke-width: var(--f-button-svg-stroke-width);
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: opacity 0.15s ease;
  transform: var(--f-button-transform);
  filter: var(--f-button-svg-filter);
  pointer-events: none;
}

.f-button[disabled] {
  cursor: default;
}

.f-button[disabled] svg {
  opacity: var(--f-button-svg-disabled-opacity);
}

.f-carousel__nav .f-button.is-prev, .f-carousel__nav .f-button.is-next, .fancybox__nav .f-button.is-prev, .fancybox__nav .f-button.is-next {
  position: absolute;
  z-index: 1;
}

.is-horizontal .f-carousel__nav .f-button.is-prev, .is-horizontal .f-carousel__nav .f-button.is-next, .is-horizontal .fancybox__nav .f-button.is-prev, .is-horizontal .fancybox__nav .f-button.is-next {
  top: 50%;
  transform: translateY(-50%);
}

.is-horizontal .f-carousel__nav .f-button.is-prev, .is-horizontal .fancybox__nav .f-button.is-prev {
  left: var(--f-button-prev-pos);
}

.is-horizontal .f-carousel__nav .f-button.is-next, .is-horizontal .fancybox__nav .f-button.is-next {
  right: var(--f-button-next-pos);
}

.is-horizontal.is-rtl .f-carousel__nav .f-button.is-prev, .is-horizontal.is-rtl .fancybox__nav .f-button.is-prev {
  left: auto;
  right: var(--f-button-next-pos);
}

.is-horizontal.is-rtl .f-carousel__nav .f-button.is-next, .is-horizontal.is-rtl .fancybox__nav .f-button.is-next {
  right: auto;
  left: var(--f-button-prev-pos);
}

.is-vertical .f-carousel__nav .f-button.is-prev, .is-vertical .f-carousel__nav .f-button.is-next, .is-vertical .fancybox__nav .f-button.is-prev, .is-vertical .fancybox__nav .f-button.is-next {
  top: auto;
  left: 50%;
  transform: translateX(-50%);
}

.is-vertical .f-carousel__nav .f-button.is-prev, .is-vertical .fancybox__nav .f-button.is-prev {
  top: var(--f-button-next-pos);
}

.is-vertical .f-carousel__nav .f-button.is-next, .is-vertical .fancybox__nav .f-button.is-next {
  bottom: var(--f-button-next-pos);
}

.is-vertical .f-carousel__nav .f-button.is-prev svg, .is-vertical .f-carousel__nav .f-button.is-next svg, .is-vertical .fancybox__nav .f-button.is-prev svg, .is-vertical .fancybox__nav .f-button.is-next svg {
  transform: rotate(90deg);
}

.f-carousel__nav .f-button:disabled, .fancybox__nav .f-button:disabled {
  pointer-events: none;
}

html.with-fancybox {
  width: auto;
  overflow: visible;
  scroll-behavior: auto;
}

html.with-fancybox body {
  touch-action: none;
}

html.with-fancybox body.hide-scrollbar {
  width: auto;
  margin-right: calc(var(--fancybox-body-margin, 0px) + var(--fancybox-scrollbar-compensate, 0px));
  overflow: hidden !important;
  overscroll-behavior-y: none;
}

.fancybox__container {
  --fancybox-color: #dbdbdb;
  --fancybox-hover-color: #fff;
  --fancybox-bg: rgba(24, 24, 27, 0.98);
  --fancybox-slide-gap: 10px;
  --f-spinner-width: 50px;
  --f-spinner-height: 50px;
  --f-spinner-color-1: rgba(255, 255, 255, 0.1);
  --f-spinner-color-2: #bbb;
  --f-spinner-stroke: 3.65;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  direction: ltr;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #f8f8f8;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: visible;
  z-index: var(--fancybox-zIndex, 1050);
  outline: none;
  transform-origin: top left;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  overscroll-behavior-y: contain;
}

.fancybox__container *, .fancybox__container *::before, .fancybox__container *::after {
  box-sizing: inherit;
}

.fancybox__container::backdrop {
  background-color: rgba(0, 0, 0, 0);
}

.fancybox__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: var(--fancybox-bg);
  opacity: var(--fancybox-opacity, 1);
  will-change: opacity;
}

.fancybox__carousel {
  position: relative;
  box-sizing: border-box;
  flex: 1;
  min-height: 0;
  z-index: 10;
  overflow-y: visible;
  overflow-x: clip;
}

.fancybox__viewport {
  width: 100%;
  height: 100%;
}

.fancybox__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.fancybox__viewport.is-dragging {
  cursor: move;
  cursor: grabbing;
}

.fancybox__track {
  display: flex;
  margin: 0 auto;
  height: 100%;
}

.fancybox__slide {
  flex: 0 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 var(--fancybox-slide-gap) 0 0;
  padding: 4px;
  overflow: auto;
  overscroll-behavior: contain;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.fancybox__container:not(.is-compact) .fancybox__slide.has-close-btn {
  padding-top: 40px;
}

.fancybox__slide.has-iframe, .fancybox__slide.has-video, .fancybox__slide.has-html5video {
  overflow: hidden;
}

.fancybox__slide.has-image {
  overflow: hidden;
}

.fancybox__slide.has-image.is-animating, .fancybox__slide.has-image.is-selected {
  overflow: visible;
}

.fancybox__slide::before, .fancybox__slide::after {
  content: "";
  flex: 0 0 0;
  margin: auto;
}

.fancybox__backdrop:empty, .fancybox__viewport:empty, .fancybox__track:empty, .fancybox__slide:empty {
  display: block;
}

.fancybox__content {
  align-self: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 2rem;
  max-width: 100%;
  color: var(--fancybox-content-color, #374151);
  background: var(--fancybox-content-bg, #fff);
  cursor: default;
  border-radius: 0;
  z-index: 20;
}

.is-loading .fancybox__content {
  opacity: 0;
}

.is-draggable .fancybox__content {
  cursor: move;
  cursor: grab;
}

.can-zoom_in .fancybox__content {
  cursor: zoom-in;
}

.can-zoom_out .fancybox__content {
  cursor: zoom-out;
}

.is-dragging .fancybox__content {
  cursor: move;
  cursor: grabbing;
}

.fancybox__content [data-selectable], .fancybox__content [contenteditable] {
  cursor: auto;
}

.fancybox__slide.has-image > .fancybox__content {
  padding: 0;
  background: rgba(0, 0, 0, 0);
  min-height: 1px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  transition: none;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.fancybox__slide.has-image > .fancybox__content > picture > img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.is-animating .fancybox__content, .is-dragging .fancybox__content {
  will-change: transform, width, height;
}

.fancybox-image {
  margin: auto;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 0;
  object-fit: contain;
  user-select: none;
  filter: blur(0px);
}

.fancybox__caption {
  align-self: center;
  max-width: 100%;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 0 4px 0;
  overflow-wrap: anywhere;
  line-height: 1.375;
  color: var(--fancybox-color, currentColor);
  opacity: var(--fancybox-opacity, 1);
  cursor: auto;
  visibility: visible;
}

.is-loading .fancybox__caption, .is-closing .fancybox__caption {
  opacity: 0;
  visibility: hidden;
}

.is-compact .fancybox__caption {
  padding-bottom: 0;
}

.f-button.is-close-btn {
  --f-button-svg-stroke-width: 2;
  position: absolute;
  top: 0;
  right: 8px;
  z-index: 40;
}

.fancybox__content > .f-button.is-close-btn {
  --f-button-width: 34px;
  --f-button-height: 34px;
  --f-button-border-radius: 4px;
  --f-button-color: var(--fancybox-color, #fff);
  --f-button-hover-color: var(--fancybox-color, #fff);
  --f-button-bg: transparent;
  --f-button-hover-bg: transparent;
  --f-button-active-bg: transparent;
  --f-button-svg-width: 22px;
  --f-button-svg-height: 22px;
  position: absolute;
  top: -38px;
  right: 0;
  opacity: 0.75;
}

.is-loading .fancybox__content > .f-button.is-close-btn {
  visibility: hidden;
}

.is-zooming-out .fancybox__content > .f-button.is-close-btn {
  visibility: hidden;
}

.fancybox__content > .f-button.is-close-btn:hover {
  opacity: 1;
}

.fancybox__footer {
  padding: 0;
  margin: 0;
  position: relative;
}

.fancybox__footer .fancybox__caption {
  width: 100%;
  padding: 24px;
  opacity: var(--fancybox-opacity, 1);
  transition: all 0.25s ease;
}

.is-compact .fancybox__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: rgba(24, 24, 27, 0.5);
}

.is-compact .fancybox__footer .fancybox__caption {
  padding: 12px;
}

.is-compact .fancybox__content > .f-button.is-close-btn {
  --f-button-border-radius: 50%;
  --f-button-color: #fff;
  --f-button-hover-color: #fff;
  --f-button-outline-color: #000;
  --f-button-bg: rgba(0, 0, 0, 0.6);
  --f-button-active-bg: rgba(0, 0, 0, 0.6);
  --f-button-hover-bg: rgba(0, 0, 0, 0.6);
  --f-button-svg-width: 18px;
  --f-button-svg-height: 18px;
  --f-button-svg-filter: none;
  top: 5px;
  right: 5px;
}

.fancybox__nav {
  --f-button-width: 50px;
  --f-button-height: 50px;
  --f-button-border: 0;
  --f-button-border-radius: 50%;
  --f-button-color: var(--fancybox-color);
  --f-button-hover-color: var(--fancybox-hover-color);
  --f-button-bg: transparent;
  --f-button-hover-bg: rgba(24, 24, 27, 0.3);
  --f-button-active-bg: rgba(24, 24, 27, 0.5);
  --f-button-shadow: none;
  --f-button-transition: all 0.15s ease;
  --f-button-transform: none;
  --f-button-svg-width: 26px;
  --f-button-svg-height: 26px;
  --f-button-svg-stroke-width: 2.5;
  --f-button-svg-fill: none;
  --f-button-svg-filter: drop-shadow(1px 1px 1px rgba(24, 24, 27, 0.5));
  --f-button-svg-disabled-opacity: 0.65;
  --f-button-next-pos: 1rem;
  --f-button-prev-pos: 1rem;
  opacity: var(--fancybox-opacity, 1);
}

.fancybox__nav .f-button:before {
  position: absolute;
  content: "";
  top: -30px;
  right: -20px;
  left: -20px;
  bottom: -30px;
  z-index: 1;
}

.is-idle .fancybox__nav {
  animation: 0.15s ease-out both f-fadeOut;
}

.is-idle.is-compact .fancybox__footer {
  pointer-events: none;
  animation: 0.15s ease-out both f-fadeOut;
}

.fancybox__slide > .f-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: var(--f-spinner-top, calc(var(--f-spinner-width) * -0.5)) 0 0 var(--f-spinner-left, calc(var(--f-spinner-height) * -0.5));
  z-index: 30;
  cursor: pointer;
}

.fancybox-protected {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;
  user-select: none;
}

.fancybox-ghost {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 0;
  object-fit: contain;
  z-index: 40;
  user-select: none;
  pointer-events: none;
}

.fancybox-focus-guard {
  outline: none;
  opacity: 0;
  position: fixed;
  pointer-events: none;
}

.fancybox__container:not([aria-hidden]) {
  opacity: 0;
}

.fancybox__container.is-animated[aria-hidden=false] > *:not(.fancybox__backdrop, .fancybox__carousel), .fancybox__container.is-animated[aria-hidden=false] .fancybox__carousel > *:not(.fancybox__viewport), .fancybox__container.is-animated[aria-hidden=false] .fancybox__slide > *:not(.fancybox__content) {
  animation: var(--f-interface-enter-duration, 0.25s) ease 0.1s backwards f-fadeIn;
}

.fancybox__container.is-animated[aria-hidden=false] .fancybox__backdrop {
  animation: var(--f-backdrop-enter-duration, 0.35s) ease backwards f-fadeIn;
}

.fancybox__container.is-animated[aria-hidden=true] > *:not(.fancybox__backdrop, .fancybox__carousel), .fancybox__container.is-animated[aria-hidden=true] .fancybox__carousel > *:not(.fancybox__viewport), .fancybox__container.is-animated[aria-hidden=true] .fancybox__slide > *:not(.fancybox__content) {
  animation: var(--f-interface-exit-duration, 0.15s) ease forwards f-fadeOut;
}

.fancybox__container.is-animated[aria-hidden=true] .fancybox__backdrop {
  animation: var(--f-backdrop-exit-duration, 0.35s) ease forwards f-fadeOut;
}

.has-iframe .fancybox__content, .has-map .fancybox__content, .has-pdf .fancybox__content, .has-youtube .fancybox__content, .has-vimeo .fancybox__content, .has-html5video .fancybox__content {
  max-width: 100%;
  flex-shrink: 1;
  min-height: 1px;
  overflow: visible;
}

.has-iframe .fancybox__content, .has-map .fancybox__content, .has-pdf .fancybox__content {
  width: calc(100% - 120px);
  height: 90%;
}

.fancybox__container.is-compact .has-iframe .fancybox__content, .fancybox__container.is-compact .has-map .fancybox__content, .fancybox__container.is-compact .has-pdf .fancybox__content {
  width: 100%;
  height: 100%;
}

.has-youtube .fancybox__content, .has-vimeo .fancybox__content, .has-html5video .fancybox__content {
  width: 960px;
  height: 540px;
  max-width: 100%;
  max-height: 100%;
}

.has-map .fancybox__content, .has-pdf .fancybox__content, .has-youtube .fancybox__content, .has-vimeo .fancybox__content, .has-html5video .fancybox__content {
  padding: 0;
  background: rgba(24, 24, 27, 0.9);
  color: #fff;
}

.has-map .fancybox__content {
  background: #e5e3df;
}

.fancybox__html5video, .fancybox__iframe {
  border: 0;
  display: block;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0);
}

.fancybox-placeholder {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.f-carousel__thumbs {
  --f-thumb-width: 96px;
  --f-thumb-height: 72px;
  --f-thumb-outline: 0;
  --f-thumb-outline-color: #5eb0ef;
  --f-thumb-opacity: 1;
  --f-thumb-hover-opacity: 1;
  --f-thumb-selected-opacity: 1;
  --f-thumb-border-radius: 2px;
  --f-thumb-offset: 0px;
  --f-button-next-pos: 0;
  --f-button-prev-pos: 0;
}

.f-carousel__thumbs.is-classic {
  --f-thumb-gap: 8px;
  --f-thumb-opacity: 0.5;
  --f-thumb-hover-opacity: 1;
  --f-thumb-selected-opacity: 1;
}

.f-carousel__thumbs.is-modern {
  --f-thumb-gap: 4px;
  --f-thumb-extra-gap: 16px;
  --f-thumb-clip-width: 46px;
}

.f-thumbs {
  position: relative;
  flex: 0 0 auto;
  margin: 0;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  perspective: 1000px;
  transform: translateZ(0);
}

.f-thumbs .f-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-image: linear-gradient(#ebeff2, #e2e8f0);
  z-index: -1;
}

.f-thumbs .f-spinner svg {
  display: none;
}

.f-thumbs.is-vertical {
  height: 100%;
}

.f-thumbs__viewport {
  width: 100%;
  height: auto;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.f-thumbs__track {
  display: flex;
}

.f-thumbs__slide {
  position: relative;
  flex: 0 0 auto;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: var(--f-thumb-width);
  height: var(--f-thumb-height);
  overflow: visible;
  cursor: pointer;
}

.f-thumbs__slide.is-loading img {
  opacity: 0;
}

.is-classic .f-thumbs__viewport {
  height: 100%;
}

.is-modern .f-thumbs__track {
  width: max-content;
}

.is-modern .f-thumbs__track::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc((var(--f-thumb-clip-width, 0)) * -0.5);
  width: calc(var(--width, 0) * 1px + var(--f-thumb-clip-width, 0));
  cursor: pointer;
}

.is-modern .f-thumbs__slide {
  width: var(--f-thumb-clip-width);
  transform: translate3d(calc(var(--shift, 0) * -1px), 0, 0);
  transition: none;
  pointer-events: none;
}

.is-modern.is-resting .f-thumbs__slide {
  transition: transform 0.33s ease;
}

.is-modern.is-resting .f-thumbs__slide__button {
  transition: clip-path 0.33s ease;
}

.is-using-tab .is-modern .f-thumbs__slide:focus-within {
  filter: drop-shadow(-1px 0px 0px var(--f-thumb-outline-color)) drop-shadow(2px 0px 0px var(--f-thumb-outline-color)) drop-shadow(0px -1px 0px var(--f-thumb-outline-color)) drop-shadow(0px 2px 0px var(--f-thumb-outline-color));
}

.f-thumbs__slide__button {
  appearance: none;
  width: var(--f-thumb-width);
  height: 100%;
  margin: 0 -100% 0 -100%;
  padding: 0;
  border: 0;
  position: relative;
  border-radius: var(--f-thumb-border-radius);
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  outline: none;
  cursor: pointer;
  pointer-events: auto;
  touch-action: manipulation;
  opacity: var(--f-thumb-opacity);
  transition: opacity 0.2s ease;
}

.f-thumbs__slide__button:hover {
  opacity: var(--f-thumb-hover-opacity);
}

.f-thumbs__slide__button:focus:not(:focus-visible) {
  outline: none;
}

.f-thumbs__slide__button:focus-visible {
  outline: none;
  opacity: var(--f-thumb-selected-opacity);
}

.is-modern .f-thumbs__slide__button {
  --clip-path: inset( 0 calc( ((var(--f-thumb-width, 0) - var(--f-thumb-clip-width, 0))) * (1 - var(--progress, 0)) * 0.5 ) round var(--f-thumb-border-radius, 0) );
  clip-path: var(--clip-path);
}

.is-classic .is-nav-selected .f-thumbs__slide__button {
  opacity: var(--f-thumb-selected-opacity);
}

.is-classic .is-nav-selected .f-thumbs__slide__button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  bottom: 0;
  border: var(--f-thumb-outline, 0) solid var(--f-thumb-outline-color, transparent);
  border-radius: var(--f-thumb-border-radius);
  animation: f-fadeIn 0.2s ease-out;
  z-index: 10;
}

.f-thumbs__slide__img {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: var(--f-thumb-offset);
  box-sizing: border-box;
  pointer-events: none;
  object-fit: cover;
  border-radius: var(--f-thumb-border-radius);
}

.f-thumbs.is-horizontal .f-thumbs__track {
  padding: 8px 0 12px 0;
}

.f-thumbs.is-horizontal .f-thumbs__slide {
  margin: 0 var(--f-thumb-gap) 0 0;
}

.f-thumbs.is-vertical .f-thumbs__track {
  flex-wrap: wrap;
  padding: 0 8px;
}

.f-thumbs.is-vertical .f-thumbs__slide {
  margin: 0 0 var(--f-thumb-gap) 0;
}

.fancybox__thumbs {
  --f-thumb-width: 96px;
  --f-thumb-height: 72px;
  --f-thumb-border-radius: 2px;
  --f-thumb-outline: 2px;
  --f-thumb-outline-color: #ededed;
  position: relative;
  opacity: var(--fancybox-opacity, 1);
  transition: max-height 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}

.fancybox__thumbs.is-classic {
  --f-thumb-gap: 8px;
  --f-thumb-opacity: 0.5;
  --f-thumb-hover-opacity: 1;
}

.fancybox__thumbs.is-classic .f-spinner {
  background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
}

.fancybox__thumbs.is-modern {
  --f-thumb-gap: 4px;
  --f-thumb-extra-gap: 16px;
  --f-thumb-clip-width: 46px;
  --f-thumb-opacity: 1;
  --f-thumb-hover-opacity: 1;
}

.fancybox__thumbs.is-modern .f-spinner {
  background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
}

.fancybox__thumbs.is-horizontal {
  padding: 0 var(--f-thumb-gap);
}

.fancybox__thumbs.is-vertical {
  padding: var(--f-thumb-gap) 0;
}

.is-compact .fancybox__thumbs {
  --f-thumb-width: 64px;
  --f-thumb-clip-width: 32px;
  --f-thumb-height: 48px;
  --f-thumb-extra-gap: 10px;
}

.fancybox__thumbs.is-masked {
  max-height: 0px !important;
}

.is-closing .fancybox__thumbs {
  transition: none !important;
}

.fancybox__toolbar {
  --f-progress-color: var(--fancybox-color, rgba(255, 255, 255, 0.94));
  --f-button-width: 46px;
  --f-button-height: 46px;
  --f-button-color: var(--fancybox-color);
  --f-button-hover-color: var(--fancybox-hover-color);
  --f-button-bg: rgba(24, 24, 27, 0.65);
  --f-button-hover-bg: rgba(70, 70, 73, 0.65);
  --f-button-active-bg: rgba(90, 90, 93, 0.65);
  --f-button-border-radius: 0;
  --f-button-svg-width: 24px;
  --f-button-svg-height: 24px;
  --f-button-svg-stroke-width: 1.5;
  --f-button-svg-filter: drop-shadow(1px 1px 1px rgba(24, 24, 27, 0.15));
  --f-button-svg-fill: none;
  --f-button-svg-disabled-opacity: 0.65;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI Adjusted", "Segoe UI", "Liberation Sans", sans-serif;
  color: var(--fancybox-color, currentColor);
  opacity: var(--fancybox-opacity, 1);
  text-shadow: var(--fancybox-toolbar-text-shadow, 1px 1px 1px rgba(0, 0, 0, 0.5));
  pointer-events: none;
  z-index: 20;
}

.fancybox__toolbar :focus-visible {
  z-index: 1;
}

.fancybox__toolbar.is-absolute, .is-compact .fancybox__toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.is-idle .fancybox__toolbar {
  pointer-events: none;
  animation: 0.15s ease-out both f-fadeOut;
}

.fancybox__toolbar__column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.fancybox__toolbar__column.is-left, .fancybox__toolbar__column.is-right {
  flex-grow: 1;
  flex-basis: 0;
}

.fancybox__toolbar__column.is-right {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.fancybox__infobar {
  padding: 0 5px;
  line-height: var(--f-button-height);
  text-align: center;
  font-size: 17px;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: subpixel-antialiased;
  cursor: default;
  user-select: none;
}

.fancybox__infobar span {
  padding: 0 5px;
}

.fancybox__infobar:not(:first-child):not(:last-child) {
  background: var(--f-button-bg);
}

[data-fancybox-toggle-slideshow] {
  position: relative;
}

[data-fancybox-toggle-slideshow] .f-progress {
  height: 100%;
  opacity: 0.3;
}

[data-fancybox-toggle-slideshow] svg g:first-child {
  display: flex;
}

[data-fancybox-toggle-slideshow] svg g:last-child {
  display: none;
}

.has-slideshow [data-fancybox-toggle-slideshow] svg g:first-child {
  display: none;
}

.has-slideshow [data-fancybox-toggle-slideshow] svg g:last-child {
  display: flex;
}

[data-fancybox-toggle-fullscreen] svg g:first-child {
  display: flex;
}

[data-fancybox-toggle-fullscreen] svg g:last-child {
  display: none;
}

:fullscreen [data-fancybox-toggle-fullscreen] svg g:first-child {
  display: none;
}

:fullscreen [data-fancybox-toggle-fullscreen] svg g:last-child {
  display: flex;
}

.f-progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  transform: scaleX(0);
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  background: var(--f-progress-color, var(--f-carousel-theme-color, #0091ff));
  z-index: 30;
  user-select: none;
  pointer-events: none;
}

.fancybox__container {
  --fancybox-opacity: 0.85;
  --fancybox-bg: var(--colNero);
}
.fancybox__nav {
  --f-button-width: auto;
}

.fancy--md {
  width: 75rem;
}
.fancy--sm {
  width: 56.25rem;
}
.fancy__wrapper {
  display: none;
}
.fancy__content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-height: 80vh;
}
.fancy__content .buttons {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--vpad) / 2);
}
.fancy__content .buttons.text-center {
  justify-content: center;
}
@media screen and (max-width: 36em) {
  .fancy__content .buttons .button {
    width: 100%;
  }
  .fancy__content .buttons .button.js-fancy-close {
    order: 2;
  }
}
.fancy__header {
  flex: 0 0 auto;
  margin-bottom: calc(var(--vpad) / 2);
}
.fancy__header__image {
  margin: calc(var(--vpad) / -2);
}
.fancy__scroll {
  flex: 1 1 auto;
  overflow: auto;
  padding: 1em;
}
.fancy__footer {
  flex: 0 0 auto;
  margin-top: calc(var(--vpad) / 2);
  padding-top: calc(var(--vpad) / 2);
  border-top: 1px solid var(--colPrimarioScuro);
}
.fancy--watermark .fancybox__content {
  pointer-events: none;
}
.fancy--watermark .fancybox__content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: transparent url("/img/watermark.svg") no-repeat 50% 50%;
  background-size: 200px 30px;
  opacity: 0.3;
  pointer-events: none;
}

.fancymsg {
  width: 38em;
  max-width: 100%;
}
.fancymsg__header {
  margin-bottom: calc(var(--vpad) / 3);
  text-align: center;
}
.fancymsg__logo {
  display: inline-block;
  background: transparent url("/img/caffe-paszkowski.svg") no-repeat 50% 50%;
  background-size: contain;
  width: 100px;
  height: 60px;
}
.fancymsg .buttons {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--vpad) / 2);
}
@media screen and (max-width: 36em) {
  .fancymsg .buttons .button {
    width: 100%;
  }
  .fancymsg .buttons .button.js-fancy-close {
    order: 2;
  }
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.flatpickr-current-month {
  display: inline-flex;
  justify-content: space-between;
}

.icon-fullscreen:before, .video__fullscreen:before {
  content: "\e800";
}

.icon-heart-full:before {
  content: "\e801";
}

.icon-down-open:before {
  content: "\e802";
}

.icon-mail:before {
  content: "\e803";
}

.icon-left-open:before {
  content: "\e804";
}

.icon-gallery:before {
  content: "\e805";
}

.icon-right-open:before {
  content: "\e806";
}

.icon-up-open:before {
  content: "\e807";
}

.scheduled__hour::before, .icon-clock:before {
  content: "\e808";
}

.strip__video__container::before, .icon-play:before, .video__play:before {
  content: "\e80a";
}

.icon-pause:before, .video__play.playing:before {
  content: "\e80b";
}

.toggle__switch.toggle-open::after, input[type=checkbox] + label::after,
input[type=radio] + label::after, .icon-cancel:before {
  content: "\e810";
}

.toggle__switch::after, .icon-plus:before, .image__link:before {
  content: "\e811";
}

.icon-trash:before {
  content: "\e813";
}

.icon-check:before, .dropdown li.active:before {
  content: "\e814";
}

.icon-minus:before {
  content: "\e815";
}

.icon-heart:before {
  content: "\e818";
}

.icon-tripadvisor:before {
  content: "\e81c";
}

.icon-slider-next:before {
  content: "\e892";
}

.icon-slider-prev:before {
  content: "\e893";
}

.accordion__switch::after, .cursor-down .np-cursor__text::before, .icon-arrow-down:before {
  content: "\e8a1";
}

.slick-cursor-left .np-cursor__text::before, .cursor-left .np-cursor__text::before, .icon-arrow-left:before {
  content: "\e8a2";
}

.slick-cursor-right .np-cursor__text::before, .cursor-right .np-cursor__text::before, .link-list li::before, .is-mouse .read-more::after, .icon-arrow-right:before {
  content: "\e8a3";
}

.cursor-up .np-cursor__text::before, .icon-arrow-up:before {
  content: "\e8a4";
}

.icon-phone:before {
  content: "\e8b0";
}

.icon-search:before {
  content: "\e8b1";
}

.icon-user:before {
  content: "\e8c0";
}

.icon-cart:before {
  content: "\e8c1";
}

.side-popup__close::before, .gm-style .gm-style-iw button::before, .icon-menu-close:before {
  content: "\e8f2";
}

.video__thumb::before, .strip__gallery--video::after, .icon-play-circled:before {
  content: "\e904";
}

.icon-pdf:before {
  content: "\e92b";
}

.icon-arrow-up-left:before {
  content: "\e93a";
}

.icon-arrow-up-right:before {
  content: "\e93b";
}

.icon-twitter:before {
  content: "\e945";
}

.icon-cross:before {
  content: "\e953";
}

.scroll__handle::before, .icon-drag:before, .splide__handle::before {
  content: "\e964";
}

.icon-location:before {
  content: "\e96b";
}

.icon-share-point:before {
  content: "\e96e";
}

.icon-sound-no:before {
  content: "\e96f";
}

.icon-sound-off:before, .video__sound:before {
  content: "\e970";
}

.icon-sound-on:before, .video__sound.playing:before {
  content: "\e971";
}

.icon-facebook:before {
  content: "\e980";
}

.icon-linkedin:before {
  content: "\f0e1";
}

.icon-sort-up:before {
  content: "\f160";
}

.icon-sort-down:before {
  content: "\f161";
}

.icon-youtube-play:before {
  content: "\f16a";
}

.icon-instagram:before {
  content: "\f16d";
}

.icon-file-pdf:before, [href$=".pdf" i]:not(.read-more):not(.strip__blurb__link):before {
  content: "\f1c1";
}

.icon-file-word:before, [href$=".doc" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".docx" i]:not(.read-more):not(.strip__blurb__link):before {
  content: "\f1c2";
}

.icon-file-excel:before, [href$=".xls" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".xlsx" i]:not(.read-more):not(.strip__blurb__link):before {
  content: "\f1c3";
}

.icon-file-powerpoint:before, [href$=".ppt" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".pptx" i]:not(.read-more):not(.strip__blurb__link):before {
  content: "\f1c4";
}

.icon-file-archive:before, [href$=".zip" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".rar" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".gzip" i]:not(.read-more):not(.strip__blurb__link):before {
  content: "\f1c6";
}

.icon-file-audio:before, [href$=".mp3" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".aiff" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".aac" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".flac" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".m4a" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".wav" i]:not(.read-more):not(.strip__blurb__link):before {
  content: "\f1c7";
}

.icon-file-video:before, [href$=".mp4" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".mpg" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".mov" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".mkv" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".flv" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".avi" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".wmv" i]:not(.read-more):not(.strip__blurb__link):before {
  content: "\f1c8";
}

.icon-sliders:before {
  content: "\f1de";
}

.icon-pinterest:before {
  content: "\f231";
}

.icon-whatsapp:before {
  content: "\f232";
}

.icon-vimeo:before {
  content: "\f27d";
}

/* ! Form ---------------- */
.form-honeypot {
  display: none;
}
.form__popup {
  --colTitle: #fff;
  width: 100%;
  height: 100%;
  background: var(--colPrimario);
  color: #fff;
}
.form__grid {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}
.form__grid label.error {
  width: 100%;
  order: 5;
}
.form__grid input[type] {
  flex: 1 1 auto;
  width: auto;
  height: auto;
}
.form__grid__button {
  flex: 0 0 auto;
  height: auto;
  margin: 0;
  padding: 0.3em 1em;
  color: inherit;
  border-bottom: 1px solid;
}
@media screen and (max-width: 36em) {
  .form__grid__button {
    width: 100%;
    margin-top: 1em;
  }
}

.warning {
  color: var(--colError);
}

legend {
  display: block;
  font-size: 1em;
  margin: 0 0 calc(var(--vpad) / 3);
  grid-column: 1/-1;
}

fieldset {
  margin-top: var(--vpad);
}
.bld-form__col fieldset, fieldset:first-child {
  margin-top: 0;
}
.np_a11y fieldset {
  border-color: var(--colGrigioScuro);
  padding-left: calc(var(--vpad) / 2);
  padding-right: calc(var(--vpad) / 2);
}

.label,
label {
  transition-property: transform;
  max-width: 100%;
  font-size: 1em;
  display: block;
  text-align: left;
  margin-bottom: 0.75em;
  line-height: 1.2;
  transform-origin: 0 0;
  transform: translate3d(0.2em, var(--inputH), 0);
}
.compiled .label, .label.static,
.compiled label,
label.static {
  transform: scale3d(0.9, 0.9, 1);
}
.label .required::after, .label.required::after,
label .required::after,
label.required::after {
  content: "*";
  display: inline-block;
  margin-left: 0.3em;
  color: var(--colError);
}
.label.error,
label.error {
  color: var(--colError);
  display: block;
  margin-top: 0.3em;
}
.label small,
label small {
  font-size: 0.85em;
  color: var(--colGrigio);
}
.label img,
label img {
  max-width: 13em;
  width: 100%;
  height: auto;
  object-fit: contain;
}

input[type=text],
input[type=password],
input[type=search],
input[type=email],
input[type=date],
input[type=number],
input[type=url],
input[type=file],
textarea,
select,
.dropdown span {
  display: block;
  width: 100%;
  max-width: 100%;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  padding: 0.3em 0;
  margin: 0;
  font-size: 1em;
  line-height: 1.2;
  border: none;
  border-bottom: 1px solid;
  background: transparent;
  -webkit-appearance: none;
  appearance: none;
  height: var(--inputH);
  border-radius: 0;
}
input[type=text][readonly],
input[type=password][readonly],
input[type=search][readonly],
input[type=email][readonly],
input[type=date][readonly],
input[type=number][readonly],
input[type=url][readonly],
input[type=file][readonly],
textarea[readonly],
select[readonly],
.dropdown span[readonly] {
  opacity: 0.6;
  cursor: not-allowed;
  background: var(--colGrigioChiaro);
}
.text-small input[type=text][readonly],
.text-small input[type=password][readonly],
.text-small input[type=search][readonly],
.text-small input[type=email][readonly],
.text-small input[type=date][readonly],
.text-small input[type=number][readonly],
.text-small input[type=url][readonly],
.text-small input[type=file][readonly],
.text-small textarea[readonly],
.text-small select[readonly],
.text-small .dropdown span[readonly],
.dropdown .text-small span[readonly] {
  height: auto;
  padding: 0.3em 0.7em;
}
.flatpickr-input + input[type=text][readonly],
.flatpickr-input + input[type=password][readonly],
.flatpickr-input + input[type=search][readonly],
.flatpickr-input + input[type=email][readonly],
.flatpickr-input + input[type=date][readonly],
.flatpickr-input + input[type=number][readonly],
.flatpickr-input + input[type=url][readonly],
.flatpickr-input + input[type=file][readonly],
.flatpickr-input + textarea[readonly],
.flatpickr-input + select[readonly],
.dropdown .flatpickr-input + span[readonly] {
  opacity: 1;
  background: inherit;
  cursor: pointer;
}
input[type=text].input--small,
input[type=password].input--small,
input[type=search].input--small,
input[type=email].input--small,
input[type=date].input--small,
input[type=number].input--small,
input[type=url].input--small,
input[type=file].input--small,
textarea.input--small,
select.input--small,
.dropdown span.input--small {
  height: auto;
  padding: 0.3em 0.7em;
}
.is-touch input[type=text],
.is-touch input[type=password],
.is-touch input[type=search],
.is-touch input[type=email],
.is-touch input[type=date],
.is-touch input[type=number],
.is-touch input[type=url],
.is-touch input[type=file],
.is-touch textarea,
.is-touch select,
.is-touch .dropdown span,
.dropdown .is-touch span {
  font-size: 1rem !important;
}

input[type=text].parsley-error,
input[type=password].parsley-error,
input[type=search].parsley-error,
input[type=email].parsley-error,
input[type=date].parsley-error,
input[type=time].parsley-error,
input[type=number].parsley-error,
input[type=url].parsley-error,
textarea.parsley-error {
  border-color: var(--colError);
}
input[type=text].flt-error,
input[type=password].flt-error,
input[type=search].flt-error,
input[type=email].flt-error,
input[type=date].flt-error,
input[type=time].flt-error,
input[type=number].flt-error,
input[type=url].flt-error,
textarea.flt-error {
  color: var(--colError);
  border-color: var(--colError);
}
input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=search]::placeholder,
input[type=email]::placeholder,
input[type=date]::placeholder,
input[type=time]::placeholder,
input[type=number]::placeholder,
input[type=url]::placeholder,
textarea::placeholder {
  color: inherit;
}

input[type=file] {
  padding: 0.75em;
  padding-left: 0;
  font-size: 0.9em;
  text-align: left;
  height: auto;
}

textarea {
  height: auto;
}

select, .dropdown span {
  overflow: hidden;
  padding-right: 2em;
  margin: 0;
  background-repeat: no-repeat;
  background-position: right 1em top 50%;
  background-size: 0.65em auto;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDQ2MCAyNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoyOyI+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLC03MywtMjcwKSI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMC41LC0wLjUsMC41LDAuNSwzLDMxMCkiPgogICAgICAgICAgICA8cGF0aCBkPSJNMTQwLDQ2MEwxNDAsNjBMODAsNjBMODAsNTIwTDU0MCw1MjBMNTQwLDQ2MEwxNDAsNDYwWiIgc3R5bGU9ImZpbGw6IzFEMUQxRDsiLz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
}
select::-ms-expand, .dropdown span::-ms-expand {
  display: none;
}
select option, .dropdown span option {
  font-weight: normal;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin: 0.5em 0;
}
select.nofull, .dropdown span.nofull {
  width: auto;
  max-width: 100%;
}

input[type=checkbox],
input[type=radio] {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
input[type=checkbox] + label,
input[type=radio] + label {
  font-size: 0.9em;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
  text-transform: none;
  padding-left: 0;
  transform: none;
  font-weight: 400;
  opacity: 1;
}
input[type=checkbox] + label::before, input[type=checkbox] + label::after,
input[type=radio] + label::before,
input[type=radio] + label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  min-width: 1em;
  height: 1em;
  line-height: 1em;
  text-align: center;
  border: 1px solid;
  background: transparent;
  margin-right: 1em;
  margin-top: 0.2em;
}
input[type=checkbox] + label::before,
input[type=radio] + label::before {
  content: "";
}
input[type=checkbox] + label::after,
input[type=radio] + label::after {
  font-family: "fontello";
  display: none;
  order: -1;
  color: inherit;
  border-color: var(--colContrasto);
  background: transparent;
  line-height: 1.1;
}
input[type=checkbox] + label:hover::before,
input[type=radio] + label:hover::before {
  border-color: var(--colContrasto);
}
input[type=checkbox] + label:hover::after,
input[type=radio] + label:hover::after {
  color: var(--colNero);
  background: var(--colContrasto);
}
input[type=checkbox] + label.no-check::before, input[type=checkbox] + label.no-check::after,
input[type=radio] + label.no-check::before,
input[type=radio] + label.no-check::after {
  content: unset;
}
input[type=checkbox]:checked + label::before,
input[type=radio]:checked + label::before {
  display: none;
}
input[type=checkbox]:checked + label::after,
input[type=radio]:checked + label::after {
  display: flex;
}
input[type=checkbox]:disabled + label,
input[type=radio]:disabled + label {
  color: var(--colGrigioMedio);
  pointer-events: none;
}
input[type=checkbox]:disabled + label::before,
input[type=radio]:disabled + label::before {
  border-color: var(--colGrigioChiaro);
}
input[type=checkbox]:disabled + label::after,
input[type=radio]:disabled + label::after {
  border-color: var(--colGrigioChiaro);
  background: var(--colGrigioChiaro);
}
input[type=radio] + label::before, input[type=radio] + label::after {
  border-radius: 50%;
}
input[type=radio] + label::after {
  content: "•";
}

.input--icon {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: flex-start;
  position: relative;
}
.input--icon input[type] {
  flex: 1 1 auto;
  grid-row: 1/1;
  grid-column: 1/1;
  padding-left: 2.5em;
}
.input--icon::before {
  grid-row: 1/1;
  grid-column: 1/1;
  color: var(--colGrigioMedio);
  font-size: 1.2em;
  position: relative;
  z-index: 1;
  padding: 0.3em 0.7em;
}
.input--number {
  display: flex;
  overflow: hidden;
  border: 1px solid var(--colContrasto);
  border-radius: var(--inputH);
  max-width: calc(var(--inputH) * 3.5);
}
.input--number input[type=number] {
  border: none;
  width: var(--inputH);
  flex: 1 1 auto;
  text-align: center;
  border-radius: 0;
  color: inherit;
  appearance: textfield;
}
.input--number input[type=number]::-webkit-inner-spin-button {
  appearance: none;
}
.input--number button {
  color: inherit;
  font-size: 1.2em;
  border: none;
  background: var(--colGrigio);
  width: var(--inputH);
  flex: 0 0 auto;
  cursor: pointer;
}
.input--number button.off {
  color: var(--colGrigioMedio);
  background: var(--colGrigioChiaro);
  cursor: auto;
}

.disclaimer {
  display: none;
  font-size: 0.85em;
  padding: 0.5em;
  border: 1px solid var(--colGrigio);
  background: var(--colBg);
  color: var(--colText);
  margin: 0.5em 2em;
  opacity: 0.8;
  text-align: left;
}
.disclaimer a {
  color: var(--colLink);
}

[class*=g-recaptcha],
[class*=g-recaptcha] div,
[class*=g-recaptcha] iframe {
  max-width: 100%;
}
[class*=g-recaptcha] div {
  margin: 0 auto;
}

.dropdown {
  position: relative;
  text-align: left;
  font-size: 0.9em;
}
.dropdown:last-child ul {
  left: auto;
  right: 0;
}
.dropdown:hover {
  background-position: right center;
}
.dropdown__container {
  padding: 0;
  transform: none;
  max-width: none;
}
.dropdown__label {
  font-style: normal;
  font-weight: 400;
}
.dropdown span {
  padding-left: 1em;
  white-space: nowrap;
}
.is-touch .dropdown span {
  font-size: inherit !important;
}
.dropdown [type=checkbox] {
  visibility: visible;
  z-index: 1;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}
.dropdown [type=checkbox]:checked {
  position: fixed;
  z-index: 0;
  right: 0;
  bottom: 0;
}
.dropdown [type=checkbox]:checked + ul {
  display: block;
}
.dropdown [type=checkbox]:checked ~ span u {
  visibility: hidden;
}
.dropdown label {
  font-size: inherit;
  margin: 0;
}
.dropdown ul {
  text-align: left;
  position: absolute;
  top: 100%;
  margin-top: -1px;
  border: 1px solid #ccc;
  border-radius: 3px;
  left: 0;
  list-style: none;
  padding: 4px 0;
  display: none;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.175);
  width: 100%;
  min-width: 200px;
  z-index: 1;
}
.dropdown li {
  display: block;
  padding: 6px 20px;
  white-space: nowrap;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown li:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.dropdown li.active {
  font-weight: 400;
}

.parsley-errors-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 0.5em;
  font-size: 0.9em;
  color: var(--colError);
}
.parsley-errors-list + .parsley-errors-list {
  display: none !important;
}

.scheduled__hour::before, .scroll__handle::before, .side-popup__close::before, .accordion__switch::after, .gm-style .gm-style-iw button::before, .toggle__switch::after, .cursor-down .np-cursor__text::before, .cursor-up .np-cursor__text::before, .slick-cursor-right .np-cursor__text::before, .cursor-right .np-cursor__text::before, .slick-cursor-left .np-cursor__text::before, .cursor-left .np-cursor__text::before, .link-list li::before, .is-mouse .read-more::after, .video__thumb::before, .strip__gallery--video::after, .strip__video__container::before, [class^=icon-]:before, .image__link:before, [href$=".mp4" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".mpg" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".mov" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".mkv" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".flv" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".avi" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".wmv" i]:not(.read-more):not(.strip__blurb__link):before, [href$=".mp3" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".aiff" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".aac" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".flac" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".m4a" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".wav" i]:not(.read-more):not(.strip__blurb__link):before, [href$=".zip" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".rar" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".gzip" i]:not(.read-more):not(.strip__blurb__link):before, [href$=".ppt" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".pptx" i]:not(.read-more):not(.strip__blurb__link):before, [href$=".xls" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".xlsx" i]:not(.read-more):not(.strip__blurb__link):before, [href$=".doc" i]:not(.read-more):not(.strip__blurb__link):before,
[href$=".docx" i]:not(.read-more):not(.strip__blurb__link):before, [href$=".pdf" i]:not(.read-more):not(.strip__blurb__link):before, .video__fullscreen:before, .video__sound:before, .video__play:before,
[class*=" icon-"]:before, .splide__handle::before, .dropdown li.active:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.4em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.banner__text {
  color: var(--colBianco);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .scroll__handle, .card__title::after, .card__title, .accordion__switch::after, .banner__link::after, .menu__main a div, .menu__side a div, .menu__container li, .share-menu, .video__prog__ind, .video__play-cursor, .splide__handle, .label,
  label, input[type=checkbox] + label::before, input[type=checkbox] + label::after,
  input[type=radio] + label::before,
  input[type=radio] + label::after {
    transition: var(--aniTime, 0.3s) cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition-property: var(--aniProp, all);
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  .box-grid__title, .box-grid__image img, .side-popup__screen, .side-popup, .menu__container, .menu__screen, .button::after, .strip__blurb__link .strip__blurb__item, .strip__blurb__link .strip__blurb__link, .strip__gallery__link img, .strip--stamp::after, .strip--decoration-h::after, .strip--decoration-v::before, .scroll-top, .image-grid__text, .is-mouse .read-more::after, .is-mouse .read-more::before, .side__screen, .side__container, .strip__blurb__link .strip__blurb__image img, .strip__blurb__link .strip__blurb__item[class*=bg-custom]::before, .strip__blurb__link [class*=bg-custom].strip__blurb__link::before, .strip__blurb__link .strip__blurb__item[class*=bg-np]::before, .strip__blurb__link [class*=bg-np].strip__blurb__link::before, .animate .strip__gallery > *, .strip__slider__figure, .socials__list a::after, .border-link::after, .strip__video__container::after, .strip__video__container img {
    transition: var(--aniTime, 0.6s) cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-property: var(--aniProp, all);
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  .button {
    transition: all var(--aniTime, 0.6s) cubic-bezier(0.645, 0.045, 0.355, 1), var(--aniExclude, font-style) 1ms;
  }
}

.stroke {
  text-indent: 105%;
  white-space: nowrap;
  overflow: hidden;
  font-family: sans-serif !important;
}

.banner__slide {
  position: relative;
  overflow: hidden;
}
.banner__slide video,
.banner__slide picture,
.banner__slide img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: var(--pinX, 50%) var(--pinY, 50%);
}

.js-fixed .breadcrumbs__container, .banner__text {
  display: var(--container-display, block);
  width: 100%;
  max-width: calc(var(--container-maxWidth) + var(--container-pad) * 2 + var(--container-width-adjust, 0px));
  padding-left: var(--container-pad);
  padding-right: var(--container-pad);
  margin: 0 auto;
}

.map__content, .map-content, .cart__item__name, :where(.strip__text table) th,
:where(.strip__text table) td {
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
}

.scheduled__category, .card__title, .map__title, .banner__slogan, .banner__title, .menu__main, .image-grid__text, .text-decorated, .side-cart__item__subname, .side-cart__item__name, .cart__message__title, .cart__title, .strip__counter__values, .strip__blurb__title, .strip__counter__title, .strip__title1, .strip__title2, .strip__title3, .strip__title4, .strip__title5, legend {
  font-family: "quiche-sans", sans-serif;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: unset;
}

input[type=checkbox]:focus-visible + label:not(.mce-content-body),
input[type=radio]:focus-visible + label:not(.mce-content-body), body:not(.mce-content-body) input[type=checkbox]:focus-visible + label,
body:not(.mce-content-body) input[type=radio]:focus-visible + label {
  outline: 3px solid var(--colContrasto);
  box-shadow: 0 0 8px rgba(var(--colPrimario), 0.8);
  outline-offset: 2px;
}

.socials__list a, .border-link {
  --aniProp: transform;
  display: inline-flex;
  position: relative;
  color: inherit;
  font-weight: 400;
}
.socials__list a::after, .border-link::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  border-bottom: 2px solid var(--colContrastoScuro);
  transform: scale3d(0, 1, 1);
  transform-origin: 0 50%;
}
a:hover .socials__list a, .socials__list a:hover a, a:hover .border-link, .socials__list a:hover, .border-link:hover {
  color: inherit;
}
a:hover .socials__list a::after, .socials__list a:hover a::after, a:hover .border-link::after, .socials__list a:hover::after, .border-link:hover::after {
  transform: none;
}

.strip__video__container {
  overflow: hidden;
  position: relative;
}
.strip__video__container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #000;
  opacity: 0;
  transform: scale3d(50%, 50%, 1);
}
.is-mouse .strip__video__container:hover::after {
  opacity: 0.3;
  transform: none;
}
.is-mouse .strip__video__container:hover img {
  transform: scale3D(1.1, 1.1, 1);
}

.bld-form__row {
  display: grid;
  gap: calc(var(--vpad) / 3);
  background: var(--bgStrip, transparent);
  background-image: var(--bgImage, none);
  margin-top: calc(var(--vpad) / 3);
}
@media screen and (min-width: 37.5em) {
  .bld-form__row {
    grid-template-columns: repeat(12, minmax(1em, 1fr));
  }
  .bld-form__row--cols1 {
    --span: 12;
  }
  .bld-form__row--cols4 {
    --span: 6;
  }
  .bld-form__row--cols2 {
    --span: 6;
  }
  .bld-form__row--cols4 {
    --span: 6;
  }
  .bld-form__row--cols3 {
    --span: 4;
  }
  .bld-form__row--cols4 {
    --span: 6;
  }
}
@media screen and (min-width: 50em) {
  .bld-form__row--cols4 {
    --span: 3;
  }
}
.bld-form__row:where(:first-child) {
  margin-top: 0;
}
.bld-form__row[class*=bg-] {
  padding: 0.5em;
}
.bld-form__row--narrow {
  max-width: 56.25rem;
  margin-left: auto;
  margin-right: auto;
}
.bld-form__col {
  background: var(--bgStrip, transparent);
  background-image: var(--bgImage, none);
  color: var(--colText, inherit);
}
.bld-form__col[class*=bg-] {
  padding: 0.5em;
}
@media screen and (min-width: 37.5em) {
  .bld-form__col:nth-child(1) {
    grid-column: span calc(var(--span, 12) + var(--w1, 0) - var(--w0, 0) + var(--adj, 0));
  }
  .bld-form__col:nth-child(2) {
    grid-column: span calc(var(--span, 12) + var(--w2, 0) - var(--w1, 0) + var(--adj, 0));
  }
  .bld-form__col:nth-child(3) {
    grid-column: span calc(var(--span, 12) + var(--w3, 0) - var(--w2, 0) + var(--adj, 0));
  }
  .bld-form__col:nth-child(4) {
    grid-column: span calc(var(--span, 12) + var(--w4, 0) - var(--w3, 0) + var(--adj, 0));
  }
}
.bld-form--alternate .bld-form__group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(9.375rem, 1fr));
  column-gap: 0.5em;
}

.strip__text table {
  max-width: 100%;
  width: 100%;
  border-top: 1px solid var(--colGrigio);
}
:where(.strip__text table) tr:nth-child(2n) {
  background: var(--colGrigioLeggero);
}
:where(.strip__text table) th,
:where(.strip__text table) td {
  font-size: 1em;
  padding: 0.5em 0.7em;
  vertical-align: middle;
  border-bottom: 1px solid var(--colGrigio);
  overflow: hidden;
  text-overflow: ellipsis;
}
.strip__text table.table--data {
  border-top: none;
}
.strip__text table.table--data tr:nth-child(2n) {
  background: none;
}
.strip__text table.table--data td,
.strip__text table.table--data th {
  text-align: center;
  border-bottom: none;
}
@media screen and (max-width: 43.74em) {
  .strip__text table {
    display: block;
    position: relative;
  }
  .strip__text table::before, .strip__text table::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1em;
    background: linear-gradient(var(--dir, 90deg), rgba(0, 0, 0, 0.4) 0, transparent 100%);
  }
  .strip__text table::before {
    left: 0;
  }
  .strip__text table::after {
    --dir: -90deg;
    right: 0;
  }
  .strip__text table tbody {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .strip__text table th,
  .strip__text table td {
    min-width: 10em;
  }
}

.strip {
  background: var(--bgStrip, transparent);
  background-image: var(--bgImage, none);
  color: var(--strip-text-color, inherit);
}
:where(.strip) .strip {
  --bgStrip: unset;
  --column-bg-left: initial;
  --column-bg-right: initial;
  --strip-text-color: initial;
  --text-v-align: unset;
  text-align: initial;
}
.page__content > .strip--draft {
  display: none !important;
}
.strip__data {
  flex: 1 1 auto;
  color: var(--strip-text-color, inherit);
}
.builder .strip__data {
  min-height: 1.5rem;
}
.strip--wide {
  --container-maxWidth: 100%;
  --column-bg-left: 0;
  --column-bg-right: 0;
}
.strip--wide .container {
  padding-left: 0;
  padding-right: 0;
}
.strip--xnarrow .container, .strip--narrow .container, .strip--large .container, .strip--normal .container {
  padding-left: var(--strip-expand-paddingLeft, var(--container-pad, var(--vpad)));
  padding-right: var(--strip-expand-paddingRight, var(--container-pad, var(--vpad)));
}
.strip--large {
  --container-maxWidth: 100%;
  --strip-expand-maxWidth: 100%;
}
.strip--normal {
  --container-maxWidth: 75rem;
  --strip-expand-maxWidth: 75rem;
}
.strip--narrow {
  --container-maxWidth: 56.25rem;
  --strip-expand-maxWidth: 56.25rem;
}
.strip--xnarrow {
  --container-maxWidth: 37.5rem;
  --strip-expand-maxWidth: 37.5rem;
}
@media screen and (min-width: 50em) {
  .strip--expand-sx {
    --container-maxWidth: calc(
        (100% + var(--strip-expand-maxWidth)) / 2 - var(--container-pad, var(--vpad))
    );
  }
  .strip--expand-sx .container {
    margin-left: 0;
  }
  .strip--expand-sx.strip--image {
    --strip-expand-paddingLeft: 0;
  }
  .strip--expand-dx {
    --container-maxWidth: calc(
        (100% + var(--strip-expand-maxWidth)) / 2 - var(--container-pad, var(--vpad))
    );
  }
  .strip--expand-dx .container {
    margin-right: 0;
  }
  .strip--expand-dx.strip--image {
    --strip-expand-paddingRight: 0;
  }
}
.strip--above {
  position: relative;
  z-index: 1;
}
.strip--hidden {
  display: none;
}
.strip--full-height {
  min-height: calc(100vh - var(--header-height, 0) * 1px);
  display: flex;
}
.strip--full-height .strip__text {
  height: auto;
}
.strip .container {
  background: var(--bgCont, transparent);
}
.strip.bg-narrow {
  --bgCont: var(--bgStrip);
  --container-width-adjust: var(--container-pad, var(--vpad, 0px)) * -2;
  background: none;
  padding-left: var(--container-pad, var(--vpad));
  padding-right: var(--container-pad, var(--vpad));
}
.strip__title1, .strip__title2, .strip__title3, .strip__title4, .strip__title5 {
  font-size: var(--fontSize, 1em);
  color: var(--colTitle, inherit);
  margin-top: calc(var(--vpad) / 2);
  margin-bottom: calc(var(--vpad) / 4);
  text-wrap-style: balance;
}
.strip__pretitle + .strip__title1, .strip__title1:first-child, .strip__pretitle + .strip__title2, .strip__title2:first-child, .strip__pretitle + .strip__title3, .strip__title3:first-child, .strip__pretitle + .strip__title4, .strip__title4:first-child, .strip__pretitle + .strip__title5, .strip__title5:first-child {
  margin-top: 0;
}
.strip__title1:last-child, .strip__title2:last-child, .strip__title3:last-child, .strip__title4:last-child, .strip__title5:last-child {
  margin-bottom: 0;
}
.page__footer .strip__title1, .banner .strip__title1, .page__footer .strip__title2, .banner .strip__title2, .page__footer .strip__title3, .banner .strip__title3, .page__footer .strip__title4, .banner .strip__title4, .page__footer .strip__title5, .banner .strip__title5 {
  word-wrap: unset;
  hyphens: none;
}
@media screen and (max-width: 48em) {
  .strip__title1, .strip__title2, .strip__title3 {
    word-wrap: break-word;
    hyphens: auto;
  }
}
.strip__title1 {
  --fontSize: clamp(var(--titleSize) / 1.7, 6vw, var(--titleSize));
}
.strip__title1 i,
.strip__title1 em {
  font-style: inherit;
}
:is(.js-scroll-title, #f) .strip__title1 {
  position: relative;
  white-space: nowrap;
  min-width: 100%;
  font-weight: 500;
}
.builder :is(.js-scroll-title, #f) .strip__title1 {
  overflow: hidden;
}
:is(.js-scroll-title, #f) .strip__title1 i,
:is(.js-scroll-title, #f) .strip__title1 em,
:is(.js-scroll-title, #f) .strip__title1 strong {
  display: inline-block;
  width: fit-content;
  font-style: inherit;
  font-weight: inherit;
}
.strip__title2 {
  --fontSize: clamp(var(--titleSize) / 1.6, 5.5vw, var(--titleSize) / 1.2);
}
.strip__title3 {
  --fontSize: clamp(var(--titleSize) / 2, 3.6vw, var(--titleSize) / 1.5);
}
.strip__title4 {
  --fontSize: clamp(var(--titleSize) / 2.2, 2.4vw, var(--titleSize) / 1.8);
}
.strip__title5 {
  --fontSize: clamp(var(--titleSize) / 2.5, 2vw, var(--titleSize) / 2);
}
.strip__pretitle {
  text-transform: uppercase;
  font-size: clamp(var(--titleSize) / 2.5, 2vw, var(--titleSize) / 2);
}
.strip__subtitle {
  font-size: clamp(var(--titleSize) / 2.5, 2vw, var(--titleSize) / 2);
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0.5em;
}
.strip__subtitle:first-child {
  margin-top: 0;
}
.strip__subtitle:last-child {
  margin-bottom: 0;
}
.strip__title--splitted {
  line-height: 1rem;
}
.strip__text {
  position: relative;
  width: 100%;
  align-self: var(--text-v-align, unset);
}
.js-scroll-anime .strip__text {
  z-index: 0;
}
.v-center .strip__text {
  --text-v-align: center;
}
.v-bottom .strip__text {
  --text-v-align: flex-end;
}
.v-center-bottom > .strip__text {
  display: flex;
  flex-direction: column;
}
.v-center-bottom > .strip__text::before {
  content: "";
  display: block;
  margin-bottom: auto;
}
.v-center-top > .strip__text {
  display: flex;
  flex-direction: column;
}
.v-center-top > .strip__text::after {
  content: "";
  display: block;
  margin-top: auto;
}
.strip__text [style*=font-]:not([class*=js-]) {
  font-family: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
}
.strip__text [href^=http] {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
.strip__image {
  position: relative;
  display: grid;
  grid-template-areas: "main";
  margin-left: auto;
  margin-right: auto;
}
.js-scroll-anime .strip__image {
  z-index: -1;
}
.js-image-anime .strip__image {
  overflow: hidden;
}
.strip__image--before {
  margin-bottom: calc(var(--vpad) / 2);
}
.strip__image--before:last-child {
  margin-bottom: 0;
}
.strip__image--after {
  margin-top: calc(var(--vpad) / 2);
}
.strip__image--after:first-child {
  margin-top: 0;
}
.strip__image--before img, .strip__image--after img {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 50em) {
  .strip__image--before img, .strip__image--after img {
    margin: 0 var(--image-marginRight, auto) 0 var(--image-marginLeft, 0);
  }
}
.strip__image picture,
.strip__image .image__credits {
  grid-area: main;
}
.strip__image .image__credits {
  padding: 0.2em 0.4em;
  justify-self: flex-end;
  align-self: flex-end;
  color: #fcfcfc;
  text-shadow: 0 0 3px #0c0c0c;
}
.strip__image figcaption {
  display: block;
  position: relative;
  z-index: 1;
  padding: 0.3em;
  text-align: center;
  font-size: 0.85em;
}
.strip__image figcaption::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: var(--colNero);
  opacity: 0.03;
}
.strip--columns {
  --span: off;
}
@media screen and (max-width: 49.9em) {
  .strip--columns {
    --strip-textNarrow-paddingLeft: var(--container-pad);
    --strip-textNarrow-paddingRight: var(--container-pad);
  }
}
@media screen and (min-width: 50em) {
  .strip--columns {
    --span: 12;
    --strip-col-bgPad: -2;
  }
}
.strip--columns .container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: var(--hpad, var(--vpad));
}
@media screen and (min-width: 50em) {
  .strip--columns .container {
    --strip-column-gap: var(--hpad, var(--vpad));
    display: grid;
    grid-template-columns: repeat(24, minmax(1em, 1fr));
  }
}
.strip--3-cols {
  --span: off;
  --strip-column-fraction: 3 * 2;
}
@media screen and (max-width: 59.9em) {
  .strip--3-cols {
    --strip-textNarrow-paddingLeft: var(--container-pad);
    --strip-textNarrow-paddingRight: var(--container-pad);
  }
}
@media screen and (min-width: 50em) {
  .strip--3-cols {
    --span: off;
    --strip-col-bgPad: unset;
  }
}
@media screen and (min-width: 60em) {
  .strip--3-cols {
    --span: 8;
    --strip-col-bgPad: -2;
  }
}
@media screen and (min-width: 50em) {
  .strip--3-cols .container {
    --strip-column-gap: 0;
    grid-template-columns: none;
  }
}
@media screen and (min-width: 60em) {
  .strip--3-cols .container {
    --strip-column-gap: var(--hpad, var(--vpad));
    grid-template-columns: repeat(24, minmax(1em, 1fr));
  }
}
.strip__column {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 100%;
  margin-right: calc(var(--strip-column-gap, 0) / var(--strip-column-fraction, 2));
  margin-left: calc(var(--strip-column-gap, 0) / var(--strip-column-fraction, 2));
}
.strip__column:first-child {
  margin-left: 0;
}
.strip__column:last-child {
  margin-right: 0;
}
.strip__column.waiting .strip__image {
  transform: none;
}
.strip__column[class*=bg-] {
  position: relative;
  z-index: 1;
}
.strip__column[class*=bg-]::before {
  content: "";
  position: absolute;
}
.m-filters-open .strip__column[class*=bg-] {
  z-index: unset;
}
@media screen and (min-width: 50em) {
  .m-filters-open .strip__column[class*=bg-] {
    z-index: 1;
  }
}
.strip__column.full-height {
  min-height: calc(100vh - var(--header-height, 0) * 1px);
}
.strip__column.full-height .strip__text {
  height: auto;
}
.strip__column::before {
  z-index: -1;
  background: var(--bgStrip, transparent);
  background-image: var(--bgImage, none);
  top: 0;
  bottom: 0;
  left: calc(var(--hpad, var(--vpad)) / var(--strip-col-bgPad, -1));
  right: calc(var(--hpad, var(--vpad)) / var(--strip-col-bgPad, -1));
  pointer-events: none;
}
.strip__column.mobile-first {
  order: -1;
}
@media screen and (min-width: 50em) {
  .strip__column.mobile-first {
    order: unset;
  }
}
.strip__column__content {
  width: 100%;
  height: 100%;
  display: flex;
}
.text-narrow .strip__column__content {
  padding-left: var(--strip-textNarrow-paddingLeft, 0);
  padding-right: var(--strip-textNarrow-paddingRight, 0);
}
@media screen and (min-width: 50em) {
  .text-narrow .strip__column__content {
    max-width: calc(var(--strip-textNarrow-maxWidth, 37.5rem) + var(--vpad));
    margin-left: var(--strip-textNarrow-marginLeft, 0);
    margin-right: var(--strip-textNarrow-marginRight, 0);
  }
}
@media screen and (min-width: 50em) {
  .strip__column--overlap-up .strip__text {
    transform: translateY(calc(var(--vpad) * -1));
  }
  .strip__column--overlap-down .strip__text {
    transform: translateY(var(--vpad));
  }
  .strip__column--overlap-up-down .strip__text {
    transform: translateY(var(--vpad));
    margin-top: calc(var(--vpad) * -2);
  }
}
.strip__column--image .strip__text {
  height: 100%;
  position: relative;
  z-index: 1;
}
.strip__column--image .strip__image {
  height: 100%;
}
.strip__column--image .strip__image img {
  max-height: 40vh;
}
@media screen and (min-width: 50em) {
  .strip__column--image .strip__image img {
    max-height: none;
    width: calc(100% + var(--hpad, var(--vpad)) / 2);
  }
}
.strip__column--image img {
  max-width: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.strip__column.col-sticky .strip__column__content {
  align-items: flex-start;
}
.strip__column--1 {
  --strip-textNarrow-marginLeft: auto;
  grid-column: span calc(var(--span, 24) + var(--w1, 0) - var(--w0, 0));
}
.strip--cross-cols .strip__column--1 {
  position: relative;
  z-index: 2;
}
.strip--wide .strip__column--1 {
  --strip-textNarrow-paddingLeft: var(--container-pad);
}
.strip__column--1:not(.bg-narrow)::before {
  left: var(--column-bg-left, calc(100% - 50vw + var(--hpad, var(--vpad)) / 2));
  transform: rotate(180deg);
}
.bg-narrow .strip__column--1.strip__column--image .strip__image {
  width: calc(100% + var(--hpad, var(--vpad)) * 2);
  transform: translateX(calc(var(--hpad, var(--vpad)) * -1));
}
@media screen and (min-width: 50em) {
  .bg-narrow .strip__column--1.strip__column--image .strip__image {
    width: calc(100% + var(--hpad, var(--vpad)));
  }
}
.strip__column--2 {
  --strip-textNarrow-marginRight: auto;
  grid-column: span calc(var(--span, 24) + var(--w2, 0) - var(--w1, 0));
}
.strip--wide .strip__column--2 {
  --strip-textNarrow-paddingRight: var(--container-pad);
}
.strip--3-cols .strip__column--2 {
  --strip-column-fraction: 3;
}
.strip__column--2:last-child:not(.bg-narrow)::before {
  right: var(--column-bg-right, calc(100% - 50vw + var(--hpad, var(--vpad)) / 2));
}
.bg-narrow .strip__column--2.strip__column--image:last-child .strip__image {
  width: calc(100% + var(--hpad, var(--vpad)) * 2);
  transform: translateX(calc(var(--hpad, var(--vpad)) * -1));
}
@media screen and (min-width: 50em) {
  .bg-narrow .strip__column--2.strip__column--image:last-child .strip__image {
    width: calc(100% + var(--hpad, var(--vpad)));
    transform: none;
  }
}
.strip__column--3 {
  --strip-textNarrow-marginRight: auto;
  grid-column: span calc(var(--span, 24) + var(--w3, 0) - var(--w2, 0));
}
.strip__column--3:last-child:not(.bg-narrow)::before {
  right: calc(100% - 50vw + var(--hpad, var(--vpad)) / 2);
}
.strip--wide .strip__column--3 {
  --strip-textNarrow-paddingRight: var(--container-pad);
}
.bg-narrow .strip__column--3.strip__column--image:last-child .strip__image {
  width: calc(100% + var(--hpad, var(--vpad)) * 2);
  transform: translateX(calc(var(--hpad, var(--vpad)) * -1));
}
@media screen and (min-width: 50em) {
  .bg-narrow .strip__column--3.strip__column--image:last-child .strip__image {
    width: calc(100% + var(--hpad, var(--vpad)));
    transform: none;
  }
}
.strip--image {
  --image-text-padding: var(--vpad) var(--container-pad);
}
.strip--image .strip {
  --image-text-padding: ;
}
.strip--image .strip__data {
  display: grid;
  grid-template-columns: minmax(1em, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: "main";
  justify-items: center;
  align-items: stretch;
  position: relative;
}
@media screen and (min-width: 50em) {
  html:not(.np_a11y) .strip--image .strip__data.text-opaque .strip__image, html:not(.np_a11y) .strip--image .strip__data.text-inbox .strip__image {
    margin-bottom: 0;
    min-height: 100%;
  }
  html:not(.np_a11y) .strip--image .strip__data.text-opaque .strip__text, html:not(.np_a11y) .strip--image .strip__data.text-inbox .strip__text {
    grid-area: main;
    position: relative;
    z-index: 5;
    max-width: var(--text-max-width, none);
    padding: var(--image-text-padding);
    margin-left: var(--image-marginLeft, 0);
    margin-right: var(--image-marginRight, auto);
    display: flex;
    flex-direction: column;
  }
}
.strip--image .strip__image {
  grid-area: main;
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin-bottom: calc(var(--vpad) / 2);
  max-height: var(--strip-image-maxHeight, none);
}
.strip--image .strip__image:last-child {
  margin-bottom: 0;
}
.strip--image .strip__image--small {
  --strip-image-maxHeight: 50vh;
}
.strip--image .strip__image--big {
  --strip-image-maxHeight: 80vh;
}
html:not(.np_a11y) .strip--image .strip__image--dark .strip__image video,
html:not(.np_a11y) .strip--image .strip__image--dark .strip__image img {
  filter: brightness(0.5) contrast(1.25);
}
@media screen and (min-width: 50em) {
  html:not(.np_a11y) .strip--image .strip__image--dark .strip__text {
    --colTitle: var(--colBianco);
    --strip-text-color: var(--colBianco);
    color: var(--colBianco);
  }
}
.strip--image .strip__image--text-narrow {
  --text-max-width: 56.25rem;
}
.strip--image .strip__image--text-normal {
  --text-max-width: 75rem;
}
.strip--image .strip__image img,
.strip--image .strip__image video {
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
}
.strip--image .strip__image--real-size img {
  width: auto;
  min-height: 0;
}
.strip--image .strip__video__container {
  grid-area: main;
}
.strip--image .strip__video__container + .strip__text {
  pointer-events: none;
}
.strip--image.strip--wide .parallax .strip__image {
  height: var(--strip-image-maxHeight, 65vh);
}
.strip--image.strip--wide .strip__image {
  display: block;
  width: 100%;
}
.strip--image.strip--wide .strip__image img {
  height: 100%;
}
.strip--image.strip--wide .strip__image .image__credits {
  position: absolute;
  right: 0;
  bottom: 0;
}
.strip--image.strip--wide .strip__text {
  max-width: 56.25rem;
  padding-left: var(--container-pad);
  padding-right: var(--container-pad);
  margin-left: auto;
  margin-right: auto;
}
.strip--image.strip--wide .strip__text .container {
  padding-left: 0;
  padding-right: 0;
}
.strip--image.strip--wide .strip__text .strip__text {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}
.strip--image .parallax .strip__image {
  overflow: hidden;
}
.strip--image .parallax .strip__image img {
  height: 150%;
}
.strip--image.js-scroll-title .strip__image {
  margin-bottom: 0;
}
.strip--image.js-scroll-title .strip__text {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  grid-area: main;
  width: 100%;
  max-width: none;
  position: relative;
  z-index: 5;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.strip--image.js-scroll-reveal .strip__image {
  clip-path: inset(20% 40%);
}
.strip__slider {
  display: block;
}
.strip__slider__item {
  display: grid;
  gap: calc(var(--vpad) / 2);
  position: relative;
  margin-right: calc(var(--vpad) / 2);
  grid-template-areas: "figure" "text";
  align-items: start;
}
.strip__slider--reverse .strip__slider__item {
  grid-template-areas: "text" "figure";
}
@media screen and (min-width: 50em) {
  .strip__slider__item--cols {
    grid-template-areas: "figure text";
    grid-template-columns: minmax(1em, 1fr) minmax(1em, 1fr);
  }
  .strip__slider--reverse .strip__slider__item--cols {
    grid-template-areas: "text figure";
  }
}
.strip__slider__figure {
  grid-area: figure;
}
.strip__slider__figure figure {
  display: grid;
  grid-template-areas: "main";
}
.strip__slider__figure picture,
.strip__slider__figure .image__credits {
  grid-area: main;
}
.strip__slider__figure .image__credits {
  padding: 0.2em 0.4em;
  justify-self: flex-end;
  align-self: flex-end;
  color: #fcfcfc;
  text-shadow: 0 0 3px #0c0c0c;
}
.strip__slider__text {
  grid-area: text;
}
.strip__slider__paging {
  list-style: none;
  margin: var(--vpad) 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
.strip__slider__paging:first-child {
  margin-top: 0;
}
.strip__slider__paging:last-child {
  margin-bottom: 0;
}
.strip__slider__link {
  color: inherit;
}
.strip__slider__arrows {
  margin-top: calc(var(--vpad) / 2);
  display: flex;
}
.strip__slider__arrows a {
  font-size: 1.5em;
  margin-right: 1em;
}
.strip__slider__arrows a.off {
  opacity: 0.5;
  pointer-events: none;
}
.strip__video {
  display: block;
  width: 100%;
  height: 100%;
  visibility: hidden;
  object-fit: cover;
}
.strip__video--inline:focus-within {
  overflow: visible;
}
.strip__video--inline .strip__video {
  visibility: visible;
  object-fit: contain;
  background: var(--bgStrip, var(--colBg));
}
.active .strip__video {
  visibility: visible;
}
.strip__video__container {
  position: relative;
  display: block;
}
.strip__video__container::before {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  margin: 0;
  color: #fcfcfc;
  font-size: 2em;
  transform: translate(-50%, -50%);
}
.strip__video__container.active::after, .strip__video__container.active::before {
  display: none;
}
.strip__video__thumb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active .strip__video__thumb {
  display: none;
}
.strip__gallery {
  --col-width: 300px;
  display: grid;
  gap: calc(var(--vpad) / 3);
  grid-template-columns: repeat(auto-fill, minmax(var(--col-width), 1fr));
  text-align: center;
  position: relative;
}
:where(html:not(.np_a11y)) .strip__gallery--slider .strip__gallery, :where(html:not(.np_a11y)) .strip__gallery.splide, :where(html:not(.np_a11y)) .strip__gallery.slickslider {
  display: block;
}
.builder .strip__gallery--slider .strip__gallery, .builder .strip__gallery.splide, .builder .strip__gallery.slickslider {
  display: grid;
}
.strip__gallery__link {
  display: flex;
}
.strip__gallery picture {
  overflow: hidden;
  display: grid;
  grid-template-areas: "main";
  width: 100%;
}
.strip__gallery picture source {
  display: none;
}
.strip__gallery picture .image__credits {
  grid-area: main;
}
.strip__gallery .image__credits {
  padding: 0.2em 0.4em;
  justify-self: flex-end;
  align-self: flex-end;
  color: #fcfcfc;
  text-shadow: 0 0 3px #0c0c0c;
}
.strip__gallery img {
  grid-area: main;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.strip__gallery--video {
  display: block;
  position: relative;
  color: var(--colBianco);
}
.strip__gallery--video:hover {
  color: var(--colBianco);
}
.strip__gallery--video::after {
  margin: 0;
  position: absolute;
  font-size: 2em;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.animate .strip__gallery > *:nth-child(1n) {
  transition-delay: 0.08s;
}
.animate .strip__gallery > *:nth-child(2n) {
  transition-delay: 0.16s;
}
.animate .strip__gallery > *:nth-child(3n) {
  transition-delay: 0.24s;
}
.animate .strip__gallery > *:nth-child(4n) {
  transition-delay: 0.32s;
}
.animate .strip__gallery > *:nth-child(5n) {
  transition-delay: 0.4s;
}
.animate .strip__gallery > *:nth-child(6n) {
  transition-delay: 0.48s;
}
.animate .strip__gallery > *:nth-child(7n) {
  transition-delay: 0.56s;
}
.animate .strip__gallery > *:nth-child(8n) {
  transition-delay: 0.64s;
}
.animate .strip__gallery > *:nth-child(9n) {
  transition-delay: 0.72s;
}
.animate .strip__gallery > *:nth-child(10n) {
  transition-delay: 0.8s;
}
.animate .strip__gallery > *:nth-child(11n) {
  transition-delay: 0.88s;
}
.waiting .strip__gallery > * {
  opacity: 0;
  transform: translate3d(0, 50%, 0);
}
.strip__blurb, .strip__counter {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: var(--strip-blurb-margin, calc(var(--hpad, var(--vpad)) / 2));
}
.strip__blurb__item, .strip__blurb__link, .strip__counter__item {
  color: inherit;
  flex: 1 1 auto;
  max-width: clamp((99.9% - var(--strip-blurb-margin, var(--hpad, var(--vpad))/2) * (var(--strip-blurb-number, 4) - 1)) / var(--strip-blurb-number, 4), (36em - 100vw) * 1000, 100%);
  min-width: min(var(--container-maxWidth) / (var(--strip-blurb-number, 4) + 2), 100%);
  width: 100%;
  display: flex;
  flex-direction: var(--strip-blurb-direction, column);
}
.strip__column .strip__blurb__item, .strip__column .strip__blurb__link, .strip__column .strip__counter__item {
  margin-bottom: 0;
}
.strip__blurb__item, .strip__blurb__link, .strip__counter__item {
  counter-increment: blurbNumber;
}
.strip__blurb__item[class*=bg-custom], [class*=bg-custom].strip__blurb__link, .strip__blurb__item[class*=bg-np], [class*=bg-np].strip__blurb__link, .strip__counter__item[class*=bg-custom], .strip__counter__item[class*=bg-np] {
  flex: 0 0 auto;
  padding: 1em;
  align-items: stretch;
  background: var(--bgStrip, transparent);
  color: var(--strip-text-color, inherit);
}
.strip__blurb__item[class*=bg-custom] .strip__blurb__text, [class*=bg-custom].strip__blurb__link .strip__blurb__text, .strip__blurb__item[class*=bg-custom] .strip__counter__text, [class*=bg-custom].strip__blurb__link .strip__counter__text, .strip__blurb__item[class*=bg-np] .strip__blurb__text, [class*=bg-np].strip__blurb__link .strip__blurb__text, .strip__blurb__item[class*=bg-np] .strip__counter__text, [class*=bg-np].strip__blurb__link .strip__counter__text, .strip__counter__item[class*=bg-custom] .strip__blurb__text, .strip__counter__item[class*=bg-custom] .strip__counter__text, .strip__counter__item[class*=bg-np] .strip__blurb__text, .strip__counter__item[class*=bg-np] .strip__counter__text {
  position: relative;
}
.strip__blurb__number, .strip__counter__number {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3em;
  width: 2em;
  height: 2em;
  margin: 0 auto 0.5em;
  position: relative;
}
.strip__blurb__text, .strip__counter__text {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
}
.strip__blurb__text::before, .strip__counter__text::before {
  content: counter(blurbNumber);
  display: var(--strip-blurb-number-display, none);
  justify-content: center;
  align-items: center;
  font-size: 2em;
  padding: 0.3em;
  width: 2em;
  height: 2em;
  margin: 0 auto 0.5em;
  position: relative;
}
.strip__blurb__title, .strip__counter__title {
  margin-bottom: calc(var(--vpad) / 4);
}
.strip__blurb--n1 {
  --strip-blurb-number: 1;
}
.strip__blurb--n2 {
  --strip-blurb-number: 2;
}
.strip__blurb--n3 {
  --strip-blurb-number: 3;
}
.strip__blurb--n4 {
  --strip-blurb-number: 4;
}
.strip__blurb--n5 {
  --strip-blurb-number: 5;
}
.strip__blurb--n6 {
  --strip-blurb-number: 6;
}
.strip__blurb--n7 {
  --strip-blurb-number: 7;
}
.strip__blurb--n8 {
  --strip-blurb-number: 8;
}
.strip__blurb--imgSmall {
  --strip-blurb-image-width: 10em;
  --strip-blurb-horizontal-image-width: 15%;
}
.strip__blurb--imgWide {
  --strip-blurb-image-width: 100%;
  --strip-blurb-horizontal-image-width: 50%;
}
.strip__blurb--imgHeightSmall {
  --strip-blurb-image-height: 5em;
}
.strip__blurb--imgHeightTall {
  --strip-blurb-image-height: 10em;
}
.strip__blurb--horizontal {
  --strip-blurb-direction: row;
}
.strip__blurb--numbered {
  --strip-blurb-number-display: flex;
  counter-reset: blurbNumber;
}
.strip__blurb--text-over .strip__blurb__item, .strip__blurb--text-over .strip__blurb__link {
  display: grid;
  grid-template-areas: "main";
  align-items: stretch;
}
.strip__blurb--text-over .strip__blurb__item > *, .strip__blurb--text-over .strip__blurb__link > * {
  grid-area: main;
}
.strip__blurb__link .strip__blurb__item, .strip__blurb__link .strip__blurb__link {
  width: 100%;
  height: 100%;
  min-width: 0;
  max-width: none;
  margin: 0;
}
.strip__blurb__link .strip__blurb__item[class*=bg-custom], .strip__blurb__link [class*=bg-custom].strip__blurb__link, .strip__blurb__link .strip__blurb__item[class*=bg-np], .strip__blurb__link [class*=bg-np].strip__blurb__link {
  position: relative;
  overflow: hidden;
}
.strip__blurb__link .strip__blurb__item[class*=bg-custom]::before, .strip__blurb__link [class*=bg-custom].strip__blurb__link::before, .strip__blurb__link .strip__blurb__item[class*=bg-np]::before, .strip__blurb__link [class*=bg-np].strip__blurb__link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: var(--colNero);
  transform: translate3d(0, 50%, 0);
  opacity: 0;
}
.strip__blurb__link .strip__blurb__image {
  overflow: hidden;
}
.strip__blurb__link:hover {
  color: inherit;
}
.strip__blurb__link:hover .strip__blurb__item[class*=bg-custom], .strip__blurb__link:hover [class*=bg-custom].strip__blurb__link,
.strip__blurb__link:hover .strip__blurb__item[class*=bg-np],
.strip__blurb__link:hover [class*=bg-np].strip__blurb__link, .strip__blurb--text-over .strip__blurb__link:hover {
  color: var(--colBianco);
}
.strip__blurb__link:hover .strip__blurb__image img {
  transform: scale3d(1.1, 1.1, 1);
}
.strip__blurb--text-over .strip__blurb__link:hover .strip__blurb__image img {
  opacity: 0.3;
}
.strip__blurb__link:hover .strip__blurb__item[class*=bg-custom]::before, .strip__blurb__link:hover [class*=bg-custom].strip__blurb__link::before, .strip__blurb__link:hover .strip__blurb__item[class*=bg-np]::before, .strip__blurb__link:hover [class*=bg-np].strip__blurb__link::before {
  transform: none;
  opacity: 0.5;
}
.strip__blurb__image {
  display: grid;
  grid-template-areas: "main";
  margin: 0 auto var(--hpad, calc(var(--vpad) / 2));
  width: var(--strip-blurb-image-width, calc(40em / var(--strip-blurb-number, 4)));
  max-width: 100%;
}
.strip__blurb--horizontal .strip__blurb__image {
  margin: 0 var(--hpad, calc(var(--vpad) / 2)) 0 0;
  flex: 0 0 var(--strip-blurb-horizontal-image-width, 25%);
  min-width: calc(75rem / (var(--strip-blurb-number, 4) + 1) * 0.2);
  aspect-ratio: 1/1;
}
.strip__blurb--text-over .strip__blurb__image {
  margin: 0;
  width: 100%;
  background: var(--colNero);
}
.strip__blurb__image picture,
.strip__blurb__image .image__credits {
  grid-area: main;
}
.strip__blurb__image .image__credits {
  padding: 0.2em 0.4em;
  justify-self: flex-end;
  align-self: flex-end;
  color: #fcfcfc;
  text-shadow: 0 0 3px #0c0c0c;
}
.strip__blurb__image img {
  margin: 0 auto;
  width: 100%;
  height: var(--strip-blurb-image-height, auto);
  object-fit: contain;
}
.strip__blurb--horizontal .strip__blurb__image img {
  height: 100%;
  object-fit: contain;
  object-position: 50% 0;
}
.strip__blurb--text-over .strip__blurb__image img {
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}
.strip__blurb__image + .strip__blurb__number {
  margin-top: calc(var(--vpad) * -1.5);
}
.strip__blurb--text-over .strip__blurb__text {
  padding: 1em;
  color: var(--colBianco);
  position: relative;
}
.strip__blurb__buttons {
  padding-top: 1em;
  margin-top: auto;
}
.strip__counter__values {
  text-align: center;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 1em;
  display: grid;
  grid-template-areas: "main";
  align-items: center;
}
.strip__counter__values.pie-chart--empty {
  width: 100%;
  max-width: 9em;
  margin-left: auto;
  margin-right: auto;
}
.strip__counter__values.pie-chart--empty::before {
  content: "";
  display: block;
  grid-area: main;
  width: 100%;
  margin: auto;
  padding-bottom: calc(100% - 6px);
  border: 3px solid var(--colContrasto);
  border-radius: 50%;
}
.strip__counter__values__text {
  grid-area: main;
  position: relative;
  z-index: 1;
  padding: 0 0.5em;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.strip__counter__values__main {
  font-weight: inherit;
  font-size: 3.2em;
  margin: 0;
}
.strip__counter__values__sub {
  margin: 0;
}
.strip__counter__values canvas {
  grid-area: main;
  width: 100% !important;
  height: auto !important;
  max-width: 9em;
  margin: auto;
}
.strip__counter__label {
  text-transform: uppercase;
  line-height: 1.2;
  font-size: 0.85em;
  font-weight: 400;
}
.strip__map {
  position: relative;
  z-index: 0;
  height: 40em;
  max-height: 60vh;
  min-height: 100%;
}
.strip__map__infowin {
  padding: calc(var(--vpad) / 4);
}

@media screen and (prefers-reduced-motion: no-preference) {
  .animated:not(.strip__column):not([class*=strip__blurb]), .animated.strip__column .strip__column__content,
  .animated .strip__blurb,
  .animated .strip__counter {
    transition-duration: 1.2s;
    transition-property: opacity, transform;
  }
}
@media screen and (prefers-reduced-motion: no-preference) and (prefers-reduced-motion: no-preference) {
  .animated:not(.strip__column):not([class*=strip__blurb]), .animated.strip__column .strip__column__content,
  .animated .strip__blurb,
  .animated .strip__counter {
    transition: var(--aniTime, 0.6s) cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-property: var(--aniProp, all);
  }
}
@media screen and (prefers-reduced-motion: no-preference) and (min-width: 36em) {
  .animated.strip__column--2 .strip__column__content {
    transition-delay: 0.5s !important;
  }
  .animated.strip__column--3 .strip__column__content {
    transition-delay: 1s !important;
  }
}
@media screen and (prefers-reduced-motion: no-preference) {
  .animated:nth-child(1) {
    transition-delay: 0.1s;
  }
  .animated:nth-child(2) {
    transition-delay: 0.2s;
  }
  .animated:nth-child(3) {
    transition-delay: 0.3s;
  }
  .animated:nth-child(4) {
    transition-delay: 0.4s;
  }
  .animated:nth-child(5) {
    transition-delay: 0.5s;
  }
  .animated:nth-child(6) {
    transition-delay: 0.6s;
  }
  .animated:nth-child(7) {
    transition-delay: 0.7s;
  }
  .animated:nth-child(8) {
    transition-delay: 0.8s;
  }
  .animated:nth-child(9) {
    transition-delay: 0.9s;
  }
  .animated:nth-child(10) {
    transition-delay: 1s;
  }
  .animated:nth-child(11) {
    transition-delay: 1.1s;
  }
  .animated:nth-child(12) {
    transition-delay: 1.2s;
  }
  .animated:nth-child(13) {
    transition-delay: 1.3s;
  }
  .animated:nth-child(14) {
    transition-delay: 1.4s;
  }
  .animated:nth-child(15) {
    transition-delay: 1.5s;
  }
  .animated:nth-child(16) {
    transition-delay: 1.6s;
  }
  .animated:nth-child(17) {
    transition-delay: 1.7s;
  }
  .animated:nth-child(18) {
    transition-delay: 1.8s;
  }
  .animated:nth-child(19) {
    transition-delay: 1.9s;
  }
  .waiting {
    pointer-events: none;
  }
  .waiting.anima--fade:not(.strip__column):not([class*=strip__blurb]), .waiting.anima--fade.strip__column .strip__column__content,
  .waiting.anima--fade .strip__blurb,
  .waiting.anima--fade .strip__counter {
    opacity: 0;
  }
  .waiting.anima--left-in:not(.strip__column):not([class*=strip__blurb]), .waiting.anima--left-in.strip__column .strip__column__content,
  .waiting.anima--left-in .strip__blurb,
  .waiting.anima--left-in .strip__counter {
    opacity: 0;
    transform: translate3d(-5vh, 0, 0);
  }
  .waiting.anima--right-in:not(.strip__column):not([class*=strip__blurb]), .waiting.anima--right-in.strip__column .strip__column__content,
  .waiting.anima--right-in .strip__blurb,
  .waiting.anima--right-in .strip__counter {
    opacity: 0;
    transform: translate3d(5vh, 0, 0);
  }
  .waiting.anima--top-in:not(.strip__column):not([class*=strip__blurb]), .waiting.anima--top-in.strip__column .strip__column__content,
  .waiting.anima--top-in .strip__blurb,
  .waiting.anima--top-in .strip__counter {
    opacity: 0;
    transform: translate3d(0, -5vh, 0);
  }
  .waiting.anima--bottom-in:not(.strip__column):not([class*=strip__blurb]), .waiting.anima--bottom-in.strip__column .strip__column__content,
  .waiting.anima--bottom-in .strip__blurb,
  .waiting.anima--bottom-in .strip__counter {
    opacity: 0;
    transform: translate3d(0, 5vh, 0);
  }
}
[data-word] {
  display: inline-flex;
}

.t-s {
  display: inline-flex;
  overflow: hidden;
  padding: 0 0 0.07em;
}
.t-p {
  display: inline-flex;
  line-height: 1.1;
  transform-origin: 0 100%;
}
@media screen and (prefers-reduced-motion: reduce) {
  .t-p {
    transform: none !important;
    opacity: 1 !important;
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation: none !important;
    transition: none !important;
    scroll-behavior: auto !important;
  }
}
.video__thumb {
  position: relative;
  display: block;
}
.video__thumb::before {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  color: #fff;
  font-size: 2.5em;
  background: rgba(12, 12, 12, 0.5);
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.video__controls {
  position: absolute;
  bottom: calc(var(--vpad) / 3);
  left: 50%;
  z-index: 15;
  width: 95%;
  display: flex;
  align-items: center;
  color: #fff;
  transform: translateX(-50%);
}
.video__controls button,
.video__controls span {
  display: flex;
  font-size: inherit;
  cursor: pointer;
}
.strip__video__container:not(.active) .video__controls {
  display: none;
}
.video__play {
  padding: 0.2em;
  cursor: pointer;
}
.video__play-cursor {
  --w: 3em;
  transition-property: opacity;
  color: var(--cursorText, inherit);
  padding: 0.2em;
  position: absolute;
  z-index: 10;
  left: calc(90% - var(--w) / 2);
  top: calc(10% + var(--w) / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--w);
  height: var(--w);
  transform: translate3d(-50%, -50%, 0);
}
@media screen and (min-width: 36em) {
  .video__play-cursor {
    --w: 3em;
  }
}
.video__play-cursor::before {
  margin: 0;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 300;
  content: attr(aria-label);
}
.video__play-cursor::after {
  content: "";
  position: absolute;
  top: calc(var(--w) * -1);
  left: calc(var(--w) * -1);
  right: calc(var(--w) * -1);
  bottom: calc(var(--w) * -1);
  z-index: -1;
  border: 1px solid var(--colContrasto);
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  opacity: 0.9;
}
.is-touch .video__play-cursor.playing {
  opacity: 0;
}
.video__play-cursor.off-controls, .video__play-cursor.off {
  opacity: 0;
  visibility: hidden;
}
.video__sound {
  padding: 0.2em;
  cursor: pointer;
}
.video__sound:first-child {
  margin-left: auto;
}
.video__prog {
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  margin: 0 calc(var(--vpad) / 2);
  padding-bottom: 1em;
}
.video__prog::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  border-top: 1px solid;
  opacity: 0.5;
}
.video__prog__ind {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 3px;
  margin-top: -1px;
  background: #fff;
  transform-origin: 0 50%;
  transform: scale3d(0, 1, 1);
}
.video__fullscreen {
  padding: 0.2em;
  cursor: pointer;
}
.video__fullscreen:first-child {
  margin-left: auto;
}
.video__time {
  font-size: 0.75em;
  min-width: 7em;
  text-align: right;
  margin: 0 0.5em;
}

html:not(.np_a11y) [data-video-controls~=play_cursor] {
  cursor: none;
}

.account__info {
  padding: calc(var(--vpad) / 3);
  background: var(--colGrigioChiaro);
  border-radius: 0.4em;
  margin-bottom: var(--vpad);
}
.account__data {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: calc(var(--vpad) / 3);
  justify-content: start;
  align-items: center;
  margin: calc(var(--vpad) / 3) 0;
}
.account__data:first-child {
  margin-top: 0;
}
.account__data:last-child {
  margin-bottom: 0;
}
.account__data dt {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.85em;
}
.account__exit {
  margin-top: var(--vpad);
}

.box-address__add {
  margin-left: auto;
}
.box-address__item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: stretch;
  border: 1px solid var(--colGrigio);
  padding: calc(var(--vpad) / 3);
  margin-bottom: calc(var(--vpad) / 2);
  border-radius: 0.4em;
}
.box-address:last-child {
  margin-bottom: 0;
}
.box-address__buttons {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  font-size: 0.8em;
  margin-left: calc(var(--vpad) / 2);
}
.box-address__buttons .button {
  margin-bottom: 1em;
  margin-right: 0;
}
.box-address__alert {
  max-width: 16em;
  margin-top: calc(var(--vpad) / 2);
  margin-bottom: calc(var(--vpad) / 2);
  background: var(--colContrastoChiaro);
  padding: 0.5em;
}
.box-address__alert:last-child {
  margin-bottom: 0;
}

.cart {
  margin: calc(var(--vpad) / 2) 0;
}
.cart:last-child {
  margin-bottom: 0;
}
.cart__title {
  color: var(--colPrimario);
  margin-bottom: calc(var(--vpad) / 3);
}
.cart__title a {
  color: inherit;
}
.cart__title a:hover {
  color: var(--colContrasto);
}
.cart__section {
  margin-bottom: calc(var(--vpad) / 2);
  background: var(--colGrigioLeggero);
  border-radius: 0.4em;
  padding: calc(var(--vpad) / 3);
}
.cart__section__footer {
  margin: calc(var(--vpad) / 3) calc(var(--vpad) / -3) calc(var(--vpad) / -3);
  padding: calc(var(--vpad) / 3);
  background: var(--colGrigioChiaro);
  border-radius: 0 0 0.4em 0.4em;
}
@media screen and (max-width: 43.75em) {
  .cart__section__footer {
    font-size: 0.85em;
  }
}
.cart__item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  border-top: 1px solid var(--colPrimarioChiaro);
}
@media screen and (max-width: 65.5em) {
  .cart__item {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 43.75em) {
  .cart__item {
    font-size: 0.85em;
  }
}
.cart__title + .cart__item, .cart__item:first-child {
  border-top: none;
}
.cart__item__image {
  width: 12em;
  max-width: 33%;
  padding: 1em 1em 1em 0;
}
.cart__item__image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.cart__item__description {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  padding: 1em 0;
}
@media screen and (max-width: 65.5em) {
  .cart__item__description {
    width: 66%;
  }
}
.cart__item__name {
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
}
.cart__item__name a {
  color: var(--colText);
}
.cart__item__name a:hover {
  color: var(--colContrasto);
}
.cart__item__price {
  margin-top: 1em;
  font-weight: 600;
}
@media screen and (max-width: 65.5em) {
  .cart__item__price {
    margin-top: 0;
  }
}
.cart__item__qty {
  --inputH: 3em;
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: space-between;
  text-align: right;
  padding: 1em 0 1em 1em;
}
@media screen and (max-width: 65.5em) {
  .cart__item__qty {
    --inputH: 2em;
    width: 100%;
    flex-flow: row;
    padding: 0 0 1em 1em;
  }
}
.cart__item__qty .input--number {
  font-size: 0.8em;
}
.cart__item__tools {
  margin-top: auto;
  padding-top: calc(var(--vpad) / 6);
}
.cart__item__tools .button,
.cart__item__tools button {
  font-size: 0.8em;
  margin-right: 1em;
  text-transform: uppercase;
  cursor: pointer;
  text-align: left;
}
.cart__item__note {
  font-size: 0.8em;
  color: var(--colGrigioScuro);
}
.cart__message__title {
  font-size: 0.8em;
  margin-bottom: 1em;
}
.cart__delivery {
  --inputH: 2.5em;
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px dotted var(--colGrigio);
  display: flex;
  align-items: center;
}
@media screen and (max-width: 65.5em) {
  .cart__delivery {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 43.75em) {
  .cart__delivery {
    display: block;
  }
}
.cart__delivery label {
  margin-bottom: 0;
}
.cart__delivery__title {
  margin-right: 1em;
}
@media screen and (max-width: 43.75em) {
  .cart__delivery__title {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5em;
  }
}
.cart__delivery__day {
  margin-right: 1em;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 65.5em) {
  .cart__delivery__day {
    margin-right: 0;
  }
}
.cart__delivery__day > * {
  margin-right: calc(var(--vpad) / 3);
}
@media screen and (max-width: 43.75em) {
  .cart__delivery__day > * {
    margin-right: 0;
  }
}
@media screen and (max-width: 65.5em) {
  .cart__delivery__time {
    margin-top: 0.5em;
  }
}
@media screen and (max-width: 43.75em) {
  .cart__delivery__time {
    margin-top: 1em;
  }
}
.cart__column {
  font-size: 0.9em;
}
@media screen and (max-width: 43.75em) {
  .cart__column {
    margin-top: var(--vpad);
  }
}
.cart__town {
  margin-top: 1em;
  font-size: 0.9em;
}
.cart__town p {
  padding: 0.5em;
  margin-bottom: 0 !important;
  background: var(--colGrigioLeggero);
}
.cart__town p + p {
  padding-top: 0;
}
.cart__town p:first-child {
  border-radius: 0.4em 0.4em 0 0;
}
.cart__town p:last-child {
  border-radius: 0 0 0.4em 0.4em;
}
.cart__town strong {
  display: block;
}
.cart__promo, .cart__subtotal, .cart__shipping, .cart__total {
  margin-bottom: calc(var(--vpad) / 4);
}
.cart__promo p, .cart__subtotal p, .cart__shipping p, .cart__total p {
  display: flex;
  justify-content: space-between;
  line-height: 1.3;
  margin-bottom: calc(var(--vpad) / 4);
}
.cart__shipping {
  margin-top: calc(var(--vpad) / 2);
}
.cart__shipping strong {
  text-transform: uppercase;
  font-size: 0.9em;
}
.cart__promo p {
  align-items: flex-end;
}
.cart__promo strong {
  font-size: 0.85em;
  text-transform: uppercase;
}
.cart__promo__label {
  margin-right: auto;
}
.cart__promo__del {
  align-self: flex-start;
  padding: 0.1em;
}
.cart__total {
  font-size: 1.2em;
  font-weight: 700;
  border-top: 1px solid var(--colGrigio);
  margin-top: calc(var(--vpad) / 2);
  padding-top: 0.5em;
}
.cart__value {
  min-width: 45%;
  margin-right: 0.5em;
  text-align: right;
}
.cart__promo-text {
  font-size: 0.85em;
  padding-top: calc(var(--vpad) / 3);
}
.cart__promo-text p {
  margin-bottom: 0.5em;
}

.checkout__item {
  margin-bottom: calc(var(--vpad) / 4);
  padding-bottom: calc(var(--vpad) / 4);
  border-bottom: 1px dotted var(--colGrigio);
}
.checkout__item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.checkout__item .strip__title3 {
  font-size: 0.85em;
}
.checkout__item .button {
  font-size: 0.7em;
}
.checkout__sblock {
  display: inline-block;
  margin-right: 0.5em;
}
.checkout__alert {
  display: block;
  font-size: 0.9rem;
  color: var(--colText);
  background: var(--colContrastoChiaro);
  padding: 0.3em 0.7em;
  margin: calc(var(--vpad) / 2) 0;
  border-radius: 0.4em;
}

.side__container {
  display: block;
  background: var(--colBianco);
  padding: calc(var(--vpad) / 2);
}
@media screen and (min-width: 50em) {
  .side__container {
    padding: calc(var(--vpad) / 2) var(--vpad);
  }
}
.side-open .side__container {
  transform: none;
}
.side__content {
  transition: opacity 0.3s ease-in-out;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}
.side__content.loading {
  opacity: 0;
}
.side__close {
  position: absolute;
  font-size: 0.75em;
  text-transform: uppercase;
  right: calc(var(--vpad) / 4);
  top: calc(var(--vpad) / 2);
}
@media screen and (min-width: 50em) {
  .side__close {
    right: calc(var(--vpad) / 2);
  }
}
.side__close span {
  color: var(--colNero);
}
.side__footer {
  margin-top: auto;
}
.side__screen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
}
.loading .side__screen {
  visibility: visible;
  opacity: 1;
}
.side__screen .spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}
.side__screen .spinner > div {
  width: 18px;
  height: 18px;
  background-color: var(--colContrasto);
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.side__screen .spinner .bounce1 {
  animation-delay: -0.32s;
}
.side__screen .spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.side-cart__items {
  overflow: auto;
  margin: calc(var(--vpad) / 2) 0;
  padding-right: 1em;
}
.side-cart__item {
  display: grid;
  margin-top: 1.5em;
}
@media screen and (min-width: 50em) {
  .side-cart__item {
    grid-template-columns: max(8em, 25%) 1fr;
  }
}
.cart__title + .side-cart__item, .side-cart__item:first-child {
  margin-top: 0;
}
@media screen and (min-width: 50em) {
  .side-cart__item__image {
    padding-right: 1em;
  }
}
.side-cart__item__image img {
  max-width: 100%;
  object-fit: contain;
  width: 12em;
  margin: 0 auto;
}
@media screen and (min-width: 50em) {
  .side-cart__item__image img {
    width: auto;
    margin: 0;
  }
}
.side-cart__item__description p {
  margin-bottom: 0;
}
.side-cart__item__dett {
  font-size: 0.8em;
  margin-top: 0.75em;
  color: var(--colNero);
}
.side-cart__item__dett span {
  color: var(--colPrimario);
  margin-right: 0.2em;
}
.side-cart__item__name {
  font-size: 1.6em;
  text-transform: uppercase;
  color: var(--colTitle);
  margin-bottom: 0.5em;
}
.side-cart__item__subname {
  color: var(--colTitle, inherit);
  font-size: 1.2em;
}
.side-cart__item__qty {
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  border: 2px solid #0c0c0c;
}
.side-cart__item__qty p {
  width: 100%;
  text-align: center;
  color: var(--colNero);
}
.side-cart__item__price {
  margin-top: 0.25em;
  text-align: right;
}
@media screen and (min-width: 50em) {
  .side-cart__item__price {
    text-align: unset;
  }
}
.side-cart__item__price .product__price {
  margin: 0;
  font-size: 1.5em;
}
.side-cart__item__tools {
  --inputH: 2.2em;
  display: flex;
  flex-flow: column nowrap;
  padding-left: calc(var(--vpad) / 4);
  font-size: 0.9em;
  align-items: flex-end;
}
.side-cart__buttons {
  margin-top: 0.5rem;
}
.side-cart__buttons [class*=icon-]::before {
  margin-right: 0;
  font-size: 1em;
  color: var(--colPrimario);
  vertical-align: middle;
}
.side-cart__buttons span {
  text-transform: uppercase;
  font-size: 0.75em;
}
.side-cart__promo p, .side-cart__subtotal p, .side-cart__total p {
  display: flex;
  justify-content: space-between;
  line-height: 1.3;
  margin-bottom: 0.5rem;
}
.side-cart__promo p:nth-child(2), .side-cart__subtotal p:nth-child(2), .side-cart__total p:nth-child(2) {
  margin-bottom: 0;
}
.side-cart__promo p {
  align-items: flex-end;
}
.side-cart__promo strong {
  font-size: 0.85em;
  text-transform: uppercase;
}
.side-cart__promo__label {
  margin-right: auto;
}
.side-cart__promo__del {
  align-self: flex-start;
  padding: 0.1em;
}
.side-cart__total {
  text-transform: uppercase;
  border-top: 2px solid var(--colNero);
  border-bottom: 2px solid var(--colNero);
  margin: 1rem 0;
  padding: 1rem 0;
  font-size: 0.85em;
}
@media screen and (min-width: 50em) {
  .side-cart__total {
    font-size: 1em;
  }
}
.side-cart__value {
  min-width: 45%;
  margin-right: 0.5em;
  text-align: right;
}
.side-cart__promo-text {
  font-size: 0.85em;
  padding-top: 1rem;
}
.side-cart__promo-text p {
  margin-bottom: 0.5em;
}
.side-cart__recap .buttons {
  display: grid;
  gap: 1rem;
  justify-content: center;
}
.side-cart__recap .buttons a {
  font-size: 1em;
}
.side-cart__recap .buttons a:not(.button) {
  color: var(--colNero);
}

.share-div__grid {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  gap: 1.5em;
  margin-top: 1em;
}
.share-div .share__label {
  @apply sr-only;
}
.share-div .share__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  color: var(--colBianco);
  background: var(--colPrimario);
}
.share-div .share__link::before {
  margin: 0;
}
.share-win {
  width: 30em;
  max-width: 100%;
  text-align: center;
}
.share-win__logo {
  margin-bottom: 1em;
}
.share-win__logo span {
  display: block;
  background: transparent url("/img/caffe-paszkowski.svg") 50% 50% no-repeat;
  background-size: contain;
  height: 60px;
}
.share-win__grid {
  list-style: none;
  margin: calc(var(--vpad) / 2) 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
  gap: calc(var(--vpad) / 4);
}
.share-menu {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 100%;
  min-width: 16em;
  padding: 0.5em;
  background: var(--colBg);
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
.share-menu.active {
  visibility: visible;
  opacity: 1;
}
.share-menu__container {
  position: relative;
}
.share-menu__list {
  list-style: none;
  padding: 0;
  display: grid;
  gap: 0.5em;
}
.share-menu__list a {
  width: 100%;
  color: var(--colText);
  border: 1px solid var(--colGrigio);
  border-radius: 0.4em;
  display: block;
  padding: 0.3em;
}
.share-menu__list a::before {
  color: var(--colPrimario);
}

.read-more {
  display: var(--display, inline-grid);
  align-items: center;
  justify-items: center;
  color: var(--colRead-more, var(--strip-text-color, var(--colNero)));
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.95em;
  cursor: pointer;
}
.is-mouse .read-more {
  grid-template-columns: 1fr auto;
  grid-template-areas: "text icon";
  gap: 0.5em;
}
.is-mouse .read-more::before {
  content: "";
  grid-area: icon;
  width: 2em;
  height: 2em;
  background: currentColor;
  opacity: 0;
  border-radius: 50%;
}
.is-mouse .read-more::after {
  color: var(--colBianco);
  grid-area: icon;
  display: flex;
  align-items: center;
  margin: 0;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  mix-blend-mode: difference;
}
a:hover .read-more, .read-more:hover {
  color: var(--colRead-more, var(--strip-text-color, var(--colNero)));
}
.is-mouse a:hover .read-more::before, .is-mouse .read-more:hover::before {
  opacity: 1;
}
.is-mouse a:hover .read-more::after, .is-mouse .read-more:hover::after {
  opacity: 1;
  transform: none;
}

.panel-scroll {
  border: none;
  display: block;
}
.panel-scroll tr,
.panel-scroll tr:nth-child(2n) {
  display: block;
  background: var(--bgStrip, var(--colBg));
  border-top: 1px solid;
  padding: var(--vpad) 0;
}
.panel-scroll th,
.panel-scroll td {
  display: block;
  padding: 0;
  overflow: visible;
  border: none;
}

.stroke {
  display: inline-block;
  vertical-align: middle;
  height: 1px;
  width: var(--strokeWidth, calc(var(--vpad) / 4));
  border-top: 1px solid;
}

.text-left {
  --image-marginLeft: 0;
  --strip-textNarrow-marginLeft: 0;
  --strip-textNarrow-marginRight: auto;
}
.text-center {
  --image-marginLeft: auto;
  --strip-textNarrow-marginLeft: auto;
  --strip-textNarrow-marginRight: auto;
}
.text-right {
  --image-marginLeft: auto;
  --image-marginRight: 0;
  --strip-textNarrow-marginLeft: auto;
  --strip-textNarrow-marginRight: 0;
}
.text-justify {
  --image-marginLeft: 0;
}
.text-small {
  font-size: 0.85em;
}
.text-big {
  font-size: 1.2em;
}
.text-cite {
  font-family: "quiche-sans", sans-serif;
  font-size: 1.4em;
  font-style: italic;
  color: var(--colTitle, var(--strip-text-color, var(--colPrimario)));
  margin-top: calc(var(--vpad) / 3);
  margin-bottom: calc(var(--vpad) / 3);
}
@media screen and (min-width: 36em) {
  .text-cite {
    font-size: 2em;
  }
}
.text-cite:first-child {
  margin-top: 0;
}
.text-cite:last-child {
  margin-bottom: 0;
}
.text-decorated {
  font-size: clamp(var(--titleSize) / 1.6, 5.5vw, var(--titleSize) / 1.2);
  line-height: 1.1;
}
.text-decorated:first-child {
  margin-top: 0;
}
.text-decorated:last-child {
  margin-bottom: 0;
}
.text-decorated + [class*=strip__title] {
  margin-top: 0;
}

@media screen and (min-width: 50em) {
  .md\:text-left {
    --image-marginLeft: 0;
    --strip-textNarrow-marginLeft: 0;
    --strip-textNarrow-marginRight: auto;
  }
  .md\:text-center {
    --image-marginLeft: auto;
    --strip-textNarrow-marginLeft: auto;
    --strip-textNarrow-marginRight: auto;
  }
  .md\:text-right {
    --image-marginLeft: auto;
    --image-marginRight: 0;
    --strip-textNarrow-marginLeft: auto;
    --strip-textNarrow-marginRight: 0;
  }
  .md\:text-justify {
    --image-marginLeft: 0;
  }
}
@media screen and (min-width: 60em) {
  .lg\:text-left {
    --image-marginLeft: 0;
    --strip-textNarrow-marginLeft: 0;
    --strip-textNarrow-marginRight: auto;
  }
  .lg\:text-center {
    --image-marginLeft: auto;
    --strip-textNarrow-marginLeft: auto;
    --strip-textNarrow-marginRight: auto;
  }
  .lg\:text-right {
    --image-marginLeft: auto;
    --image-marginRight: 0;
    --strip-textNarrow-marginLeft: auto;
    --strip-textNarrow-marginRight: 0;
  }
  .lg\:text-justify {
    --image-marginLeft: 0;
  }
}
.two-columns:not(.strip__data), .two-columns .strip__text {
  columns: 18.75rem 2;
  column-gap: var(--hpad, var(--vpad));
}
.two-columns:not(.strip__data) [class*=__title], .two-columns .strip__text [class*=__title] {
  column-span: all;
}
.two-columns:not(.strip__data) li, .two-columns .strip__text li {
  break-inside: avoid;
}

.three-columns:not(.strip__data), .three-columns .strip__text {
  columns: 14.0625rem 3;
  column-gap: var(--hpad, var(--vpad));
}
.three-columns:not(.strip__data) [class*=__title], .three-columns .strip__text [class*=__title] {
  column-span: all;
}
.three-columns:not(.strip__data) li, .three-columns .strip__text li {
  break-inside: avoid;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: var(--vpad);
  grid-auto-flow: dense;
}
.image-grid__item {
  display: block;
  position: relative;
}
.is-mouse .image-grid__item:hover .image-grid__text {
  opacity: 1;
}
.image-grid__item.wide {
  grid-column-end: span 2;
}
.image-grid__item.tall {
  grid-row-end: span 2;
}
.image-grid__item img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.image-grid__text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  color: var(--colTitle, inherit);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: max(var(--titleSize) / 2, 2em);
  text-align: center;
  opacity: 0;
  pointer-events: none;
}
.image-grid__text::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: var(--igBg, #fff);
  opacity: 0.7;
}

.socials__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
.socials__list li {
  margin: 0.75em 0 0.75em 1.5em;
}
.link-list {
  line-height: initial;
  list-style: none;
  padding: 0;
}
.link-list li {
  position: relative;
  padding-left: 1.5em;
  margin-bottom: calc(var(--vpad) / 2);
}
.link-list li::before {
  position: absolute;
  left: 0;
  top: 0.1em;
  color: var(--colContrasto);
}
.link-list a {
  display: block;
  color: inherit;
}
.link-list a:hover {
  color: var(--colContrasto);
}

.bg-np {
  background: var(--bgStrip, transparent);
  background-image: var(--bgImage, none);
  color: var(--strip-text-color, inherit);
}
@media screen and (min-width: 50em) {
  .bg-half-white {
    --bgImage: linear-gradient(-90deg, #fff 25%, transparent 0);
  }
  .bg-half-black {
    --bgImage: linear-gradient(
        -90deg,
        var(--colNero) 25%,
        transparent 0
    );
  }
  .bg-half-black .strip__text_ > *:not(.strip__image) {
    filter: Invert(100%);
    mix-blend-mode: difference;
  }
  .bg-half-grey {
    --bgImage: linear-gradient(
        -90deg,
        var(--colGrigioChiaro) 25%,
        transparent 0
    );
  }
}
.bg-np-black {
  --bgStrip: var(--colNero);
  --colDots: var(--colBianco);
  --strip-text-color: var(--colBianco);
  --colTitle: "";
}
.bg-np-black a:not(.button) {
  color: inherit;
}
.bg-np-black a:not(.button):hover {
  color: var(--colContrasto);
}
.bg-np-white {
  --bgStrip: var(--colBianco);
  --strip-text-color: var(--strip-text-color);
  color: var(--strip-text-color);
}
.bg-np-white a:not(.button, .read-more) {
  color: var(--colLink);
}
.bg-np-white a:not(.button, .read-more):hover {
  color: var(--colLinkHover);
}
.bg-np-grey {
  --bgStrip: var(--colGrigioLeggero);
}
.bg-grad-white {
  --bgImage: linear-gradient(180deg, #fff 0, transparent 100%);
}
.bg-grad-black {
  --bgImage: linear-gradient(180deg, var(--colNero) 0, transparent 100%);
}
.bg-grad-black .strip__text_ > *:not(.strip__image) {
  filter: Invert(100%);
  mix-blend-mode: difference;
}
.bg-grad-grey {
  --bgImage: linear-gradient(
      180deg,
      var(--colGrigioChiaro) 0,
      transparent 100%
  );
}

@media screen and (min-width: 50em) {
  .bg-half-custom-1 {
    --bgImage: linear-gradient(
        -90deg,
        var(--colPrimarioLeggero) 25%,
        transparent 0
    );
  }
  .bg-half-custom-2 {
    --bgImage: linear-gradient(
        -90deg,
        var(--colContrastoLeggero) 25%,
        transparent 0
    );
  }
  .bg-half-custom-3 {
    --bgImage: linear-gradient(
        -90deg,
        var(--colContrasto) 25%,
        transparent 0
    );
  }
  .bg-half-custom-4 {
    --bgImage: linear-gradient(
        -90deg,
        var(--colPrimarioChiaro) 25%,
        transparent 0
    );
  }
  .bg-half-dark {
    --bgImage: linear-gradient(
        -90deg,
        var(--colPrimario) 25%,
        transparent 0
    );
  }
}
.bg-custom-1 {
  --bgStrip: var(--colPrimarioLeggero);
}
.bg-custom-2 {
  --bgStrip: var(--colContrastoLeggero);
}
.bg-custom-3 {
  --bgStrip: var(--colContrasto);
}
.bg-custom-3 :where(a:not(.button):not(.read-more)) {
  color: inherit;
}
.bg-custom-3 :where(a:not(.button):not(.read-more)):hover {
  color: var(--colContrastoChiaro);
}
.bg-custom-4 {
  --bgStrip: var(--colPrimarioChiaro);
}
.bg-np-dark {
  --bgStrip: var(--colPrimario);
  --colDots: var(--colBianco);
  --colBordoBottoni: var(--colBianco);
  --strip-text-color: var(--colBianco);
  --colTitle: "";
}
.bg-np-dark :where(a:not(.button):not(.read-more)) {
  color: inherit;
}
.bg-np-dark :where(a:not(.button):not(.read-more)):hover {
  color: var(--colContrastoChiaro);
}
.bg-grad-custom-1 {
  --bgImage: linear-gradient(180deg, var(--colPrimarioLeggero) 0, transparent 100%);
}
.bg-grad-custom-2 {
  --bgImage: linear-gradient(180deg, var(--colContrastoLeggero) 0, transparent 100%);
}
.bg-grad-custom-3 {
  --bgImage: linear-gradient(180deg, var(--colContrasto) 0, transparent 100%);
}
.bg-grad-custom-3 :where(a:not(.button):not(.read-more)) {
  color: inherit;
}
.bg-grad-custom-3 :where(a:not(.button):not(.read-more)):hover {
  color: var(--colContrastoChiaro);
}
.bg-grad-custom-4 {
  --bgImage: linear-gradient(180deg, var(--colPrimarioChiaro) 0, transparent 100%);
  --colDots: var(--colBianco);
  --colBordoBottoni: var(--colBianco);
  --strip-text-color: var(--colBianco);
}
.bg-grad-custom-4 :where(a:not(.button):not(.read-more)) {
  color: inherit;
}
.bg-grad-custom-4 :where(a:not(.button):not(.read-more)):hover {
  color: var(--colContrastoChiaro);
}
.bg-grad-dark {
  --bgImage: linear-gradient(180deg, var(--colPrimario) 0, transparent 100%);
  --colDots: var(--colBianco);
  --colBordoBottoni: var(--colBianco);
  --strip-text-color: var(--colBianco);
  --colTitle: "";
}
.bg-grad-dark :where(a:not(.button):not(.read-more)) {
  color: inherit;
}
.bg-grad-dark :where(a:not(.button):not(.read-more)):hover {
  color: var(--colContrastoChiaro);
}

.np-cursor.slick-cursor-dark {
  --cursorBg: #fff;
  --cursorBorder: var(--colText);
  --cursorText: var(--colText);
}
.slick-cursor-left .np-cursor__text::before, .cursor-left .np-cursor__text::before {
  margin: 0;
  font-size: var(--iconSize, 2em);
}
.slick-cursor-right .np-cursor__text::before, .cursor-right .np-cursor__text::before {
  margin: 0;
  font-size: var(--iconSize, 2em);
}
.cursor-up .np-cursor__text::before {
  margin: 0;
  font-size: var(--iconSize, 2em);
}
.cursor-down .np-cursor__text::before {
  margin: 0;
  font-size: var(--iconSize, 2em);
}

.buttons {
  margin-top: calc(var(--vpad) / 2);
}

.page__content, .page__footer {
  opacity: 1;
}

.page--nobanner .page__content {
  padding-top: calc(var(--header-height) / 16 * 1em / var(--fontSize));
  flex: 1 1 auto;
}
.page__content--fixed-row .page__content {
  position: relative;
  display: grid;
  grid-template-columns: minmax(10vw, auto) minmax(50vw, 1fr);
}
.page__content--fixed-row .page__content .strip {
  grid-column: 2/2;
}
.page__content--fixed-row .page__content .strip .container {
  margin-left: 0;
}
.page__content--fixed-row .page__content .strip--fixed-row {
  --hpad: calc(var(--vpad) / 2);
  grid-column: 1/1;
  grid-row: 1/50;
  padding-top: calc(var(--header-height) * 1px);
  width: 33vw;
}
.page__content--fixed-row .page__content .strip--fixed-row.strip--narrow {
  width: 12em;
}
@media screen and (max-width: 43.75em) {
  .page__content--fixed-row .page__content .strip--fixed-row.strip--narrow {
    width: 9em;
  }
}
.page__content--fixed-row .page__content .strip--fixed-row.strip--narrow ~ .strip .container {
  margin-left: auto;
}
.page__content--fixed-row .page__content .strip--fixed-row .strip__data {
  height: 100%;
}
.page__content--fixed-row .page__content .strip--fixed-row .container {
  height: 100%;
  max-width: 18.75rem;
  margin-right: 0;
  margin-left: auto;
}
.page__content--fixed-row .page__content > .strip:first-child {
  padding-top: calc(var(--header-height) / 16 * 1em);
}
@media screen and (max-width: 43.75em) {
  .page__content--fixed-row[data-fixed-stop] .page__content {
    display: block;
  }
  .page__content--fixed-row[data-fixed-stop] .page__content .strip--fixed-row {
    width: auto !important;
  }
  .page__content--fixed-row[data-fixed-stop] .page__content .strip--fixed-row .container {
    margin-left: auto;
    margin-right: auto;
  }
  .page__content--fixed-row[data-fixed-stop] .page__content .strip .container {
    margin-left: auto;
  }
}

.banner {
  --colDots: #fff;
  position: relative;
  overflow: hidden;
}
.banner.TR {
  --pinX: 100%;
  --pinY: 0;
}
.banner.BR {
  --pinX: 100%;
  --pinY: 100%;
}
.banner.BL {
  --pinX: 0;
  --pinY: 100%;
}
.banner.TL {
  --pinX: 0;
  --pinY: 0;
}
.banner .slick-dots {
  position: absolute;
  z-index: 10;
  bottom: calc(var(--vpad) / 2);
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
}
.banner .slick-dots a {
  position: static;
  margin: 0 0.5em;
}
.banner__slider, .banner__slide {
  height: 60vh;
}
.home .banner__slider, .home .banner__slide {
  height: calc(60vh + var(--header-height) * 1px);
}
.banner__slider {
  z-index: 1;
  padding: 0;
  overflow: hidden;
}
.banner__slider__container {
  overflow: hidden;
}
.banner__slide {
  width: 100%;
  display: block !important;
}
.banner__video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.banner__text {
  position: absolute;
  z-index: 5;
  top: 50%;
  right: 0;
  left: 0;
  transform: translate3D(0, -50%, 0);
  text-align: center;
}
.banner__buttons {
  margin-top: calc(var(--vpad) / 2);
}

.accordion__switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.accordion__panel {
  display: none;
}
.no-js .accordion__panel {
  display: block;
}
.accordion__panel.open {
  display: block;
}

.toggle__switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  border-bottom: 1px solid;
  cursor: pointer;
}
.toggle__switch::after {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 calc(var(--vpad) / 4);
  font-size: 1rem;
}
.toggle__panel {
  display: none;
}
.no-js .toggle__panel {
  display: block;
}

.np-logo {
  max-width: 400px !important;
}

[data-image-link] {
  position: relative;
  display: inline-block;
  cursor: none;
  color: inherit;
}

.image__link {
  padding: 0.2em;
  position: absolute;
  z-index: 20;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  pointer-events: none;
  color: var(--colText);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
  width: 2em;
  height: 2em;
}
.image__link::before {
  margin: 0;
}
.image__link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  border: 1px solid var(--colContrasto);
  border-radius: 50%;
}
.image__link--float {
  pointer-events: none;
  position: fixed;
}
.image__link.hidden {
  display: none;
}
.image__credits {
  position: relative;
  z-index: 10;
  font-size: 0.8rem;
  text-align: right;
}

[data-drag-scroll] {
  cursor: grab;
  user-select: none;
}
[data-drag-scroll].is-dragging {
  cursor: grabbing;
}

.map-content {
  color: var(--colText);
  font-size: 1rem;
  text-align: center;
  min-width: 16em;
  padding: 0.2em;
}
.map__image {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 0.7em;
}

.gm-style .gm-style-iw {
  border-radius: 8px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}
.gm-style .gm-style-iw button {
  width: auto !important;
  height: auto !important;
  top: 3px !important;
  right: 3px !important;
}
.gm-style .gm-style-iw button::before {
  margin: 0;
  font-size: 1rem;
  color: var(--colNero);
}
.gm-style .gm-style-iw button span,
.gm-style .gm-style-iw button img {
  display: none !important;
}

.page__footer {
  opacity: 1;
  flex: 0 0 auto;
  margin-top: auto;
}

.scroll-top {
  --aniProp: opacity, visibility, background;
  display: block;
  position: fixed;
  z-index: 20;
  bottom: 1rem;
  right: 0.5rem;
  opacity: 0;
  visibility: hidden;
  font-size: 0.8em;
}
@media screen and (min-width: 36em) {
  .scroll-top {
    right: var(--vpad);
    font-size: 1em;
  }
}
.opaque .scroll-top {
  opacity: 1;
  visibility: visible;
}
.m-open .opaque .scroll-top {
  opacity: 1;
  visibility: hidden;
}
.scroll-top a {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  padding: 0.5em;
  font-size: 1em;
  line-height: 1.1;
  opacity: 0.5;
}
.scroll-top a::before {
  font-size: 1.3em;
  margin: 0;
  width: auto;
}
.scroll-top a:hover {
  opacity: 1;
}

.strip {
  --hpad: var(--vpad);
}
.slickslider .strip .container {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}
.strip--full-height {
  min-height: 100vh;
}
.strip--full-height .container {
  align-self: center;
}
.strip--full-height .strip__text {
  height: auto;
}
.strip--decoration-v {
  position: relative;
  z-index: 0;
}
.strip--decoration-v::before {
  animation-delay: 0.4s;
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  border-left: 2px solid var(--colDecoration, var(--colContrasto));
  transform-origin: 50% 0;
}
@media screen and (min-width: 50em) {
  .strip--decoration-v::before {
    content: "";
  }
}
.strip--decoration-v.js-waiting-deco-top::before {
  transform: scale3d(1, 0, 1);
}
.strip--decoration-h {
  position: relative;
  z-index: 0;
}
.strip--decoration-h::after {
  animation-delay: 0.6s;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border-top: 2px solid var(--colDecoration, var(--colContrasto));
  transform-origin: 50% 50%;
}
.strip--decoration-h.js-waiting-deco-bottom::after {
  transform: scale3d(0, 1, 1);
}
.strip--stamp {
  position: relative;
}
.strip--stamp::after {
  transition-property: opacity;
  content: "";
  position: absolute;
  z-index: 10;
  top: -3.5em;
  right: calc(var(--vpad) / 2);
  width: 5em;
  height: 5em;
  background: transparent url("/img/paszkowski-stamp.svg") no-repeat 50% 50%;
  background-size: contain;
  transform: translate3d(0, var(--stamp-y, 0), 0);
  font-size: 0.6em;
}
@media screen and (min-width: 36em) {
  .strip--stamp::after {
    right: var(--vpad);
    width: 7em;
    height: 7em;
    font-size: 1em;
  }
}
.strip--stamp.strip__column--1::after {
  right: auto;
  left: var(--vpad);
}
.strip--stamp.waiting::after {
  opacity: 0;
}
@media screen and (min-width: 43.76em) {
  .strip__column ul,
  .strip__column ol {
    --ulPad: 1.5em;
  }
  .strip__column.full-height {
    min-height: 100vh;
  }
  .strip__column.full-height .strip__image:only-child img {
    height: 100vh;
    object-fit: cover;
  }
}
.strip__column--sticky .strip__text {
  align-self: flex-start;
  position: sticky;
  top: 30vh;
}
.strip--image.strip--wide .strip__text {
  max-width: calc(var(--container-maxWidth) + var(--container-pad, var(--vpad)) * 2 + var(--container-width-adjust, 0px));
}
.strip__image--overlap .strip__image {
  width: calc(100% + var(--hpad) * 2);
}
.strip__image--overlap.strip__column--2 img {
  transform: translateX(calc(var(--hpad) * -2));
}
.strip__gallery {
  --col-width: 250px;
}
.strip--wide .strip__gallery.splide {
  margin-left: auto;
  margin-right: 0;
  width: calc(100vw - var(--vpad));
  max-width: calc((100% + 75rem) / 2);
}
.strip--wide .strip__gallery .splide__track {
  padding-right: max(50vw - 37.5rem, var(--container-pad)) !important;
}
.strip__gallery__link {
  overflow: hidden;
}
.strip__gallery__link:hover img {
  transform: scale3d(1.02, 1.02, 1);
}
.strip__gallery--slider .strip__gallery__item {
  margin-right: 1em;
}
.builder .strip__gallery--slider .strip__gallery__item {
  margin-right: 0;
}
.strip__gallery--slider img {
  max-height: 70vh;
  object-fit: cover;
}
@media screen and (min-width: 50em) {
  .strip__gallery--slider img {
    max-width: 80vw;
    max-height: 50vh;
  }
}
.strip__counter__values {
  text-align: inherit;
}
.strip__counter__values canvas {
  display: none;
}
.strip__counter__values__main {
  font-size: 3.8em;
  color: var(--strip-text-color, inherit);
}
.strip__counter__values__main span {
  color: var(--colContrasto);
}
.strip__blurb__title {
  font-size: clamp(var(--titleSize) / 3, 2.4vw, var(--titleSize) / 2.5);
}
.strip__blurb__item, .strip__blurb__link {
  border-radius: 0.4em;
}
.strip__blurb__link .strip__blurb__item, .strip__blurb__link .strip__blurb__link {
  transition-property: transform, box-shadow;
}
.strip__blurb__link .strip__blurb__item[class*=bg-custom]::before, .strip__blurb__link [class*=bg-custom].strip__blurb__link::before, .strip__blurb__link .strip__blurb__item[class*=bg-np]::before, .strip__blurb__link [class*=bg-np].strip__blurb__link::before {
  transform: none;
}
.strip__blurb__link:hover .strip__blurb__item[class*=bg-custom], .strip__blurb__link:hover [class*=bg-custom].strip__blurb__link, .strip__blurb__link:hover .strip__blurb__item[class*=bg-np], .strip__blurb__link:hover [class*=bg-np].strip__blurb__link {
  color: inherit;
  transform: scale3d(1.05, 1.05, 1);
  box-shadow: 0 2px 0.75em rgba(0, 0, 0, 0.2);
}
.strip__blurb__link:hover .strip__blurb__item[class*=bg-custom]::before, .strip__blurb__link:hover [class*=bg-custom].strip__blurb__link::before, .strip__blurb__link:hover .strip__blurb__item[class*=bg-np]::before, .strip__blurb__link:hover [class*=bg-np].strip__blurb__link::before {
  opacity: 0.02;
}
.strip__blurb__image {
  margin-bottom: calc(var(--vpad) / 2);
}
.strip__blurb__text a[href^="mailto:"], .strip__blurb__text a[href^=http] {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.strip__map {
  height: 60em;
}
.strip.js-image-cover {
  transform: scaleZ(1);
}
.strip.js-image-cover.js-fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  transform: scaleZ(1) !important;
}
.strip.js-image-cover .strip__image {
  height: 35em;
  max-height: 100vh;
  align-items: center;
}
.strip.js-image-cover .strip__image picture {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.builder .strip.js-image-cover .strip__image picture {
  position: absolute;
}
.strip.js-image-cover .strip__image picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.strip.js-scroll-n-clip {
  height: 100vh;
  overflow: hidden;
}
.builder .strip.js-scroll-n-clip {
  height: auto;
}
.strip.js-scroll-n-clip .strip {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  transform: translate(100vw, 0);
}
.strip.js-scroll-n-clip .strip:first-child {
  transform: none;
}
.strip.js-scroll-n-clip .strip__image img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}
.strip.js-image-slide {
  transform: scaleZ(1);
  overflow: hidden;
}
.strip.js-image-slide .strip__image picture {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.builder .strip.js-image-slide .strip__image picture {
  position: static;
  overflow: hidden;
}
.strip.js-image-slide .strip__image img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  transform: scale3d(1.2, 1.2, 1);
}
.builder .strip.js-image-slide .strip__image img {
  height: 100%;
}
.strip.js-zoom-splash {
  transform: scaleZ(1);
}
.strip.js-zoom-splash .strip__text:has(.strip) {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: calc(var(--vpad) / 3);
}
.strip.js-zoom-splash .strip__text .strip {
  min-width: 30vw;
  width: 100%;
}
.strip.js-zoom-splash .strip__text .strip--columns {
  display: none;
}
@media screen and (min-width: 36em) {
  .strip.js-zoom-splash .strip__text .strip--columns {
    display: block;
    min-width: 50vw;
  }
}
.strip.js-zoom-splash .strip__text .strip--columns .container {
  --strip-column-gap: calc(var(--vpad) / 3);
}
.strip.js-zoom-splash .strip__text .strip--image {
  z-index: 1;
}
.strip.js-zoom-splash .strip__text .strip--image .strip__image {
  height: 35em;
  max-height: 100vh;
  align-items: center;
  clip-path: inset(0px);
}
.strip.js-zoom-splash .strip__text .strip--image .strip__image picture {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.builder .strip.js-zoom-splash .strip__text .strip--image .strip__image picture {
  position: absolute;
}
.strip.js-zoom-splash .strip__text .strip--image .strip__image picture::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: var(--colNero);
  opacity: var(--screenOpacity, 0);
}
.strip.js-zoom-splash .strip__text .strip--image .strip__image picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale3d(0.7, 0.7, 1);
}
.strip.js-zoom-splash .strip__text .strip--image .strip__text {
  position: absolute !important;
  z-index: 2;
  width: 100vw;
  max-width: 56.25rem;
  transform: scale3d(1.2, 1.2, 1);
  opacity: 0;
}

::selection {
  background-color: var(--colPrimario);
  color: var(--colBianco);
}

.button {
  --p: 0.75em;
  --ph: 0.75em;
  --pr: 0px;
  --aniX: var(--X, 0);
  --aniY: var(--Y, 1);
  --aniExclude: outline, outline-offset;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: var(--p) calc(var(--p) + var(--ph) + var(--pr)) calc(var(--p)) calc(var(--p) + var(--ph));
  background: var(--colFondoBottoni, var(--colPrimario));
  color: var(--colTextBottoni, var(--colBianco));
  border: 1px solid var(--colBordoBottoni, transparent);
  font-weight: 500;
  font-size: 0.85em;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
  position: var(--buttonPosition, relative);
  z-index: var(--buttonZ, 1);
  overflow: hidden;
  width: 100%;
  border-radius: 0.5em;
  transform: var(--btTransform, scale3d(1, 1, 1));
}
@media screen and (min-width: 36em) {
  .button {
    width: auto;
  }
}
:where(.button) + .button {
  margin-top: 1em;
}
@media screen and (min-width: 36em) {
  :where(.button) + .button:not(.button--wide) {
    margin-top: 0;
    margin-left: 1em;
  }
  .np_a11y :where(.button) + .button:not(.button--wide) {
    margin-left: 2.5em;
  }
}
.button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--colBianco);
  opacity: 0.4;
  transform: translate3d(calc(-100% * var(--aniX)), calc(-100% * var(--aniY)), 0);
}
.button strong {
  color: inherit;
}
a:hover .button:not(.button--shy), .button:hover {
  color: var(--colBianco);
}
a:hover .button:not(.button--shy)::after, .button:hover::after {
  transform: none;
}
@keyframes bt-anim {
  50% {
    transform: translate3d(calc(-100% * var(--aniX) * -1), calc(-100% * var(--aniY) * -1), 0);
    opacity: 0;
  }
  50.001% {
    transform: translate3d(calc(-100% * var(--aniX)), calc(-100% * var(--aniY)), 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.button--small {
  --p: 0.25em;
}
.button--big {
  --p: 1.25em;
}
.button--wide {
  display: flex;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.button--alternate {
  --colFondoBottoni: var(--colContrasto);
}
.button--no-icon {
  --pr: 0em;
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
}
.button--no-icon strong {
  font-weight: 300;
}
.button--no-icon::before {
  display: none;
}
.button--outlined {
  --colFondoBottoni: transparent;
  --colBordoBottoni: currentColor;
  --colTextBottoni: inherit;
}
.button--white {
  --colTextBottoni: var(--colBianco);
}
.button--no-button {
  border: none;
  color: var(--colTextBottoni, inherit);
}
.button--no-button::after {
  display: none;
}
.button--no-button:hover {
  --colTextBottoni: unset;
  color: var(--colContrasto);
}
.button--icon {
  --aniTime: 0.3s;
  width: auto;
  padding: calc(var(--p) - 0.2em);
}
.button--icon::before {
  margin: 0;
}
.button--squared {
  border-radius: 0;
}
.button--rounded {
  height: var(--buttonHeight, 4.5em);
  width: var(--buttonHeight, 4.5em);
  padding: var(--p);
  border-radius: 50%;
}
.button--disabled {
  --colTextBottoni: var(--colGrigioScuro);
  --colFondoBottoni: var(--colGrigio);
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}
.button[href^=http]:not(.button--standard)::before {
  order: 2;
  margin-right: 0;
  margin-left: 0.5em;
}

.socials {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu__screen {
  display: block;
  background: var(--colNero);
  opacity: 0.3;
  z-index: 4;
}
.m-open .menu__screen {
  visibility: visible;
  transform: none;
  pointer-events: all;
}
.menu__container {
  display: flex;
  flex-direction: column;
  position: fixed;
  background: var(--colNero);
  padding-top: calc(var(--header-height) / 16 * 1em * var(--fontSize)) var(--vpad) calc(var(--vpad) / 2);
  z-index: 5;
  line-height: 1.2;
  color: var(--colBianco);
}
.m-open .menu__container {
  visibility: visible;
  opacity: 1;
  transform: none;
  pointer-events: all;
}
.menu__container a {
  color: inherit;
}
@media screen and not (prefers-reduced-motion: reduce) {
  .menu__container li {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  .menu__container li:nth-child(1) {
    transition-delay: 0.35s;
  }
  .menu__container li:nth-child(2) {
    transition-delay: 0.4s;
  }
  .menu__container li:nth-child(3) {
    transition-delay: 0.45s;
  }
  .menu__container li:nth-child(4) {
    transition-delay: 0.5s;
  }
  .menu__container li:nth-child(5) {
    transition-delay: 0.55s;
  }
  .menu__container li:nth-child(6) {
    transition-delay: 0.6s;
  }
  .menu__container li:nth-child(7) {
    transition-delay: 0.65s;
  }
  .menu__container li:nth-child(8) {
    transition-delay: 0.7s;
  }
  .menu__container li:nth-child(9) {
    transition-delay: 0.75s;
  }
  .m-open .menu__container li, .no-js .menu__container li {
    transform: none;
    opacity: 1;
  }
  .m-open .menu__container li.off, .no-js .menu__container li.off {
    opacity: 0.5;
  }
}
.menu__row {
  max-width: 75rem;
  margin-top: auto;
  display: grid;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  gap: calc(var(--vpad) / 2) var(--vpad);
  padding-left: calc(var(--vpad) / 2);
  padding-right: calc(var(--vpad) / 2);
}
@media screen and (min-width: 36em) {
  .menu__row {
    padding-left: 0;
    padding-right: 0;
  }
}
.menu__main {
  width: 100%;
  padding: 1em var(--vpad);
  max-height: 100%;
  overflow: auto;
}
.menu__main ul {
  display: grid;
  gap: 1em;
}
.menu__main li {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media screen and (min-width: 50em) {
  .menu__main li {
    text-align: unset;
    justify-content: flex-start;
  }
}
.menu__main a {
  font-size: clamp(var(--titleSize) / 1.7, 4vw, var(--titleSize));
}
.menu__main a, .menu__side a {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.menu__main a div, .menu__side a div {
  display: block;
}
.menu__main a div + div, .menu__side a div + div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}
.is-mouse .menu__main a:hover div, .is-mouse .menu__side a:hover div {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
.is-mouse .menu__main a:hover div + div, .is-mouse .menu__side a:hover div + div {
  opacity: 1;
  transform: none;
}
.menu__mid {
  padding: calc(var(--vpad) / 2) var(--vpad) 0;
  margin-bottom: auto;
  display: grid;
  gap: 1em;
}
@media screen and (min-width: 36em) {
  .menu__mid {
    display: flex;
    justify-content: space-between;
  }
}
.menu__side {
  text-transform: uppercase;
  font-size: 1.1em;
  text-align: center;
}
@media screen and (min-width: 36em) {
  .menu__side {
    text-align: unset;
  }
}
.menu__side ul {
  display: grid;
  gap: 0.5em;
}
.menu__footer {
  padding: 1em var(--vpad);
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
}
.menu__lang ul {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: 1em;
}
.menu__lang a {
  color: inherit;
}
.menu__lang a.active {
  font-weight: 400;
}
.menu__socials .socials {
  gap: 1em;
  display: flex;
}
.menu__socials a::before {
  margin: 0;
  font-size: 1.5em;
}
.menu__socials a:hover {
  color: inherit;
  opacity: 0.7;
}

.page__content {
  overflow: hidden;
}
.page--nobanner .page__content {
  padding-top: calc(var(--header-height) / 16 * 1em / var(--fontSize));
}

.word-wrapper {
  display: inline-flex;
  overflow: hidden;
  margin-top: -0.15em;
}
.word-wrapper .word {
  padding-top: 0.15em;
}

.banner {
  background: var(--colNero);
}
.banner__slider, .home .banner__slider, .banner__slide, .home .banner__slide {
  height: 100vh;
}
.js-clip-n-cover .banner__slide picture {
  clip-path: inset(calc(var(--header-height) * 1px + var(--vpad) * 2 + var(--titleSize) * 1.3) 0px 0px 0px);
}
.js-clip-n-cover .banner__slide img {
  transform: scale3d(1.2, 1.2, 1);
}
.banner__video {
  filter: Grayscale();
}
.banner__watermark {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: transparent url("/img/watermark.svg") no-repeat 50% 50%;
  background-size: 200px 30px;
  opacity: 0.3;
  pointer-events: none;
}
.banner__text {
  max-width: 100%;
  top: calc(var(--header-height) * 1px + var(--vpad));
  text-align: inherit;
  transform: none;
}
.home .banner__text {
  --titleSize: 5em;
  top: auto;
  transform: none;
  text-shadow: 0.1em 0.1em 0px rgb(0, 0, 0);
  bottom: calc(var(--vpad) * 1.5);
}
@media screen and (min-width: 36em) {
  .home .banner__text {
    bottom: calc(var(--vpad) * 2);
  }
}
@media screen and (min-width: 50em) {
  .home .banner__text {
    bottom: 25%;
  }
}
.banner__title {
  font-size: clamp(var(--titleSize) / 1.7, 6vw, var(--titleSize));
}
.banner__credits {
  position: absolute;
  bottom: calc(var(--vpad) / 3);
  right: var(--vpad);
  color: var(--colBianco);
  z-index: 5;
  font-size: 0.7em;
}
.banner__form {
  display: block;
  position: absolute;
  bottom: 5em;
  left: 0;
  right: 0;
  z-index: 20;
}
.banner__slogan {
  font-size: clamp(var(--titleSize) / 1.2, 3vw, var(--titleSize));
  color: var(--colBianco);
  margin-bottom: calc(var(--vpad) / 2);
}
.banner__slogan em {
  font-style: normal;
  display: block;
}
.banner__search {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: var(--colBianco);
  padding: 0.5em;
  border-radius: 0.3em;
  gap: calc(var(--vpad) / 2);
}
.banner__search p {
  width: 25%;
}
.banner__search p:last-child {
  align-self: stretch;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  padding-right: 0.5em;
}
.banner__search label {
  font-size: 0.85em;
  text-align: center;
  margin-bottom: 0.2em;
}
.banner__search select, .banner__search .dropdown span, .dropdown .banner__search span {
  border: none;
  text-align: center;
  font-weight: 400;
  height: auto;
}
.banner__link {
  text-transform: uppercase;
  color: var(--colBianco);
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 0.5em;
  overflow: hidden;
  font-weight: 400;
}
.banner__link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: var(--colBianco);
  transform: translateY(calc(100% - 2px));
}
.banner__link:hover {
  color: var(--colText);
}
.banner__link:hover::after {
  transform: none;
}

.breadcrumbs {
  padding: 0.3em 0;
  text-align: center;
}
.breadcrumbs.js-fixed {
  position: fixed;
  z-index: 89;
  left: 0;
  right: 0;
  top: calc(var(--header-height) * 1px + var(--menuPageHeight, 0px));
  background: var(--colBianco);
  margin-top: 0;
  transform: none;
}
.breadcrumbs a {
  color: inherit;
}
.breadcrumbs span {
  font-size: 0.85em;
}
.breadcrumbs span:not(:first-child)::before {
  content: "/";
  font-weight: 300;
  display: inline-block;
  margin: 0 0.4em 0 0.2em;
  vertical-align: middle;
}
.breadcrumbs span:last-child {
  font-weight: 400;
}

.accordion__switch::after {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 calc(var(--vpad) / 4);
  font-size: 1rem;
  width: 1.5em;
  height: 1.5em;
  color: var(--colContrasto);
}
.no-js .accordion__switch::after, .accordion__switch.active::after {
  transform: rotate3d(0, 0, 1, 180deg);
}

.map__content {
  font-size: 1rem;
  text-align: inherit;
  padding-top: 0.5em;
}
.map__logo {
  height: 24px !important;
  width: auto !important;
  margin: 0 auto 1.5em !important;
}
.map__image {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 0.7em;
}
.map__title {
  font-size: 1.4em;
  margin-bottom: 0.5em;
}
.map__text {
  font-size: 0.95em;
}
.map__marker__type {
  text-transform: uppercase;
  font-size: 0.75em;
  color: var(--colGrigioMedio);
}
.map__marker__title {
  text-transform: uppercase;
  font-weight: 400;
}

.slider__buttons {
  display: grid;
  grid-template-columns: repeat(2, minmax(1em, 1fr));
  gap: 1em;
}
.slider__buttons .button {
  margin: 0;
}

.scroll-title-fixed {
  position: fixed;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.js-scroll-container {
  overflow: hidden;
  display: flex;
}
.js-scroll-container > * {
  flex: 0 0 auto;
}
.js-scroll-container .strip__data,
.js-scroll-container .strip__text {
  min-width: 100%;
  display: flex;
}
.js-scroll-container [class*=strip__title] {
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--colPrimarioLeggero);
  margin: 0;
  padding-left: 0.15em;
}
.js-scroll-container.activated .js-scroll-content,
.js-scroll-container.activated [class*=strip__title] {
  animation: strip-marquee 10s linear infinite;
}
@keyframes strip-marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.awards-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: calc(var(--vpad) / 2);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.awards-list img {
  max-width: 10em;
  max-height: 4em;
  object-fit: contain;
}

.side-popup {
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  height: 100%;
  width: 70em;
  max-width: 95%;
  background: var(--colBg);
  transform: translate3d(100%, 0, 0);
}
.side-popup-open {
  overflow: hidden;
}
.side-popup-open .side-popup {
  transform: none;
}
.side-popup-open .side-popup__screen {
  opacity: 1;
  visibility: visible;
}
.side-popup__screen {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  backdrop-filter: blur(4px);
  opacity: 0;
  visibility: hidden;
}
.side-open .side-popup__screen {
  opacity: 1;
  visibility: visible;
}
.side-popup__close {
  position: absolute;
  z-index: 10;
  top: calc(var(--vpad) / 2);
  right: var(--vpad);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  background: var(--colNero);
  color: var(--colBianco);
  border-radius: 50%;
}
.side-popup__close::before {
  margin: 0;
}
.side-popup__conts {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.side-popup .ajax-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.side-popup .strip:not(.strip--image) {
  display: flex;
  height: 100%;
  overflow: hidden;
}
.side-popup .strip:not(.strip--image) .container {
  display: flex;
}
.side-popup .strip:not(.strip--image) .strip__text {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card__banner img {
  max-height: 30vh;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card__contents {
  padding: var(--vpad) 0;
  flex: 1 1 auto;
  height: 1em;
  display: flex;
}
.card__scroll {
  height: 100%;
  overflow: auto;
  flex: 1 1 auto;
}
.card__section {
  padding: 0 var(--vpad);
}
.card__title {
  font-size: clamp(1.2em, 3vw, 2em);
  color: var(--colNero);
  position: sticky;
  top: 0;
  background: var(--bgStrip, var(--colBg));
  padding: 1rem 0;
  border-bottom: 1px solid;
}
.card__title.active {
  border-bottom-color: transparent;
}
.card__title:hover {
  color: var(--colPrimario);
}
.card__title::after {
  color: inherit;
  font-size: 0.9em;
}
.card__list {
  list-style: none;
  margin: 0;
  padding: 1em 0 0;
  border-bottom: 2px solid var(--colNero);
}
.card__item {
  display: grid;
  grid-template-columns: minmax(1em, 1fr) 6em;
  gap: 0 1em;
  margin-bottom: 1em;
}
.card__plate {
  grid-column: span 2;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.95em;
  line-height: 1.3;
  color: var(--colNero);
}
.card__price {
  text-align: right;
}

.scroll__container {
  overflow: hidden;
  position: relative;
}
.scroll__item {
  overflow: auto;
  padding-bottom: 40px;
  margin-bottom: -30px;
  user-select: none;
}
.is-touch .scroll__item {
  scroll-snap-type: x mandatory;
}
.scroll__content {
  display: inline-flex;
}
.scroll__content .strip {
  scroll-snap-align: start;
  width: 85vw;
}
@media screen and (min-width: 36em) {
  .scroll__content .strip {
    width: 90vw;
  }
}
@media screen and (min-width: 50em) {
  .scroll__content .strip {
    flex: 0 0 auto;
    height: 100vh;
  }
  .scroll__content .strip .container {
    max-width: none;
  }
}
@media screen and (min-width: 50em) {
  .scroll__content .strip--3-cols {
    --span: 4;
    --strip-col-bgPad: -2;
  }
  .scroll__content .strip--3-cols .container {
    --strip-column-gap: var(--hpad, var(--vpad));
    grid-template-columns: repeat(12, minmax(1em, 1fr));
  }
}
.scroll__content .strip__column.full-height {
  min-height: 0;
}
@media screen and (min-width: 50em) {
  .scroll__content .strip__column.full-height {
    min-height: 100vh;
  }
}
@media screen and (min-width: 50em) {
  .scroll__content .strip__column--image .strip__image img {
    width: calc(100% + var(--container-pad, var(--vpad)));
  }
}
@media screen and (min-width: 50em) {
  .scroll__content .strip__column--image.strip__column--1 .strip__image img {
    transform: translateX(calc(var(--container-pad) * -1));
  }
}
.scroll__content .strip__image img {
  max-height: 40vh;
  object-fit: contain;
}
@media screen and (min-width: 50em) {
  .scroll__content .strip__image img {
    max-height: none;
    object-fit: cover;
  }
}
.scroll__handle {
  --aniProp: opacity;
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5em;
  height: 4.5em;
  color: var(--colPrimario);
  background: var(--colBianco);
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  pointer-events: none;
  opacity: 0.8;
}
.scroll__handle.visible {
  opacity: 1;
}
.scroll__handle::before {
  font-size: 2.6em;
  margin: 0 !important;
}

.scheduled__item {
  margin-right: calc(var(--vpad) * 1.5);
  display: flex;
  flex-direction: column;
}
.scheduled__name {
  font-weight: 400;
  color: var(--colNero);
}
.scheduled__category {
  font-size: clamp(1.5em, 2vw, 2.1em);
  color: var(--colNero);
  margin-bottom: calc(var(--vpad) / 3);
}
.scheduled__time {
  display: flex;
  gap: 1em;
  justify-content: space-between;
  border-bottom: 1px solid var(--strip-text-color, var(--colNero));
  margin-bottom: 0.5em;
  margin-top: auto;
  padding-bottom: 0.5em;
}
.scheduled__hour {
  white-space: nowrap;
}
.scheduled__hour::before {
  margin: 0 0.4em 0;
  color: var(--strip-text-color, var(--colNero));
}

.box-grid {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: var(--vpad) 1.5em;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 20em), 1fr));
  grid-auto-flow: dense;
}
.box-grid__item--large {
  grid-column: span 2;
}
.box-grid__item--large img {
  height: 100%;
  object-fit: cover;
}
.box-grid__link {
  color: inherit;
  display: grid;
  gap: 1em;
}
.box-grid__link:hover {
  color: inherit;
}
.box-grid__link:hover .box-grid__image img {
  transform: scale3d(1.05, 1.05, 1);
}
.box-grid__link:hover .box-grid__title {
  color: var(--colPrimarioChiaro);
}
.box-grid__image {
  overflow: hidden;
}
.box-grid__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.box-grid__text {
  display: grid;
  gap: 0.5em;
}
.box-grid__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.5em;
  font-weight: 400;
}
.box-grid__buttons {
  margin-top: var(--vpad);
  text-align: center;
}

.page__footer {
  overflow: hidden;
}
.page__footer a {
  color: inherit;
  display: inline-block;
}
.page__footer a:hover {
  color: inherit;
  opacity: 0.6;
}

.footer__top {
  padding: calc(var(--vpad) * 1.5) 0;
  display: grid;
  gap: calc(var(--vpad) / 2);
  text-align: center;
}
@media screen and (min-width: 50em) {
  .footer__top {
    text-align: unset;
    grid-template-columns: repeat(4, auto);
  }
}
.footer__logo {
  max-width: 5em;
  margin: 0 auto calc(var(--vpad) / 2);
}
@media screen and (min-width: 50em) {
  .footer__logo {
    margin-left: 0;
    margin-right: 0;
  }
}
.footer__menu ul {
  display: grid;
  gap: 0.25em;
}
.footer__shortcuts ul {
  display: grid;
  gap: 0.25em;
  grid-template-columns: repeat(2, minmax(1em, 1fr));
}
@media screen and (min-width: 50em) {
  .footer__shortcuts ul {
    grid-template-columns: none;
  }
}
.footer__shortcuts ul + ul {
  margin-top: 0.25em;
}
.footer__shortcuts a div + div {
  display: none;
}
.footer__title {
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 0.5em;
}
.footer__socials .socials {
  gap: 0.5em;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 36em) {
  .footer__socials .socials {
    justify-content: flex-start;
  }
}
.footer__socials a::before {
  font-size: 1.5em;
  margin: 0;
}
.footer__mid {
  padding-bottom: calc(var(--vpad) / 4);
  display: flex;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.75em;
  flex-direction: column;
}
@media screen and (min-width: 36em) {
  .footer__mid {
    flex-direction: row;
  }
}
.footer__bottom {
  background: var(--colNero);
  padding: calc(var(--vpad) / 2) 0;
  display: grid;
  gap: calc(var(--vpad) / 2);
  justify-content: center;
  position: relative;
}
@media screen and (min-width: 36em) {
  .footer__bottom {
    display: flex;
    align-items: center;
  }
}
.footer__credits {
  text-align: right;
}
@media screen and (min-width: 36em) {
  .footer__credits {
    flex: 0 0 auto;
    text-align: unset;
  }
}

.scroll-top a {
  width: 4.5em;
  height: 4.5em;
  color: var(--colBianco);
  background: var(--colNero);
  border: 1px solid;
  border-radius: 50%;
  opacity: 1;
}
.scroll-top a:hover {
  opacity: 0.8;
}
.scroll-top a::before {
  font-size: 1em;
}