// ! Extended ----------------
%fontIcons {
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    //speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.4em;
    text-align: center;

    // For safety - reset parent styles, that can break glyph codes
    font-variant: normal;
    text-transform: none;

    // fix buttons height, for twitter bootstrap
    line-height: 1em;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
%textWhite {
    color: var(--colBianco);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin animate($time: 0.3s, $prop: all) {
    @media screen and (prefers-reduced-motion: no-preference) {
        // transition: all 0.3s ease;
        transition: var(--aniTime, 0.3s) cubic-bezier(0.445, 0.05, 0.55, 0.95);
        transition-property: var(--aniProp, all);
    }
}
@mixin animate2($time: 0.6s, $prop: all) {
    @media screen and (prefers-reduced-motion: no-preference) {
        // transition: var(--aniProp, all) var(--aniTime, 0.8s) cubic-bezier(0.895, 0.03, 0.685, 0.22), var(--aniExclude, font-style) 1ms;
        transition: var(--aniTime, #{$time}) cubic-bezier(0.645, 0.045, 0.355, 1);;
        transition-property: var(--aniProp, #{$prop});
    }
}
@mixin animateBut() {
    @media screen and (prefers-reduced-motion: no-preference) {
        transition: all var(--aniTime, 0.6s) cubic-bezier(0.645, 0.045, 0.355, 1),
            var(--aniExclude, font-style) 1ms;
    }
}
%animate {
    @include animate();
}
%animate2 {
    @include animate2();
}
%animateBut {
    @include animateBut();
}

%ir {
    text-indent: 105%;
    white-space: nowrap;
    overflow: hidden;
    font-family: sans-serif !important;
}

%coverImage {
    position: relative;
    overflow: hidden;
    video,
    picture,
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: var(--pinX, 50%) var(--pinY, 50%);
    }
}

%container {
    display: var(--container-display, block);
    width: 100%;
    max-width: calc(
        var(--container-maxWidth) + (var(--container-pad) * 2) + var(--container-width-adjust, 0px)
    );
    padding-left: var(--container-pad);
    padding-right: var(--container-pad);
    margin: 0 auto;
}
%cf {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}
%hidden {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -100;
}
